import * as React from 'react'
import { useState } from 'react'
import {
  List, TextField, useTranslate, ReferenceField, FunctionField
} from 'react-admin'
import Pagination from '../../../components/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import Datagrid from '../components/Datagrid'
import ListActions from '../components/ListActions'
import BulkActionButtons from '../components/BulkActionButtons'
import Filters from '../components/Filters'
import { stylesList } from '../../../helpers/enums'

const useStyles = makeStyles(stylesList)

const PointsOfSaleList = props => {
  const classes = useStyles()
  const translate = useTranslate()

  const [isDisabledSaveChanges, setDisabledSaveChanges] = useState(true)

  return (
    <List
      classes={{
        root: classes.root,
        bulkActionsDisplayed: classes.bulkActionsDisplayed
      }}
      title={translate('menuItem.pointsOfSale')}
      {...props}
      actions={
        <ListActions
          isDisabledSaveChanges={isDisabledSaveChanges}
        />
      }
      bulkActionButtons={<BulkActionButtons />}
      filters={<Filters />}
      pagination={<Pagination />}
      perPage={25}
    >
      <Datagrid
        rowClick="edit"
        setDisabledSaveChanges={setDisabledSaveChanges}
      >
        <TextField
          label={translate('fields.namePointOfSale')}
          source="name"
          color="warning"
        />
        <TextField
          label={translate('fields.addressPointOfSale')}
          source="legalAddress.fullName"
        />
        <TextField
          label={translate('fields.posInn')}
          source="posInn"
        />
        <TextField
          label={translate('fields.legalName')}
          source="registeredName"
        />
        <TextField
          label={translate('fields.status')}
          source="status.name"
        />
        <ReferenceField
          source='supplier.id'
          label={translate('fields.supplier')}
          reference='suppliers'
          link={false}
        >
          <TextField source='client.organizationName' />
        </ReferenceField>
        <TextField
          label={translate('fields.includedInInformationLetter')}
          source="includedInInformationLetter"
        />
      </Datagrid>
    </List>
  )
}

export default PointsOfSaleList