import * as React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import SoleProprietor from '../SoleProprietor'
import LegalEntity from '../LegalEntity'
import VendorTaxation from '../VendorTaxation'
import BankDetails from '../BankDetails'
import FoundersVendor from '../FoundersVendor'
import { Field } from 'react-final-form'

const useStyles = makeStyles({
  inactively: {
    display: 'none'
  }
})

const ClientCard = ({active}) => {
  const classes = useStyles()
  const organizationType = useSelector(state => state.customReducer.organizationType)

  const renderFoundersVendor = ({
                                  meta: {touched, error} = {touched: false, error: undefined},
                                  input: {...inputProps},
                                  ...props
                                }) => {
    return (
      <FoundersVendor inputProps={inputProps}/>
    )
  }

  return (
    <div className={active ? '' : classes.inactively}>
      {organizationType === 'INDIVIDUAL' ?
        <SoleProprietor/> : <LegalEntity/>
      }
      {organizationType === 'LEGAL' &&
        <Field
          name="client.supplierFounders"
          render={renderFoundersVendor}
        />
      }
      <VendorTaxation />
      <BankDetails/>
    </div>
  )
}

export default ClientCard