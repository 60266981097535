import * as React from 'react'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { Button } from '@material-ui/core'
import ModalBase from '../ModalBase'

const ConfirmationVerifyModal = ({ onProvide, isOpen, toggle }) => {
  const translate = useTranslate()

  const handleProvide = useCallback(async () => {
    await onProvide()
    toggle()
  }, [])

  const customActions = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleProvide}
      >
        {translate('buttons.provide')}
      </Button>
      <Button
        variant="contained"
        onClick={toggle}
      >
        {translate('buttons.cancel')}
      </Button>
    </>
  )

  return (
    <ModalBase
      isOpen={isOpen}
      handleClose={toggle}
      title={translate('confirmationVerifyModal.title')}
      customActions={customActions}
    >
      {translate('confirmationVerifyModal.content')}
    </ModalBase>
  )
}

export default ConfirmationVerifyModal