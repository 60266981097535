export const transform = ({}) => (data) => {
  if (!data.supplier || data.supplier.id === null) {
    data.supplier = null
  } else {
    data.supplier = {
      id: data.supplier.id
    }
  }
  if (!data.includedInInformationLetter) {
    data.includedInInformationLetter = false
  }
  data.sent = false; //todo: rename field "sent" to "dirty"

  return data
}