import List from './List'
import Edit from './Edit'
import Store from '@material-ui/icons/Store'

let changedFields = {};

export const getChangedFields = () => {
  return changedFields
}

export const setChangedFields = (data) => {
  changedFields = data
}

export default {
  list: List,
  edit: Edit,
  icon: Store,
  options: {
    label: 'menuItem.pointsOfSale'
  }
}