import * as React from 'react'
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    width: '100%',
    maxWidth: '300px',
  },
  selectWithRightComponent: {
    display: 'flex',
    maxWidth: '100%'
  }
}))

const SelectInput = ({ value, onChange, label, options, className, ...props }) => {
  const classes = useStyles()
  return (
    <>
      <FormControl className={`${classes.formControl} ${className}`}>
        <InputLabel>{label}</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          {...props}
        >
          {options.map(item => (
            <MenuItem value={item.value}>{item.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

export default SelectInput