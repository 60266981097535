import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate, useNotify, useDataProvider
} from 'react-admin'
import {
  Button, CircularProgress, TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import ModalBase from '../../../../../../../../components/ModalBase'
import InfoPopover from '../../../../../../../../components/InfoPopover'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridRowGap: theme.spacing(1),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      flex: 'unset'
    },
  },
  paperStyle: {
    width: '100%'
  },
  input: {
    width: '100%',
  },
  select: {
    width: '100%',
    '& > div': {
      width: '100%',
    }
  },
  textArea: {
    '& .MuiInputBase-root': {
      alignItems: 'start'
    }
  },
  hint: {
    marginTop: -17
  },
  helperText: {
    marginLeft: 14
  }
}))

const initValues = {
  name: '',
  organizationType: '',
  fullAddress: ''
}

const initErrors = {
  name: 'ra.validation.required',
  organizationType: 'ra.validation.required',
  fullAddress: 'ra.validation.required',
}

const initBlurred = {
  name: false,
  organizationType: false,
  fullAddress: false,
}

const DistributionCenterCreateModal = ({
                                          isOpen,
                                          toggle,
                                          onGetDistributionCenters
                                       }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const loading = useSelector(state => state.admin.loading)
  const constants = useSelector(state => state.customReducer.constants)
  const currentUser = useSelector(state => state.customReducer.currentUser)

  const organizationTypeOptions = useMemo(() => ([
    { value: constants?.SUBJECT_TYPE_LEGAL_PERSON, name: 'fields.legal' },
    { value: constants?.SUBJECT_TYPE_INDIVIDUAL, name: 'fields.individual' }
  ]), [constants])

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState(initErrors)
  const [blurred, setBlurred] = useState(initBlurred)

  const onBlur = useCallback(field => () => {
    setBlurred(prev => ({
      ...prev,
      [field]: true
    }))
  }, [])

  const onChange = useCallback(field => e => {
    const value = e.target.value
    setValues({
      ...values,
      [field]: value
    })
    setErrors({
      ...errors,
      [field]: !value ? 'ra.validation.required' : ''
    })
  }, [values, errors])

  const handleCreate = useCallback(async () => {
    const errorsKeys = Object.keys(errors)
    const isValid = !errorsKeys.find(field => errors[field])
    const blurredKeys = Object.keys(blurred)
    const allBlurred = {}
    blurredKeys.forEach(field => {
      allBlurred[field] = true
    })
    setBlurred(allBlurred)

    if (isValid) {
      try {
        await dataProvider.create('distributionCenters', {
          data: {
            ...values,
            tradeAccount: currentUser.tradeAccount ?? null
          }
        })
        onGetDistributionCenters()
        notify('notify.elementCreated')
      } catch (err) {
        notify(err.message, 'warning')
      }
      toggle()
    } else {
      notify('ra.message.invalid_form', 'warning')
    }
  }, [errors, blurred, currentUser, values])

  const customActions = () => (
    <Button
      variant="contained"
      color="primary"
      startIcon={loading ? <CircularProgress size={14} /> : <AddIcon />}
      onClick={handleCreate}
      disabled={loading}
    >
      {translate('buttons.add')}
    </Button>
  )

  return (
    <ModalBase
      title={translate('resources.distributionCenters.create')}
      handleClose={toggle}
      isOpen={isOpen}
      contentClasses={classes.content}
      paperStyle={classes.paperStyle}
      maxWidth='sm'
      customActions={customActions}
    >
      <TextField
        className={classes.input}
        label={translate('fields.tradeAccount')}
        variant="filled"
        value={currentUser.tradeAccount?.name ?? ''}
        required
        disabled
      />
      <TextField
        className={classes.input}
        label={translate('fields.nameDC')}
        variant="filled"
        value={values.name}
        onBlur={onBlur('name')}
        onChange={onChange('name')}
        error={blurred.name && errors.name}
        helperText={blurred.name ? translate(errors.name) : ''}
        required
      />
      <FormControl
        error={blurred.organizationType && errors.organizationType}
      >
        <InputLabel required>
          {translate('fields.organizationType')}  
        </InputLabel>
        <Select
          className={classes.select}
          variant="filled"
          value={values.organizationType}
          onBlur={onBlur('organizationType')}
          onChange={onChange('organizationType')}
        >
          {organizationTypeOptions.map(item => (
            <MenuItem value={item.value}>{translate(item.name)}</MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.helperText}>
          {translate(blurred.organizationType && (errors.organizationType ?? ''))}
        </FormHelperText>
      </FormControl>
      <TextField
        className={classes.textArea}
        label={translate('fields.legalAddress')}
        variant="filled"
        multiline
        rows={5}
        value={values.fullAddress}
        onBlur={onBlur('fullAddress')}
        onChange={onChange('fullAddress')}
        required
        InputProps={{
          endAdornment: (
            <InfoPopover
              id="fullAddress"
              content={translate('fields.helperTextDCFullAddress')}
              iconClass={classes.hint}
            />
          )
        }}
        error={blurred.fullAddress && errors.fullAddress}
        helperText={blurred.fullAddress ? translate(errors.fullAddress) : ''}
      />
    </ModalBase>
  )
}

export default DistributionCenterCreateModal