import * as React from 'react'
import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate, TextInput, required, email, number
} from 'react-admin'
import { Field, useFormState } from 'react-final-form'
import { Typography, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import PersonWithRightToSignInput from '../PersonWithRightToSignInput'
import PhoneNumberInput from '../PhoneNumberInput'
import LegalAddress from '../../../../../../components/LegalAddressFieldWithModal'
import { lineLength } from '../../../../../../helpers/utils'
import ContactPersonInput from '../ContactPersonInput'
import { legalAddressSupplierArray } from '../../../../../../helpers/enums'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0 0 ${theme.spacing(2)}px`,
  },
  subtitle: {
    marginBottom: theme.spacing(2)
  },
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 48px',
      gridColumnGap: theme.spacing(1),
      maxWidth: 'calc(100vw - 48px)'
    },
  },
  formControl: {
    paddingBottom: 4,
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 3',
      paddingBottom: 12,
    },
  },
  formControlLegalAddress: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 4,
    paddingTop: 8,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 56px',
      '& > div': {
        width: '100%'
      }
    },
  },
  editBtn: {
    width: 48,
    height: 48,
    marginTop: 12,
    marginLeft: theme.spacing(2),
    gridColumn: '2 / 3',
  },
  formControlPersonWithRightToSign: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 4
  },
  legalAddress: {
    gridColumn: '1/2'
  },
  position: {
    gridColumn: '1/2'
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 3',
      maxWidth: 'calc(100vw - 48px)'
    },
  },
  inn: {
    gridColumn: '1/2',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 3'
    }
  },
  fullName: {
    gridColumn: '1 / 3'
  },
  textfield: {
    marginTop: 8,
    marginBottom: 4
  }
}))

const identity = value => (value)

const validateRequired = [required()]
const validateNumber = [required(), number()]
const validateEmail = [required(), email()]

const LegalEntity = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const { values } = useFormState()

  const constants = useSelector(state => state.customReducer.constants)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)
  const loading = useSelector(state => state.admin.loading)

  const validateKpp = [required(), number(), lineLength(9, translate)]

  const [isFullOrganizationNameDisabled, setFullOrganizationNameDisabled] = useState(true)
  const [isOrganizationNameDisabled, setOrganizationNameDisabled] = useState(true)

  useEffect(() => {
    if (loading === 0 && values.client?.inn) {
      if (!values.client.organizationFullName && isFullOrganizationNameDisabled) {
        setFullOrganizationNameDisabled(false)
      }

      if (!values.client.organizationName && isOrganizationNameDisabled) {
        setOrganizationNameDisabled(false)
      }
    }
    
    if (loading !== 0) {
      if (!isFullOrganizationNameDisabled) {
        setFullOrganizationNameDisabled(true)
      }
      if (!isOrganizationNameDisabled) {
        setOrganizationNameDisabled(true)
      }
    }
  }, [values.client, isFullOrganizationNameDisabled, isOrganizationNameDisabled, loading])

  const isDisabledFields = useMemo(() => (!(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === ''
    )
  ), [supplierStatus, constants, loading])

  const renderPhone = ({
                         meta: { touched, error } = { touched: false, error: undefined },
                         input: { ...inputProps },
                         ...props
                       }) => {
    return (
      <PhoneNumberInput
        inputProps={inputProps}
        touched={touched}
        disabled={isDisabledFields}
      />
    )
  }

  const renderLegalAddress = ({
                                meta: { touched, error } = { touched: false, error: undefined },
                                input: { ...inputProps },
                                ...props
                              }) => {
    return (
      <LegalAddress
        inputProps={inputProps}
        modalTitle={translate('formSuppliers.titles.legalAddress')}
        fullAddressLabel={translate('fields.legalAddress')}
        disabledOnFilled={true}
        isDisabledModalFields={supplierStatus !== constants?.DRAFT_PROVIDER_STATUS_I && supplierStatus !== ''}
        showErrorsWithoutBlur={true}
        fieldsArray={legalAddressSupplierArray}
        source="client.legalAddress"
      />
    )
  }

  const renderPersonWithRightToSign = ({
                                         meta: { touched, error } = { touched: false, error: undefined },
                                         input: { ...inputProps },
                                         ...props
                                       }) => {
    return (
      <PersonWithRightToSignInput inputProps={inputProps} />
    )
  }

  const renderContactPerson = ({
                                 meta: { touched, error } = { touched: false, error: undefined },
                                 input: { ...inputProps },
                                 ...props
                               }) => {
    return (
      <ContactPersonInput
        inputProps={inputProps}
        disabled={isDisabledFields}
      />
    )
  }

  const renderField = (field, label, validate, disabled, helperText, fieldClass) => ({
   meta: { touched, error } = { touched: false, error: undefined },
   input: { ...inputProps },
   ...props
  }) => {
    return (
      <>
        <TextField
          className={`${classes.input} ${classes.textfield} ${fieldClass ?? ''}`}
          variant="filled"
          label={label}
          value={(values.client && values.client[field]) ?? ''}
          onChange={inputProps.onChange}
          onBlur={inputProps.onBlur}
          parse={identity}
          disabled={disabled}
          required
          helperText={(touched && error) ? translate(error) : helperText}
          error={translate(error)}
        />
        <TextInput
          source={`client.${field}`}
          validate={validate}
          disabled
          style={{
            display: 'none'
          }}
        />
      </>
    )
  }

  return (
    <div className={classes.root}>
      <Typography
        className={classes.subtitle}
        variant="subtitle1"
      >
        {translate('formSuppliers.titles.mainInfoSupplier')}
      </Typography>
      <div className={classes.form}>
        <Field
          name="client.organizationFullName"
          render={
            renderField(
              'organizationFullName',
              translate('fields.organizationFullName'),
              validateRequired,
              isFullOrganizationNameDisabled || isDisabledFields,
              '',
              classes.fullName
            )
          }
        />
        <Field
          name="client.organizationName"
          render={
            renderField(
              'organizationName',
              translate('fields.organizationName'),
              validateRequired,
              isOrganizationNameDisabled || isDisabledFields,
              '',
              classes.fullName
            )
          }
        />
        <TextInput
          className={`${classes.input} ${classes.inn}`}
          source="client.inn"
          label={translate('fields.inn')}
          helperText=""
          validate={validateNumber}
          disabled
        />
        <TextInput
          className={classes.input}
          source="client.kpp"
          label={translate('fields.kpp')}
          inputProps={{ maxLength: 9 }}
          validate={validateKpp}
          helperText=""
          parse={identity}
          disabled={isDisabledFields}
        />
        <Field
          name="client.legalAddress"
          render={renderLegalAddress}
        />
        <Field
          name="client"
          render={renderContactPerson}
        />
        <Field
          name="client.phone"
          render={renderPhone}
        />
        <Field
          name="client.email"
          render={
            renderField(
              'email',
              translate('fields.serviceEmail'),
              validateEmail,
              isDisabledFields,
              translate('fields.helpertextServiceEmail')
            )
          }
        />
        <Field
          name="client.person"
          render={renderPersonWithRightToSign}
        />
      </div>
    </div>
  )
}

export default LegalEntity