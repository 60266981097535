import * as React from 'react'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  TextInput, useTranslate, ReferenceInput, AutocompleteInput, useNotify, useDataProvider,
  useRefresh
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Button, CircularProgress, TextField } from '@material-ui/core'
import { useForm, Field } from 'react-final-form'
import moment from 'moment'
import { transform } from '../../../../utils'
import {postLogs, refreshSupplierData} from '../../../../../../api/customApi'
import ConfirmationVerifyModal from '../../../../../../components/ConfirmationVerifyModal'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
      maxWidth: 'calc(100vw - 48px)'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
  input: {
    width: '100%'
  },
  status: {
    gridColumn: '1 / 2',
  },
  solution: {
    gridColumn: '1 / 3',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 2'
    }
  },
  button: {
    gridColumn: '2 / 3',
    width: 250,
    justifySelf: 'end',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 2',
      width: '100%',
    }
  },
  buttonEdit: {
    gridColumn: '2 / 3',
    width: 250,
    justifySelf: 'end',
    padding: '0 16px',
    color: '#662211',
    background: '#F2B8B5',
    '&:hover': {
      background: '#eea6a3'
    },
    '&.Mui-disabled': {
      background: 'transparent',
    }
  }
}))

const TradeAccount = ({ handleTabs }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const notify = useNotify()
  const refresh = useRefresh()
  const currentDocumentKinds = useSelector(state => state.customReducer.currentDocumentKinds)
  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const docStatuses = useSelector(state => state.customReducer.docStatuses)
  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const [isLoadingRefreshData, setLoadingRefreshData] = useState()
  const [isOpenConfirmationVerifyModal, setOpenConfirmationVerifyModal] = useState(false)

  const dataProvider = useDataProvider()

  const toggleConfirmationVerifyModal = useCallback(() => {
    setOpenConfirmationVerifyModal(prev => !prev)
  }, [])

  const onProvide = useCallback(async () => {
    postLogs("TradeAccount", "ALL", {
      message: `onProvide start`,
      stack: "90 line"
    });
    const fieldsNames = form.getRegisteredFields()
    fieldsNames.forEach(name => form.blur(name))


    const errors = form.getState().errors
    const values = form.getState().values

    if (Object.keys(errors).length) {
      postLogs("TradeAccount", "ALL", {
        message: `onProvide warning ra.message.invalid_form`,
        stack: "102 line"
      });
      notify('ra.message.invalid_form', 'warning')
      handleTabs({}, 0)
      return
    }

    const hasPowerOfAttorney = values.attorneyLetters?.length
    if (!hasPowerOfAttorney) {
      postLogs("TradeAccount", "ALL", {
        message: `onProvide warning addRequiredPowerOfAttorney`,
        stack: "113 line"
      });
      notify('errors.addRequiredPowerOfAttorney', 'warning')
      handleTabs({}, 2)
      return
    }

    const isRequiredDocsAdded = !currentDocumentKinds.find(kind => {
      const constituentDoc = values.constituentDocs.find(doc => doc.docKind.identifier === kind.identifier)
      const statusDeclined = docStatuses.find(doc => doc.identifier === constants.FOUNDING_DOCUMENT_STATUS_REJECTED_I)
      return (
        (kind.required && !constituentDoc) || (constituentDoc && constituentDoc.foundingDocumentStatus.identifier === statusDeclined.identifier)
      )
    })

    if (!isRequiredDocsAdded) {
      postLogs("TradeAccount", "ALL", {
        message: `onProvide warning errors.addRequiredDocs`,
        stack: "131 line"
      });
      notify('errors.addRequiredDocs', 'warning')
      handleTabs({}, 1)
      return
    }

    try {
      const values = form.getState().values
      const data = await transform(constants)(values)

      const res = await dataProvider.update('suppliers', { id: data.id, data, verify: true })
      postLogs("TradeAccount", "ALL", {
        message: `onProvide res: ${JSON.stringify(res)}`,
        stack: "145 line"
      });
      if (res?.data?.responseBody) {
        postLogs("TradeAccount", "ALL", {
          message: `onProvide notify.dataSaved`,
          stack: "150 line"
        });
        notify(translate('notify.dataSaved'))
        refresh()
      }
      if (res?.data?.errorMessage) {
        postLogs("TradeAccount", "ERROR", {
          message: `onProvide errorMessage: ${res.data.errorMessage}`,
          stack: "158 line"
        });
        notify(res.data.errorMessage, 'warning')
      }
    } catch (err) {
      postLogs("TradeAccount", "ERROR", {
        message: `onProvide err: ${err}`,
        stack: "165 line"
      });
      notify(err.message, 'warning')
    }
    postLogs("TradeAccount", "ALL", {
      message: `onProvide end`,
      stack: "171 line"
    });
  }, [form, currentDocumentKinds, providerStatuses, docStatuses, constants])

  const onRefresh = useCallback(async() => {
    postLogs("TradeAccount", "ALL", {
      message: `onRefresh start`,
      stack: "178 line"
    });
    setLoadingRefreshData(true)

    try {
      const values = form.getState().values
      const res = await refreshSupplierData(values.id)
      postLogs("TradeAccount", "ALL", {
        message: `onRefresh res: ${JSON.stringify(res)}`,
        stack: "187 line"
      });
      if(res.responseBody && res.errorMessage){
        postLogs("TradeAccount", "ERROR", {
          message: `onRefresh res.errorMessage: ${res.errorMessage}`,
          stack: "192 line"
        });
        notify(res.errorMessage, 'warning')
        refresh()
      }
      else if (res.responseBody) {
        refresh()
      }
      else if (res.errorMessage) {
        postLogs("TradeAccount", "ERROR", {
          message: `onRefresh res.errorMessage: ${res.errorMessage}`,
          stack: "203 line"
        });
        notify(res.errorMessage, 'warning')
      }
    } catch (err) {
      postLogs("TradeAccount", "ERROR", {
        message: `onRefresh err: ${err}`,
        stack: "210 line"
      });
      console.log(err)
    }
    setLoadingRefreshData(false)
    postLogs("TradeAccount", "ALL", {
      message: `onRefresh end`,
      stack: "217 line"
    });
  }, [form])

  const renderDateField = ({ value, label }) => () => {
    return (
      <TextField
        className={classes.input}
        label={label}
        value={value}
        disabled
        variant="filled"
        helperText=""
      />
    )
  }

  return (
    <div className={classes.root}>
      <Field
        name="client.createDate"
        render={
          renderDateField({
            value: form.getState().values.client.createDate ?
              moment(form.getState().values.client.createDate).format('DD.MM.YYYY') : '',
            label: translate('fields.createDate')
          })
        }
      />
      <Field
        name="client.presentationDate"
        render={
          renderDateField({
            value: form.getState().values.client.presentationDate ?
              moment(form.getState().values.client.presentationDate).format('DD.MM.YYYY') : '',
            label: translate('fields.presentationDate')
          })
        }
      />
      <TextInput
        className={`${classes.input} ${classes.status}`}
        label={translate('fields.status')}
        reference="providerStatuses"
        source="client.providerStatus.name"
        allowEmpty
        disabled
        helperText=''
      />
      <ReferenceInput
        className={classes.input}
        label={translate('fields.tradeAccount')}
        reference="tradeAccounts"
        source="client.tradeAccount.id"
        allowEmpty
      >
        <AutocompleteInput
          optionText={(item) => item.name}
          allowEmpty
          disabled
          helperText=''
        />
      </ReferenceInput>
      <TextInput
        className={`${classes.input} ${classes.solution}`}
        source='client.solution'
        label={translate('fields.solution')}
        disabled
      />
      {(
        supplierStatus !== constants?.INACTIVE_PROVIDER_STATUS_I &&
        supplierStatus !== constants?.ACTIVE_PROVIDER_STATUS_I
      ) ? (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          startIcon={loading !== 0 ? <CircularProgress size={14} /> : ''}
          onClick={(
              form.getState().values.client.hadActiveStatus &&
              supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I
            ) ? toggleConfirmationVerifyModal : onProvide
          }
          disabled={loading !== 0 || !(
            supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
            supplierStatus === constants?.DECLINED_PROVIDER_STATUS_I ||
            supplierStatus === constants?.PART_CONFIRMED_DATA_I ||
            supplierStatus === ''
          )}
        >
          {translate('buttons.provide')}
        </Button>
      ) : (
        <Button
          className={classes.buttonEdit}
          variant="contained"
          color="primary"
          startIcon={(loading !== 0 || isLoadingRefreshData) ? <CircularProgress size={14} /> : ''}
          onClick={onRefresh}
          disabled={loading !== 0 || isLoadingRefreshData}
        >
          {translate('buttons.editData')}
        </Button>)
      }
      <ConfirmationVerifyModal
        onProvide={onProvide}
        isOpen={isOpenConfirmationVerifyModal}
        toggle={toggleConfirmationVerifyModal}
      />
    </div>
  )
}

export default TradeAccount