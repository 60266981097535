import * as React from 'react'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { BulkDeleteButton } from 'react-admin'

const BulkActionButtons = (props) => {
  const suppliers = useSelector(state => state.admin.resources?.suppliers?.data)
  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const constants = useSelector(state => state.customReducer.constants)

  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const draftStatus = providerStatuses.find(status => (
      status.identifier === constants?.DRAFT_PROVIDER_STATUS_I
    ))

    const activeSupplier = props.selectedIds.find(id => (
      suppliers[id].client?.providerStatus && suppliers[id].client?.providerStatus?.identifier !== draftStatus.identifier
    ))
    setDisabled(!!activeSupplier)
  }, [suppliers, props.selectedIds])

  return (
    <BulkDeleteButton
      {...props}
      undoable={false}
      disabled={disabled}
    />
  )
}

export default BulkActionButtons