import * as React from 'react'
import { useCallback, useState, useEffect } from 'react'
import ModalBase from '../../../../../../../../components/ModalBase'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useTranslate, useDataProvider, email, useNotify } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import MuiPhoneNumber from 'material-ui-phone-number'
import { validPhone } from '../../../../../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      flex: 'unset',
      gridTemplateColumns: '1fr',
    },
  },
  paperStyle: {
    width: '100%'
  },
  input: {
    marginTop: 8,
    marginBottom: 4,
  },
}))

const initValues = {
  active: false,
  lastName: '',
  firstName: '',
  middleName: '',
  name: '',
  phone: '',
  email: '',
  login: ''
}

const initErrors = {
  lastName: 'ra.validation.required',
  firstName: 'ra.validation.required',
  phone: 'ra.validation.required',
  email: 'ra.validation.required'
}

const initBlurred = {
  lastName: false,
  firstName: false,
  phone: false,
  email: false,
}

const Modal = ({ isOpen, toggle }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [values, setValues] = useState(initValues)
  const [errors, setErrors] = useState(initErrors)
  const [blurred, setBlurred] = useState(initBlurred)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setValues(initValues)
      setErrors(initErrors)
      setBlurred(initBlurred)
      setLoading(false)
    }
  }, [isOpen])

  const onChange = useCallback(field => e => {
    const value = typeof e === 'string' ? e : e.target.value
    validate(field, value)
    const newValues = { ...values, [field]: value }
    if (field === 'email') {
      newValues.login = newValues.email
    }
    if (field === 'lastName' || field === 'firstName' || field === 'middleName') {
      newValues.name =
        `${values.lastName ? `${values.lastName} ` : ''}${values.firstName ? `${values.firstName[0]}. ` : ''}${values.middleName ? `${values.middleName[0]}.` : ''}`
    }
    setValues(newValues)
  }, [values, blurred])

  const onBlur = useCallback(field => () => {
    setBlurred({
      ...blurred,
      [field]: true
    })
  }, [blurred])

  const validate = useCallback((field, value) => {
    const newErrors = { ...errors }
    if (!value) {
      newErrors[field] = 'ra.validation.required'
    } else if (field === 'email') {
      newErrors[field] = email()(value)?.message ?? ''
    } else if (field === 'phone') {
      if(!value?.replace('+', '')) {
        newErrors[field] = 'ra.validation.required'
      }
      else {
        newErrors[field] = validPhone(value)
      }
    } else {
      newErrors[field] = ''
    }
    setErrors(newErrors)
    return newErrors[field]
  }, [errors])

  const handleCreate = useCallback(async () => {
    const errorsKeys = Object.keys(errors)
    const isValid = !errorsKeys.find(field => errors[field])
    const blurredKeys = Object.keys(blurred)
    const allBlurred = {}
    blurredKeys.forEach(field => {
      allBlurred[field] = true
    })
    setBlurred(allBlurred)

    if (isValid) {
      setLoading(true)
      const data = {
        ...values,
        phone: values.phone.replace(/[\+\(\)\s\-]/g, '')
      }
      try {
        await dataProvider.create('users', { data })
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
      toggle()
    } else {
      notify('ra.message.invalid_form', 'warning')
    }
  }, [values, errors, blurred])

  const onFocus = () => {
    if (values.phone === undefined) {
      values.phone = ''
    }
  }

  const customActions = () => (
    <Button
      variant="contained"
      color="primary"
      startIcon={loading ? <CircularProgress size={14} /> : <AddIcon />}
      onClick={handleCreate}
      disabled={loading}
    >
      {translate('buttons.add')}
    </Button>
  )

  return (
    <ModalBase
      title={translate('resources.users.create')}
      handleClose={toggle}
      isOpen={isOpen}
      contentClasses={classes.content}
      paperStyle={classes.paperStyle}
      maxWidth='md'
      customActions={customActions}
    >
      <TextField
        className={classes.input}
        variant="filled"
        label={translate('fields.lastName')}
        value={values.lastName}
        onChange={onChange('lastName')}
        onBlur={onBlur('lastName')}
        required
        disabled={loading}
        helperText={(blurred.lastName && errors.lastName) ? translate(errors.lastName) : ''}
        error={blurred.lastName && errors.lastName}
      />
      <TextField
        className={classes.input}
        variant="filled"
        label={translate('fields.firstName')}
        value={values.firstName}
        onChange={onChange('firstName')}
        onBlur={onBlur('firstName')}
        required
        disabled={loading}
        helperText={(blurred.firstName && errors.firstName) ? translate(errors.firstName) : ''}
        error={blurred.firstName && errors.firstName}
      />
      <TextField
        className={classes.input}
        variant="filled"
        label={translate('fields.middleName')}
        value={values.middleName}
        onChange={onChange('middleName')}
        disabled={loading}
      />
      <TextField
        className={classes.input}
        variant="filled"
        label={translate('fields.fullName')}
        value={values.name}
        onChange={onChange('name')}
        disabled={loading}
      />
      <MuiPhoneNumber
        className={classes.input}
        label={translate('fields.phone')}
        variant="filled"
        value={`+${values.phone}`}
        onFocus={onFocus}
        onChange={onChange('phone')}
        onBlur={onBlur('phone')}
        helperText={(blurred.phone && errors.phone) ? translate(errors.phone) : ''}
        error={blurred.phone && errors.phone}
        disabled={loading}
        placeholder="+7 (999) 999-99-99"
        required
      />
      <TextField
        className={classes.input}
        variant="filled"
        value={values.email}
        onChange={onChange('email')}
        onBlur={onBlur('email')}
        label={translate('fields.email')}
        required
        disabled={loading}
        helperText={(blurred.email && errors.email) ? translate(errors.email) : ''}
        error={blurred.email && errors.email}
      />
    </ModalBase>
  )
}

export default Modal