import * as React from 'react'
import { Pagination, PaginationActions, useTranslate } from 'react-admin'

const CustomPagination = props => {
  const translate = useTranslate()
  const totalPages = () => {
    return Math.ceil(props.total / props.perPage) || 1
  }

  const handlePageChange = (event, page) => {
    event && event.stopPropagation()
    if (page < 0 || page > totalPages - 1) {
      throw new Error(
        translate('ra.navigation.page_out_of_boundaries', {
          page: page + 1,
        })
      )
    }
    props.setPage(page + 1)
  }

  return (
    <Pagination
      rowsPerPageOptions={[25, 50, 100, 250]}
      actions={(props) => (
        <PaginationActions
          {...props}
          onChangePage={handlePageChange}
        />
      )}
      {...props}
    />
  )
}

export default CustomPagination