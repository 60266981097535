import React, { useCallback, useState } from 'react'
import { number, required, TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getBank } from '../../../../../../../../api/customApi'
import { lineLength } from '../../../../../../../../helpers/utils'
import InfoPopover from "../../../../../../../../components/InfoPopover";

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
    marginTop: 8,
    marginBottom: 4,
  }
}))

const FieldBIC = ({ inputProps, touched, disabled }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const validateBIC = [required(), number(), lineLength(9, translate)]

  const { values, errors } = useFormState()

  const [errorFromRequest, setErrorFromRequest] = useState('')

  const customValid = useCallback(() => (
    errorFromRequest ?? undefined
  ), [errorFromRequest])

  const onBlur = useCallback(async e => {
    inputProps.onBlur(e)

    if (
      values.client?.bic && (!errors.client?.bic
        || (errors.client?.bic && !errors.client?.bic.includes('ra.validation.')))
    ) {
      const bankInfo = await getBank(values.client.bic)
      if (bankInfo.responseBody) {
        setErrorFromRequest('')
        inputProps.onChange({
          target: {
            value: {
              ...values.client,
              ...bankInfo.responseBody
            }
          }
        })
      }

      if (bankInfo.errorMessage) {
        setErrorFromRequest(bankInfo.errorMessage)
        inputProps.onChange({
          target: {
            value: {
              ...values.client
            }
          }
        })
      }
    }
  }, [values, errors])

  const onChange = useCallback(e => {
    inputProps.onChange({
      target: {
        value: {
          ...values.client,
          bankFullName: '',
          bankLocation: '',
          correspondentAccountNumber: '',
          bic: e.target.value
        }
      }
    })
  }, [values])

  return (
    <>
      <TextField
        className={classes.input}
        label={translate('fields.bankCode')}
        variant="filled"
        onChange={onChange}
        onBlur={onBlur}
        value={values.client?.bic ?? ''}
        error={errors.client?.bic}
        inputProps={{ maxLength: 9 }}
        helperText={(touched) ? translate(errors.client?.bic) : ''}
        required
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InfoPopover
              id="bic"
              content={translate('fields.bankCodeHint')}
            />
          )
        }}
      />
      <TextInput
        source="client.bic"
        validate={[...validateBIC, customValid]}
        disabled
        style={{ display: 'none' }}
      />
    </>

  )
}

export default FieldBIC