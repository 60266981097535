import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslate } from 'react-admin'
import { useFormState, Field } from 'react-final-form'
import { Tabs, Tab } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { setOrganizationType, setSupplierStatus } from '../../../../reducer/actions'

import TradeAccount from './components/TradeAccount'
import ClientCard from './components/ClientCard'
import ConstituentDocs from './components/ConstituentDocs'
import PowerOfAttorney from './components/PowerOfAttorney'
import InformationLetter from './components/InformationLetter'

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    padding: `9px 16px ${theme.spacing(2)}px`,
  },
  tab: {
    color: '#1565C0',
    letterSpacing: 0,
    '&.Mui-selected': {
      fontWeight: 700,
    },
    [theme.breakpoints.down('xs')]: {
      width: '50%'
    }
  },
  tabs: {
    marginBottom: 54
  }
}))

const Form = ({ tab, handleTabs, className }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dispatch = useDispatch()
  const constants = useSelector(state => state.customReducer.constants)
  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)
  const featureFlags = useSelector(state => state.customReducer.featureFlags)

  const { values, modified } = useFormState()

  useEffect(() => {
    const modifiedForCheck = { ...modified }
    delete modifiedForCheck['client.supplierFounders']
    delete modifiedForCheck.constituentDocs

  }, [modified])

  useEffect(() => {
    if (providerStatuses.length) {
      const statusIdentifier = providerStatuses.find(status => status.identifier === values.client?.providerStatus?.identifier)?.identifier
      dispatch(setSupplierStatus(statusIdentifier ?? ''))
    }
  }, [values.client?.providerStatus, providerStatuses])

  useEffect(() => {
    if (values.organizationType?.name) {
      let orgType = ''
      switch (values.organizationType.name) {
        case constants?.JURIDICAL_ORGANIZATION_TYPE_NAME: {
          orgType = 'LEGAL'
          break
        }
        case constants?.INDIVIDUAL_ORGANIZATION_TYPE_NAME: {
          orgType = 'INDIVIDUAL'
          break
        }
      }

      dispatch(setOrganizationType(orgType))
    }
  }, [values, constants])

  const renderConstituentDocs = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => {
    return (
      <ConstituentDocs
        active={tab === 1}
        inputProps={inputProps}
      />
    )
  }

  const renderPowerOfAttorney = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => {
    return (
      <PowerOfAttorney
        active={tab === 2}
        inputProps={inputProps}
      />
    )
  }
  const renderInformationLetter = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => {
    return (
      <InformationLetter
        active={tab === 3}
        inputProps={inputProps}
      />
    )
  }

  return (
    <div className={`${className} ${classes.form}`}>
      <TradeAccount handleTabs={handleTabs} />
      <Tabs
        className={classes.tabs}
        value={tab}
        onChange={handleTabs}
        variant="scrollable"
      >
        <Tab className={classes.tab} label={translate('formSuppliers.tabs.clientCard')} />
        <Tab className={classes.tab} label={translate('formSuppliers.tabs.constituentDocs')} />
        <Tab className={classes.tab} label={translate('formSuppliers.tabs.powerOfAttorney')} />
        {(!featureFlags.features?.informationLetter?.disabled &&
          supplierStatus !== constants?.DRAFT_PROVIDER_STATUS_I &&
          supplierStatus !== ''
        ) && (
            <Tab className={classes.tab} label={translate('formSuppliers.tabs.informationLetter')} />
          )}
      </Tabs>
      <ClientCard active={tab === 0} />
      <Field
        name="constituentDocs"
        render={renderConstituentDocs}
      />
      <Field
        name="attorneyLetters"
        render={renderPowerOfAttorney}
      />
      {(!featureFlags.features?.informationLetter?.disabled &&
        supplierStatus !== constants?.DRAFT_PROVIDER_STATUS_I &&
        supplierStatus !== ''
      ) && (
          <Field
            name="informationLetters"
            render={renderInformationLetter}
          />
        )}
    </div>
  )
}

export default Form