import * as React from 'react'
import {useCallback} from 'react'
import {useTranslate, TextInput} from 'react-admin'
import {useFormState} from 'react-final-form'
import MuiPhoneNumber from 'material-ui-phone-number'
import {FormControl} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {validPhone} from '../../../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  formControl: {
    paddingBottom: 4,
    gridColumn: '1 / 2',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 3',
      maxWidth: 'calc(100vw - 48px)',
      marginBottom: props => props.marginBottom,
    },
  },
  phone: {
    '& > div': {
      marginTop: 8,
    },
    '& label': {
      transform: 'translate(12px, 14px) scale(0.75) !important'
    },

  },
  input: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      gridColumn: '1 / 3'
    },
  },
  hide: {
    display: 'none'
  }
}))


const PhoneNumberInput = ({ inputProps, touched, style, disabled }) => {
  const translate = useTranslate()

  const { values, errors, submitFailed } = useFormState()
  const classes = useStyles({
    marginBottom: (errors?.client?.phone && (submitFailed || touched))  ? 24 : 12
  })

  const onChangePhone = (value) => {
    inputProps.onChange({
      target: {
        value
      }
    })
  }
  const onFocus = () => {
    if (values.phone === undefined) {
      values.phone = ''
    }
  }

  const validate = useCallback((value) => {
    return (
      !value?.replace('+', '') ? translate('ra.validation.required') : ''
    )
  }, [])

  return (
    <>
      <FormControl className={`${classes.formControl} ${style}`}>
        {values.client && <MuiPhoneNumber
          className={`${classes.input} ${classes.phone}`}
          variant="filled"
          onFocus={onFocus}
          label={translate('fields.phone')}
          onChange={onChangePhone}
          onBlur={inputProps.onBlur}
          value={`+${values.client.phone ?? ''}`}
          placeholder="+7 (999) 999-99-99"
          helperText={(submitFailed || touched) && (translate(errors.client?.phone) ?? '')}
          error={errors.client?.phone}
          required
          disabled={disabled}
        />}
      </FormControl>
      <TextInput
        className={classes.hide}
        source="client.phone"
        validate={[validate, validPhone]}
        disabled
      />
    </>
  )
}

export default PhoneNumberInput