import * as React from 'react'
import { useEffect } from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InfoPopover from '../../../../../../components/InfoPopover'

const useStyles = makeStyles((theme) => ({
  inputComment: {
    gridColumn: '1 / 3',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    '& > div': {
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 2',
    },
  },
  hint: {
    marginTop: -17
  },
}))

const CommentField = ({
                        inputProps,
                        disabled,
                        validate,
                        meta: { touched, error } = { touched: false, error: undefined },
                        ...props }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { values } = useFormState()

  useEffect(() => {
    return () => {
      inputProps.onChange({
        target: {
          value: null
        }
      })
    }
  }, [values.attorneyLetterAct?.id])

  return (
    <>
      <TextField
        className={classes.inputComment}
        label={translate('fields.comment')}
        multiline
        rows={5}
        variant="filled"
        value={values.supplierComment ?? ''}
        onBlur={inputProps.onBlur}
        onChange={inputProps.onChange}
        error={touched && error}
        helperText={(touched && error) ? translate(error) : ''}
        disabled={disabled}
        InputProps={{
          endAdornment: (
            <InfoPopover
              id="attorneyLetterComment"
              content={translate('attorneyLettersModal.commentHint')}
              iconClass={classes.hint}
            />
          )
        }}
      />
      <TextInput
        source="supplierComment"
        disabled
        validate={validate}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default CommentField