export const languageOptions = [
  {
    value: "ru",
    name: "Русский",
  },
  {
    value: "en",
    name: "English",
  },
]

export const yesNoOptions = [
  { value: 'YES', name: 'fields.yes' },
  { value: 'NO', name: 'fields.no' }
]

export const yesNoOptionsBoolean = [
  { value: true, name: 'fields.yes' },
  { value: false, name: 'fields.no' }
]

export const resources = {
  suppliers: {
    source: "vp_Supplier",
    view: "api-edit",
    operator: "contains"
  },
  organizationTypes: {
    source: "vp_OrganizationType",
    view: "",
    operator: "="
  },
  clients: {
    source: "vp$Client",
    view: ""
  },
  constituentDocs: {
    source: "vp$ConsituentDoc",
    view: ""
  },
  legalAddresses: {
    source: "vp$LegalAddress",
    view: ""
  },
  vatPaymentAbsenceBasises: {
    source: "vp_VatPaymentAbsenceBasis",
    view: ""
  },
  users: {
    source: "vp$User",
    view: "api-contactPersons"
  },
  tradeAccounts: {
    source: "vp$TradeAccount",
    view: ""
  },
  persons: {
    source: "vp$Person",
    view: ""
  },
  providerStatuses: {
    source: "vp$ProviderStatus",
    view: ""
  },
  attorneyLetters: {
    source: "vp_AttorneyLetter",
    view: "attorneyLetter-edit"
  },
  pointsOfSale: {
    source: "vp_PointOfSale",
    view: "edit"
  },
  distributionCenters: {
    source: "vp_DistributionCenter",
    view: "edit"
  },
  informationLetter: {
    source: "vp_InformationLetter",
    view: "edit"
  },
  supplierFounders: {
    source: "vp_SupplierFounder",
    view: "supplier-founder-edit"
  },
}

export const typeOfDCOptions = [
  { value: 'DC_OWNED_BY', name: 'fields.dcOwnedBy' },
  { value: 'DC_CONTROLLED_BY', name: 'fields.dcControlledBy' }
]

export const genderOptions = [
  { value: 'MALE', name: 'fields.male' },
  { value: 'FEMALE', name: 'fields.female' }
]

export const fieldsCharter = [
  { name: 'supplierComment', required: false },
  { name: 'confirmation', required: true }
]

export const fieldsDatePowerofattorney = [
  { name: 'issueDate', required: true },
  { name: 'validFrom', required: false },
  { name: 'validUntil', required: true },
]

export const fieldsPowerofattorney = [
  { name: 'attorneyLetterNumber', required: true },
  ...fieldsDatePowerofattorney,
  { name: 'file', required: true },
]

export const generalFields = [
  { name: 'lastName', required: true },
  { name: 'firstName', required: true },
  { name: 'middleName', required: false },
  { name: 'signerName', required: true },
  { name: 'position', required: false },
  { name: 'gender', required: true },
]

export const legalAddressSupplierArray = [
  { name: 'index', required: true },
  { name: 'region', required: true },
  { name: 'district', required: false },
  { name: 'town', required: true },
  { name: 'street', required: true },
  { name: 'house', required: true },
  { name: 'housing', required: false },
  { name: 'building', required: false },
  { name: 'apartment', required: false }
]

export const stylesList = {
  root: {
    '& > .MuiToolbar-root': {
      justifyContent: 'flex-start',
    },
    '& div > .MuiPaper-root': {
      marginTop: 0,
      transition: 'unset !important',
    }
  },
  bulkActionsDisplayed: {
    '& > .MuiToolbar-root': {
      marginTop: '-64px !important',
      transition: `height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, min-height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin-top  300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important`,
    },
  },
  hasChanges: {
    '& > .MuiToolbar-root': {
      fontWeight: "bold"
    },
  }
}