import * as React from 'react'
import { useCallback } from 'react'
import { Datagrid, DatagridBody, DatagridRow, useTranslate } from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'

import { Checkbox, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'block',
    marginBottom: theme.spacing(2),
    padding: '0px 12px'
  }
}));

const PointsOfSaleDatagridRow = (props) => {
  const { record, resource, id, children, basePath } = props

  const stopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])

  return (
    <DatagridRow
      key={id}
      {...props}
    >
      {React.Children.map(children, field => (
        field.props.source === 'includedInInformationLetter' ? (
          <Checkbox
            checked={record.includedInInformationLetter}
            onClick={stopPropagation}
            color="primary"
            disabled={true}
          />
        ) : (
            React.cloneElement(field, {
              record,
              basePath,
              resource,
            })
          )
        )
      )}
    </DatagridRow>
  )
}

const PointsOfSaleDatagridBody = (props) => (
  <DatagridBody
    {...props}
    row={
      <PointsOfSaleDatagridRow />
    }
  />
)

const PointsOfSaleDatagrid = (props) => {

  const translate = useTranslate();
  const classes = useStyles();
  const CustomEmpty = () => <Typography class={classes.text} variant="h9">{translate('pointsOfSale.emptyList')}</Typography>;

  return (
    <Datagrid
      empty={<CustomEmpty/>}
      {...props}
      body={
        <PointsOfSaleDatagridBody />
      }
    />
  )
}

export default PointsOfSaleDatagrid