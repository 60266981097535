import React from 'react'
import { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  TopToolbar,
  EditButton,
  useTranslate,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import ContentAdd from '@material-ui/icons/Add'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import DeleteIcon from '@material-ui/icons/Delete'
import CustomAction from '../../../../../../../../../../components/CustomAction'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative'
  }
}))

const ListButtons = ({
                       ids,
                       toggleModal,
                       isDisabledCreate,
                       isOpenCopy,
                       data,
                       onDelete
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const [disableDelete, setDisableDelete] = useState(false)

  useEffect(() => {
    let isDisableDelete = false
    ids.find((id, index) => {
      if (data[id].id) {
        isDisableDelete = true
        return true
      } else if (index === (ids.length - 1)) {
        isDisableDelete = false
      }
    })

    setDisableDelete(isDisableDelete)
  }, [ids, data])

  const disabledActions = useMemo(() => (
    loading !== 0 || !(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === ''
    )
  ), [supplierStatus, constants, loading])

  return (
    <div>
      <TopToolbar className={classes.container}>
        {!isDisabledCreate && (
          <CustomAction
            label={translate('buttons.add')}
            icon={<ContentAdd />}
            onAction={toggleModal('create')}
            disabled={disabledActions}
          />
        )}
        <EditButton
          basePath="/suppliers"
          disabled={ids.length !== 1}
          onClick={toggleModal('edit')}
        />
        {isOpenCopy && (
          <CustomAction
            label={translate('buttons.clone')}
            icon={<AddToPhotosIcon />}
            onAction={toggleModal('copy')}
            disabled={ids.length !== 1 || disabledActions}
          />
        )}
        <CustomAction
          label={translate('ra.action.delete')}
          color="red"
          icon={<DeleteIcon />}
          onAction={onDelete}
          disabled={ids.length < 1 || disableDelete || disabledActions}
        />
      </TopToolbar>
    </div>
  )
}

export default ListButtons
