import * as React from 'react'
import {
  Create, SimpleForm, useTranslate
} from 'react-admin'
import Form from '../components/Form'
import UserEditToolbar from '../components/Toolbar'

const UserCreate = props => {
  const translate = useTranslate()
  return (
    <Create
      {...props}
      title={translate('resources.users.create')}
    >
      <SimpleForm
        toolbar={
          <UserEditToolbar mode="create" />
        }
      >
        <Form mode="create" />
      </SimpleForm>
    </Create>
  )
}

export default UserCreate