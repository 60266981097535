import React, {useState} from 'react'
import { Popover } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    maxWidth: '500px',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 48px)'
    }
  },
  hint: {
    width: 22,
    height: 22,
    padding: 8
  }
}))

const InfoPopover = ({ id, content, iconClass = '' }) => {
  const classes = useStyles()

  const [anchorPopoverEl, setAnchorPopoverEl] = useState(null)

  const handlePopoverOpen = (event) => {
    event.preventDefault()
    setAnchorPopoverEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorPopoverEl(null)
  }

  return (
    <>
      <InfoIcon
        className={`${classes.hint} ${iconClass}`}
        aria-owns={!!anchorPopoverEl ? `mouse-over-popover-${id}` : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id={`mouse-over-popover-${id}`}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={!!anchorPopoverEl}
        anchorEl={anchorPopoverEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {content}
      </Popover>
    </>
  )
}

export default  InfoPopover