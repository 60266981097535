import React, {useCallback} from 'react'
import { useEffect } from 'react'
import { useTranslate, required, TextInput, number } from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { lineLength } from '../../../../../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 8,
    marginBottom: 4,
  },
  hide: {
    display: 'none'
  }
}))

const BiggestTaxpayerKpp = ({ inputProps, touched, disabled }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { values, errors, submitFailed } = useFormState()

  const validateKpp = [required(), number(), lineLength(9, translate)]

  useEffect(() => {
    return () => {
      if (values.client?.biggestTaxpayer !== 'YES') {
        inputProps.onChange({
          target: {
            value: ''
          }
        })
      }
    }
  }, [])

  const onChange = useCallback((e) => {
    inputProps.onChange({
      target: {
        value: e.target.value
      }
    })
  }, [inputProps])

  return (
    <>
      <TextField
        className={classes.input}
        label={translate('fields.biggestTaxpayerKpp')}
        variant="filled"
        value={values.client?.biggestTaxpayerKpp ?? ''}
        onChange={onChange}
        onBlur={inputProps.onBlur}
        helperText={(submitFailed || touched) && translate(errors.client?.biggestTaxpayerKpp ?? '')}
        error={errors.client?.biggestTaxpayerKpp}
        inputProps={{ maxLength: 9 }}
        required
        disabled={disabled}
      />
      <TextInput
        className={classes.hide}
        source="client.biggestTaxpayerKpp"
        validate={validateKpp}
        disabled
      />
    </>
  )
}

export default BiggestTaxpayerKpp