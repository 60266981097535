import React from 'react'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslate, number, useNotify, SelectInput } from 'react-admin'
import {
  IconButton,
  FormControl,
  TextField,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { useFormState } from 'react-final-form'
import FounderModalBase from './components/FounderModalBase'
import FTextField from './components/FounderTextField'
import MoreThenFiftyPercentSelect from './components/MoreThenFiftyPercentSelect'
import { v4 as uuidv4 } from 'uuid';
import { getFullName } from '../../../../../../../../helpers/utils'
import { yesNoOptionsBoolean } from '../../../../../../../../helpers/enums'
import { getSupplierFounderByTin } from '../../../../../../../../api/customApi'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 8,
    marginBottom: 4,
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  organizationFounder: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 32,
    '& > div >div': {
      paddingRight: '0',
    },
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  subtitle: {
    margin: `${theme.spacing(2)}px 0`,
    gridColumn: '1 / 3',
    [theme.breakpoints.down('xs')]: {
      gridColumn: '1 / 2',
    },
  },
  fullName: {
    gridColumn: '1 / 3',
      [theme.breakpoints.down('xs')]: {
        gridColumn: '1 / 2',
      },
  }
}))

const CreateAndEditSupplierFounder = ({
                                        isOpen,
                                        toggle,
                                        data,
                                        mode,
                                        value,
                                        setValue,
                                        inputProps,
                                        globalData,
                                        selected,
                                        setSelected,
                                        error,
                                        parentClient
                                      }) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()
  const form = useFormState()
  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const [modalValues, setModalValues] = useState({
    subjectType: '',
    firstName: '',
    lastName: '',
    middleName: '',
    fullName: '',
    name: '',
    tin: '',
    iec: '',
    hasMoreThanFiftyPercent: '',
    headLastName: '',
    headFirstName: '',
    headMiddleName: '',
    parentSupplier: {},
    foreignCompany: false
  })

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    fullName: '',
    name: '',
    tin: '',
    iec: '',
    hasMoreThanFiftyPercent: '',
    headLastName: '',
    headFirstName: '',
  })

  const [blurred, setBlurred] = useState({
    firstName: false,
    lastName: false,
    fullName: false,
    name: false,
    tin: false,
    iec: false,
    hasMoreThanFiftyPercent: false,
    headLastName: false,
    headFirstName: false,
  })

  const [open, setOpen] = useState(false)
  let isShow = useMemo(() => (
    modalValues.hasMoreThanFiftyPercent === 'YES' ? true : false
  ), [modalValues])
  const classes = useStyles({ isShow })

  const disabledByStatus = useMemo(() => (
    loading !== 0 || !(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === ''
    )
  ), [constants, loading, supplierStatus])

  const individualFields = useMemo(() => (
    [
      {
        field: 'lastName',
        required: !modalValues.foreignCompany,
        disabled: !(modalValues.accessIndividualName === null) || disabledByStatus
      },
      {
        field: 'firstName',
        required: !modalValues.foreignCompany,
        disabled: !(modalValues.accessIndividualName === null) || disabledByStatus
      },
      {
        field: 'middleName',
        disabled: !(modalValues.accessIndividualName === null) || disabledByStatus
      },
      {
        field: 'name',
        translate: 'fields.nameFounderIndividual',
        required: !modalValues.foreignCompany,
        disabled: !(modalValues.accessToName === null) || disabledByStatus
      }
    ]
  ), [modalValues, disabledByStatus, constants, loading, supplierStatus])

  const legalFields = useMemo(() => (
    [
      {
        field: 'tin',
        translate: 'fields.inn',
        required: !modalValues.foreignCompany,
        disabled: !(modalValues.accessToEditTin === null) || disabledByStatus,
      },
      {
        field: 'iec',
        translate: 'fields.kpp',
        disabled: !(modalValues.accessToEditIec === null) || disabledByStatus,
      },
      {
        field: 'fullName',
        translate: 'fields.fullNameFounder',
        required: true,
        disabled: !(modalValues.accessFullName === null) || disabledByStatus,
        formClass: classes.fullName
      },
      {
        field: 'name',
        translate: 'fields.nameFounderOrganization',
        required: !modalValues.foreignCompany,
        disabled: !(modalValues.accessToName === null) || disabledByStatus
      }
    ]
  ), [modalValues, classes, isShow, disabledByStatus, constants, loading, supplierStatus])

  const headFields = useMemo(() => ([
    {
      field: 'headLastName',
      translate: 'fields.lastName',
      required: true,
      disabled: !(modalValues.accessHeadName === null) || disabledByStatus
    },
    {
      field: 'headFirstName',
      translate: 'fields.firstName',
      required: true,
      disabled: !(modalValues.accessHeadName === null) || disabledByStatus,
    },
    {
      field: 'headMiddleName',
      translate: 'fields.middleName',
      disabled: !(modalValues.accessHeadName === null) || disabledByStatus,
    }
  ]), [modalValues, disabledByStatus, constants, loading, supplierStatus])

  const setValuesFromDadata = useCallback((newValue, field) => {
    if(newValue != null) {
          modalValues[field] = newValue;
    }
  }, [modalValues]);

  const loadFounderByInn = useCallback(async (tin) => {

    if(isNumberError(errors['tin'])){
      return;
    }

    const data = await getSupplierFounderByTin(tin);

    validateTin(data);

    setLoadedFieldsFromDadata(data?.responseBody?.suggestions[0]?.data);
   }, [modalValues, errors])

  const validateTin = useCallback( (data)=> {
    const existingError = errors['tin']
    const errorsNew = { ...errors }

    if (data == null || data?.responseBody?.suggestions == null || data?.responseBody?.suggestions?.length === 0) {
      errorsNew['tin'] = translate('innNotFound')
    } else if (!isNumberError(existingError)) {
      errorsNew['tin'] = ''
    }

    setErrors(errorsNew)

    return errorsNew['tin']
  }, [errors])

  const isNumberError = useCallback( (existingError) => {
   return existingError && existingError === translate('ra.validation.number')
  })

  const setLoadedFieldsFromDadata = useCallback((data) => {
      setValuesFromDadata(data?.name?.fullWithOpf, 'fullName');
      setValuesFromDadata(data?.name?.shortWithOpf, 'name');
      setValuesFromDadata(data?.kpp, 'iec');
      setValuesFromDadata(data?.fio?.name, 'firstName');
      setValuesFromDadata(data?.fio?.surname, 'lastName');
      setValuesFromDadata(data?.fio?.patronymic, 'middleName');
   }, [modalValues])

  const registeredInRussiaOnChange = useCallback((e) => {
      modalValues.foreignCompany = !e.target.value;
  })

  const setAllBlur = useCallback((value) => {
    const blurredNew = {}
    for (const key in blurred) {
      blurredNew[key] = value
    }
    setBlurred(blurredNew)
  }, [])

  const validate = useCallback((field, value, required = false, setError = true ) => {
    const errorsNew = { ...errors }

    if (!value && required) {
      errorsNew[field] = translate('ra.validation.required')
    } else {
      errorsNew[field] = ''
    }

    if(field === 'tin' && value){
      const validTin = number()(value)
      errorsNew[field] = validTin ? translate(validTin) : ''
    }

    if (field === 'iec' && value) {
      const validIEC = number()(value)
      errorsNew[field] = validIEC ? translate(validIEC) : ''
    }

    if (setError) {
      setErrors(errorsNew)
    }
    return errorsNew[field]
  }, [errors])

  const validateAll = useCallback(() => {
    setAllBlur(true)

    const errorsKeys = Object.keys(errors)
    let errorsNew = {}

    if (modalValues.subjectType === constants?.SUBJECT_TYPE_LEGAL_PERSON) {
      let validateFields = legalFields.filter(field => (
        field.field === 'iec' || field.required
      ))
      validateFields.push({
        field: 'hasMoreThanFiftyPercent',
        required: true
      })

      if (modalValues.hasMoreThanFiftyPercent === 'YES') {
        validateFields = [ ...validateFields, ...headFields.filter(field => field.required) ]
      }

      errorsKeys.forEach(key => {
        const field = validateFields.find(field => field.field === key)
        if (field) {
          errorsNew[key] = validate(key, modalValues[key], field.required, false)
        } else {
          errorsNew[key] = ''
        }
      })
    } else if (modalValues.subjectType === constants?.SUBJECT_TYPE_INDIVIDUAL) {
      const validateFields = individualFields.filter(field => field.required)

      errorsKeys.forEach(key => {
        const field = validateFields.find(field => field.field === key)
        if (field) {
          errorsNew[key] = validate(key, modalValues[key], field.required,false)
        } else {
          errorsNew[key] = ''
        }
      })
    } else {
      errorsNew = { ...errors }
    }
    setErrors(errorsNew)

    return errorsNew
  }, [
    modalValues,
    errors,
    individualFields,
    legalFields,
    headFields,
    classes,
    isShow,
    disabledByStatus,
    constants,
    loading,
    supplierStatus
  ])

  // show errors if it was got from back
  useEffect(() => {
    if ((selected || error) && mode === 'edit') {
      const globalDataKeys = Object.keys(globalData)
      const founderIndex = selected && globalDataKeys && globalDataKeys.findIndex(dataId => (
        dataId === selected[0]
      ))
      const blurredKeys = Object.keys(blurred)
      const isNotBlured = blurredKeys.find(key => !blurred[key])
      if ((error && isNotBlured && modalValues.subjectType) || (selected && selected[0] && form.errors?.client?.supplierFounders && form.errors?.client?.supplierFounders[founderIndex] && isNotBlured)) {
        validateAll()
      }
    }
  }, [
    selected,
    form.errors?.client?.supplierFounders,
    globalData,
    modalValues,
    errors,
    blurred,
    error,
    individualFields,
    legalFields,
    headFields,
    classes,
    isShow,
    disabledByStatus,
    constants,
    loading,
    supplierStatus
  ])

  useEffect(() => {
    let values = {}

    // set values for base modal with data
    if ((mode === 'edit' || mode === 'copy') && data) {
      values = {
        subjectType: data[0].subjectType,
        lastName: data[0].lastName,
        firstName: data[0].firstName,
        middleName: data[0].middleName,
        fullName: data[0].fullName,
        name: data[0].name,
        tin: data[0].tin,
        iec: data[0].iec,
        hasMoreThanFiftyPercent: data[0].hasMoreThanFiftyPercent,
        headLastName: data[0].headLastName,
        headFirstName: data[0].headFirstName,
        headMiddleName: data[0].headMiddleName,
        parentSupplier: data[0]?.parentSupplier,
        id: data[0]?.id,
        foreignCompany: data[0].foreignCompany
      }

      // access to edit unfilled inn, kpp or headName
      if (!data[0]?.tin || (data[0]?.accessToEditTin === null)) {
        values.accessToEditTin = null
      }

      if (!data[0]?.iec || (data[0]?.accessToEditIec === null)) {
        values.accessToEditIec = null
      }

      if ((!data[0]?.headLastName || !data[0]?.headFirstName) || (data[0]?.accessHeadName === null)) {
        values.accessHeadName = null
      }

      if (!data[0]?.fullName || (data[0]?.accessFullName === null)) {
        values.accessFullName = null
      }

      if (!data[0]?.hasMoreThanFiftyPercent || (data[0]?.accessHasMoreThanFiftyPercent === null)) {
        values.accessHasMoreThanFiftyPercent = null
      }

      if ((!data[0]?.lastName || !data[0]?.firstName) || (data[0]?.accessIndividualName === null)) {
        values.accessIndividualName = null
      }

      if (!data[0]?.name || (data[0]?.accessToName === null)) {
        values.accessToName = null
      }

      if (mode === 'copy') {
        delete values.id
      }

    } else if (value && value.parentSupplier) { // set values for sub modal
      for (const key in value.parentSupplier) {
        values[key] = value.parentSupplier[key]
      }
    } else if (mode === 'create' || !value?.parentSupplier) { // set init values for sub modal and create mode
      for (const key in modalValues) {
        values[key] = (key === 'subjectType') ? constants?.SUBJECT_TYPE_LEGAL_PERSON : ''
      }
    }

    // access to edit unfilled inn, kpp or headName
    if (!values?.tin) {
      values.accessToEditTin = null
    }

    if (!values?.iec) {
      values.accessToEditIec = null
    }

    if (!values?.headLastName || !values?.headFirstName) {
      values.accessHeadName = null
    }

    if (!values?.fullName) {
      values.accessFullName = null
    }

    if (!values?.hasMoreThanFiftyPercent) {
      values.accessHasMoreThanFiftyPercent = null
    }

    if (!values?.lastName || !values?.firstName) {
      values.accessIndividualName = null
    }

    if (!values?.name ) {
      values.accessToName = null
    }

    // access to edit unfilled inn or kpp if mode isn't edit
    if (!(mode === 'edit' && values?.id)) {
      values.accessToEditIec = null
      values.accessToEditTin = null
      values.accessHasMoreThanFiftyPercent = null
      values.accessHeadName = null
      values.accessFullName = null
      values.accessIndividualName = null
      values.accessToName = null
    }

    const errorsNew = {}
    for (const key in errors) {
      errorsNew[key] = ''
    }
    setModalValues(values)
    setErrors(errorsNew)
  }, [value, mode])

  useEffect(() => {
    if (isOpen) {
      setAllBlur(false)
    }
  }, [isOpen])

  const handleSave = useCallback((e) => {
    const errorsNew = validateAll()

    for (const key in errorsNew) {
      if (errorsNew[key]) {
        notify('ra.message.invalid_form', 'warning')
        return
      }
    }

    const valuesKeys = Object.keys(modalValues)
    const valuesWithoutEmpty = {}

    valuesKeys.forEach(key => {
      if (modalValues[key] !== '') {
        valuesWithoutEmpty[key] = modalValues[key]
      }
    })

    if (value) {
      const newValue = {
        ...value,
        ['parentSupplier']: valuesWithoutEmpty,
      }
      setValue(newValue)
    } else if (mode === 'create' || mode === 'copy') {
      const supplierFounders = [ ...globalData ];

      const client = parentClient;
      (function pushNewSuppliers(suppliers, supplier) {
        if (supplier.id == null) {
          supplier.id = uuidv4();
          supplier.isNew = true;
          supplier.client = client;

          if (supplier.headFirstName && !supplier.headFullName) {
            supplier.headFullName = getFullName(supplier.headFirstName, supplier.headMiddleName, supplier.headLastName);
          }

          suppliers.push(supplier);

          if (supplier.parentSupplier != null) {
            pushNewSuppliers(suppliers, supplier.parentSupplier);
          }
        }
      })(supplierFounders, valuesWithoutEmpty);

      setSelected && setSelected([]);

      inputProps.onChange({
        target: {
          value: supplierFounders
        }
      });
    } else if (mode === 'edit') {
      const supplierFounders = [ ...globalData ]
      supplierFounders[selected[0]] = valuesWithoutEmpty

      inputProps.onChange({
        target: {
          value: supplierFounders
        }
      })
    }

    toggle(e)
  }, [
    blurred,
    errors,
    selected,
    modalValues,
    setSelected,
    individualFields,
    legalFields,
    headFields,
    classes,
    isShow,
    disabledByStatus,
    constants,
    loading,
    supplierStatus
  ])

  const onBlur = useCallback((field, required) => async () => {
    validate(field, modalValues[field], required)

    if(field === 'tin' && modalValues[field] && !modalValues.foreignCompany) {
      await loadFounderByInn(modalValues[field]);
    }

    setBlurred(prev => ({
      ...prev,
      [field]: true,
    }))
  }, [modalValues, errors])

  // open parent founder modal
  const toggleModal = useCallback(() => {
    const errorsNew = validateAll()

    for (const key in errorsNew) {
      if (errorsNew[key]) {
        notify('ra.message.invalid_form', 'warning')
        return
      }
    }
    setOpen((prev) => !prev)
  }, [
    errors,
    modalValues,
    blurred,
    individualFields,
    legalFields,
    headFields,
    classes,
    isShow,
    disabledByStatus,
    constants,
    loading,
    supplierStatus
  ])

  const renderTextFields = fields => (
    fields.map(field => (
      <FTextField
        label={translate(field.translate ?? `fields.${field.field}`)}
        field={field.field}
        values={modalValues}
        errors={errors}
        blurred={blurred}
        onBlur={onBlur}
        setValues={setModalValues}
        validate={validate}
        required={field.required}
        disabled={field.disabled}
        loading={loading}
        formClass={`${classes.input} ${field.formClass ?? ''}`}
      />
    ))
  )

  return (
    <FounderModalBase
      isOpen={isOpen}
      toggle={toggle}
      handleSave={handleSave}
      modalValues={modalValues}
      setModalValues={setModalValues}
      disabled={disabledByStatus || (mode === 'edit' ? (data ? data[0]?.id : value.parentSupplier?.id) : false)}
      setAllBlur={setAllBlur}
    >
      {modalValues.subjectType === constants?.SUBJECT_TYPE_INDIVIDUAL && (
        <>
          {renderTextFields(individualFields)}
        </>
      )}
      {modalValues.subjectType === constants?.SUBJECT_TYPE_LEGAL_PERSON && (
        <>
          <SelectInput
              className={classes.input}
              label={translate('registeredInRussia')}
              source="registeredInRussiaSelect"
              choices={yesNoOptionsBoolean}
              optionValue="value"
              helperText=""
              onChange={registeredInRussiaOnChange}
          />
          {renderTextFields(legalFields)}
          <MoreThenFiftyPercentSelect
            formClass={classes.input}
            values={modalValues}
            setValues={setModalValues}
            blurred={blurred}
            onBlur={onBlur}
            errors={errors}
            validate={validate}
            disabled={!(modalValues.accessHasMoreThanFiftyPercent === null) || disabledByStatus}
            required={!modalValues.foreignCompany}
          />
          {modalValues.hasMoreThanFiftyPercent === 'YES' && (
            <>
              <Typography className={classes.subtitle} variant="subtitle1">
                {translate('founders_of_the_vendor.modal.organization_head')}
              </Typography>
              {renderTextFields(headFields)}
              <FormControl className={classes.input}>
                <TextField
                  label={translate('fields.directorName')}
                  value={
                    `${modalValues.headLastName ? `${modalValues.headLastName} ` : ''}${
                      modalValues.headFirstName ? `${modalValues.headFirstName} ` : ''}${
                      modalValues.headMiddleName ? `${modalValues.headMiddleName}` : ''}`
                  }
                  variant="filled"
                  disabled
                />
              </FormControl>
            </>
          )}
          <FormControl
            className={`${classes.input} ${classes.organizationFounder}`}
          >
            <TextField
              variant="filled"
              label={translate('fields.organizationFounder')}
              style={{ width: '90%' }}
              disabled
              value={modalValues.parentSupplier?.fullName ?? ''}
            />
            <IconButton
              onClick={toggleModal}
              color="primary"
            >
              <EditIcon />
            </IconButton>
          </FormControl>
        </>
      )}
      {open && (
        <CreateAndEditSupplierFounder
          isOpen={open}
          toggle={toggleModal}
          value={modalValues}
          setValue={setModalValues}
          globalData={globalData}
          mode={mode}
          error={error ?? (
            form.errors?.client?.supplierFounders && form.errors?.client?.supplierFounders[
              Object.keys(globalData)?.findIndex(dataId => (dataId === selected[0]))
            ]
          )}
        />
      )}
    </FounderModalBase>
  )
}

export default CreateAndEditSupplierFounder