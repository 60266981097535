import React from 'react'
import {
  Dialog, DialogContent, DialogActions, DialogTitle,
  useMediaQuery, useTheme, Button, CircularProgress
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  dialogTitle: {
    borderBottom: '1px solid #E0E0E0',
    padding: '18px 24px'
  },
  title: {
    display: 'block',
    width: 'calc(100% - 50px)',
    fontWeight: 500,
    lineHeight: '1.4'
  },
  content: {
    position: 'relative',
    padding: '16px 24px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '68px !important'
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'rgba(0,0,0,.54)',
  },
  cardActions: {
    padding: '16px 24px 24px',
    backgroundColor: '#f5f5f5',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      boxSizing: 'border-box',
      width: '100%',
      height: 'auto',
      maxHeight: 'unset',
      '& button': {
        width: '100%'
      }
    },
  }
}))

const ModalBase = ({
                     title,
                     handleClose,
                     isOpen,
                     contentClasses,
                     paperStyle,
                     maxWidth,
                     handleSave,
                     loading = false,
                     actionName = 'buttons.ok',
                     children,
                     customActions
}) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Dialog
      className={classes.root}
      classes={{ paper: paperStyle }}
      open={isOpen}
      onClose={handleClose}
      fullScreen={fullScreen}
      maxWidth={maxWidth}
    >
      <DialogTitle className={classes.dialogTitle} id="form-dialog-title">
        <span className={classes.title}>{title}</span>
        {handleClose && (
          <IconButton
            className={classes.closeButton}
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent className={`${classes.content} ${contentClasses}`}>
        {children}
      </DialogContent>
      {(handleSave || customActions) && (
        <DialogActions className={classes.cardActions}>
          {customActions ? customActions() :
            <Button
              variant="contained"
              color="primary"
              startIcon={loading ? <CircularProgress size={14} /> : ''}
              onClick={handleSave}
              disabled={loading}
            >
              {translate(actionName)}
            </Button>
          }
        </DialogActions>
      )}
    </Dialog>
  )
}

export default ModalBase