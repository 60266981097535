const TYPES = {
  SET_ORGANIZATION_TYPE: 'SET_ORGANIZATION_TYPE',
  SET_ORGANIZATION_TYPES: 'SET_ORGANIZATION_TYPES',
  TOGGLE_MODAL_CREATOR_SUPPLIER: 'TOGGLE_MODAL_CREATOR_SUPPLIER',
  SET_CONSTITUENT_DOC_KINDS: 'SET_CONSTITUENT_DOC_KINDS',
  SET_DOC_STATUSES: 'SET_DOC_STATUSES',
  SET_TAX_PERIODS: 'SET_TAX_PERIODS',
  SET_VAT_PAYMENT_ABSENCE_BASIS: 'SET_VAT_PAYMENT_ABSENCE_BASIS',
  SET_CONSTANTS: 'SET_CONSTANTS',
  SET_CURRENT_DOCUMENT_KINDS: 'SET_CURRENT_DOCUMENT_KINDS',
  SET_PROVIDER_STATUSES: 'SET_PROVIDER_STATUSES',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_SUPPLIER_STATUS: 'SET_SUPPLIER_STATUS',
  SET_ATTORNEY_LETTER_ACTS: 'SET_ATTORNEY_LETTER_ACTS',
  SET_ATTORNEY_LETTER_STATUSES: 'SET_ATTORNEY_LETTER_STATUSES',
  SET_POINT_OF_SALE_STATUSES: 'SET_POINT_OF_SALE_STATUSES',
  SET_UPDATE_POINTS_OF_SALE_DATA: 'SET_UPDATE_POINTS_OF_SALE_DATA',
  SET_UPDATE_ONE_POINT_OF_SALE_DATA: 'SET_UPDATE_ONE_POINT_OF_SALE_DATA',
  SET_VISIBILITY_MODAL_CONFIRMATION_POINTS_OF_SALE: 'SET_VISIBILITY_MODAL_CONFIRMATION_POINTS_OF_SALE',
  SET_VISIBILITY_MODAL_CONFIRMATION_ONE_POINT_OF_SALE: 'SET_VISIBILITY_MODAL_CONFIRMATION_ONE_POINT_OF_SALE',
  SET_SAVE_WITHOUT_CONFIRM_POINTS_OF_SALE: 'SET_SAVE_WITHOUT_CONFIRM_POINTS_OF_SALE',
  SET_APP_LANGUAGE: 'SET_APP_LANGUAGE',
  SET_SEND_AFTER_SAVE_POINTS_OF_SALE: 'SET_SEND_AFTER_SAVE_POINTS_OF_SALE',
  SET_LOGIN_EMAIL: 'SET_LOGIN_EMAIL',
  SET_FORM_WAS_EDITED: 'SET_FORM_WAS_EDITED',
  SET_FEATURE_FLAGS: 'SET_FEATURE_FLAGS',
  SET_USER_INFO: 'SET_USER_INFO'
}

export default TYPES