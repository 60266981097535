import * as React from 'react'
import {
  List, Datagrid, TextField, FunctionField, useTranslate, useListContext, TopToolbar, CreateButton, ReferenceField
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import BulkActionButtons from '../components/BulkActionButtons'
import Pagination from '../../../components/Pagination'
import { stylesList } from '../../../helpers/enums'

const useStyles = makeStyles({
  ...stylesList,
  fileName: {
    color: '#0000FF',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

const tableCommentLength = 15;
const renderComment = (record) => {
  const comment = record.supplierComment;
  return comment && comment.length > tableCommentLength
    ?
    comment.slice(0, tableCommentLength) + "..."
    :
    comment;
}

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()

  return (
    <TopToolbar {...props} id="list-actions-light">
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton />
    </TopToolbar>
  )
}

const AttorneyLettersList = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <List
      classes={{
        root: classes.root,
        bulkActionsDisplayed: classes.bulkActionsDisplayed
      }}
      title={translate('menuItem.attorneyLetters')}
      {...props}
      actions={
        <ListActions />
      }
      bulkActionButtons={
        <BulkActionButtons />
      }
      pagination={<Pagination />}
      perPage={25}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField
          label={translate('fields.signerNameColumn')}
          source="signerName"
        />
        <TextField
          label={translate('fields.attorneyLetterAct')}
          source="attorneyLetterAct.name"
        />
        <ReferenceField
          source='supplier.id'
          label={translate('fields.organizationName')}
          reference='suppliers'
          link={false}
        >
          <TextField source='client.organizationName' />
        </ReferenceField>
        <ReferenceField
          source='supplier.id'
          label={translate('fields.inn')}
          reference='suppliers'
          link={false}
        >
          <TextField source='client.inn' />
        </ReferenceField>
        <TextField
          label={translate('fields.status')}
          source="status.name"
        />
        <TextField
          label={translate('fields.attorneyLetterNumber')}
          source="attorneyLetterNumber"
        />
        <FunctionField
          label={translate('fields.validUntil')}
          render={record => (
            record.validUntil ? moment(record.validUntil).format('DD.MM.YYYY') : ''
          )}
        />
        <FunctionField
          label={translate('fields.comment')}
          render={renderComment}
        />
      </Datagrid>
    </List>
  )
}

export default AttorneyLettersList