import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Datagrid,
  EditButton,
  TextField,
  TopToolbar,
  useTranslate,
  FunctionField,
  useNotify,
  useDataProvider
} from "react-admin";
import DeleteIcon from "@material-ui/icons/Delete";
import ContentAdd from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { useFormState } from "react-final-form";
import moment from "moment";
import { Button, CircularProgress } from "@material-ui/core";
import { verifyAttorneyList } from "../../../../../../api/customApi";

import { arrayToObject } from "../../../../utils";
import { downloadDocument } from "../../../../../../helpers/utils";
import CustomAction from "../../../../../../components/CustomAction";
import Modal from "./components/Modal";
import {
  attorneyLettersProcessing,
  attachFilesToDataObjects
} from "../../../../../../helpers/utils";
import { postLogs } from "../../../../../../api/customApi";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(1)
  },
  inactively: {
    display: "none"
  },
  fileName: {
    color: "#0000FF",
    textDecoration: "underline",
    cursor: "pointer"
  },
  provideButton: {
    display: "flex",
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(3)}px auto`
  },
  toolbar: {
    paddingTop: 0
  }
}));

const PowerOfAttorney = ({ active, inputProps }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const { values } = useFormState();
  const loading = useSelector(state => state.admin.loading);
  const supplierStatus = useSelector(
    state => state.customReducer.supplierStatus
  );
  const constants = useSelector(state => state.customReducer.constants);

  const currentSort = { field: "id", order: "ASC" };

  const [selected, setSelected] = useState([]);
  const [modeModal, setModeModal] = useState("");
  const [loadingAttorneyLetters, setLoadingAttorneyLetters] = useState(false);
  const [isDisabledDelete, setDisabledDelete] = useState(false);

  const data = useMemo(
    () =>
      values.attorneyLetters?.length
        ? arrayToObject(values.attorneyLetters)
        : {},
    [values.attorneyLetters]
  );

  const ids = useMemo(
    () =>
      values.attorneyLetters?.length
        ? values.attorneyLetters.map((item, index) => index.toString())
        : [],
    [values.attorneyLetters]
  );

  useEffect(() => {
    if (loading !== 0 && selected.length !== 0) {
      setSelected([]);
    }
  }, [selected, loading]);

  useEffect(() => {
    let isDisabledDelete = false;
    selected.find(id => {
      const selectedAttorneyLetter = values.attorneyLetters?.find(
        (item, index) => id === index.toString()
      );

      if (
        selectedAttorneyLetter.attorneyLetterAct?.identifier ===
        constants.CHARTER_ACT_I
      ) {
        isDisabledDelete = true;
        return true;
      }
    });
    setDisabledDelete(isDisabledDelete);
  }, [constants, values.attorneyLetterAct, selected]);

  const onDelete = useCallback(() => {
    postLogs("PowerOfAttorney",  "ALL", {
      message: "onDelete start",
      stack: "114 line"
    });
    const attorneyLetters = [];

    values.attorneyLetters.forEach((item, index) => {
      if (!selected.find(item => item === index.toString())) {
        attorneyLetters.push(item);
      }
    });

    setSelected([]);
    inputProps.onChange({
      target: {
        value: attorneyLetters
      }
    });
    postLogs("PowerOfAttorney",  "ALL", {
      message: "onDelete end",
      stack: "132 line"
    });
  }, [values.attorneyLetters, selected, constants]);

  const handleSelect = useCallback(() => {
    selected.length ? setSelected([]) : setSelected(ids);
  }, [selected]);

  const handleClick = useCallback(
    item => {
      selected.find(el => el === item)
        ? setSelected(selected.filter(it => it !== item))
        : setSelected([...selected, item]);
      postLogs("PowerOfAttorney", "ALL", {
        message: `handleClick item: ${item}`,
        stack: "151 line"
      });
    },
    [selected]
  );

  const toggleModal = useCallback(
    mode => () => {
      postLogs("PowerOfAttorney", "ALL", {
        message: `toggleModal mode: ${mode}`,
        stack: "161 line"
      });
      setModeModal(mode);
    },
    []
  );

  const onRowClick = useCallback(id => {
    postLogs("PowerOfAttorney", "ALL", {
      message: `onRowClick id: ${id}`,
      stack: "171 line"
    });
    if (id) {
      setSelected([id]);
    }
    toggleModal("edit")();
  }, []);

  const onProvideAttorneyLetters = useCallback(async () => {
    postLogs("PowerOfAttorney", "ALL", {
      message: `onProvideAttorneyLetters start`,
      stack: "182 line"
    });
    setLoadingAttorneyLetters(true);
    try {
      const dataWithoutFile = attorneyLettersProcessing(
        values.attorneyLetters,
        constants
      );
      postLogs("PowerOfAttorney", "ALL", {
        message: `dataWithoutFile: ${JSON.stringify(dataWithoutFile)}`,
        stack: "192 line"
      });
      const [data] = await attachFilesToDataObjects([dataWithoutFile]);
      const response = await verifyAttorneyList({ id: values.id, data });
      if (response.errorMessage) {
        postLogs("PowerOfAttorney", "ERROR", {
          message: `response: ${JSON.stringify(response)}`,
          stack: "199 line"
        });
        notify(response.errorMessage, "warning");
      }
      const supplier = await dataProvider.getOne("suppliers", {
        id: values.id
      });
      postLogs("PowerOfAttorney", "ALL", {
        message: `supplier: ${JSON.stringify(supplier)}`,
        stack: "208 line"
      });
      setSelected([]);
      inputProps.onChange({
        target: {
          value: supplier.data?.attorneyLetters ?? []
        }
      });
    } catch (err) {
      postLogs("PowerOfAttorney", "ERROR", {
        message: `${err}`,
        stack: "219 line"
      });
      console.log(err);
      notify(err.message, "warning");
    }
    setLoadingAttorneyLetters(false);
    postLogs("PowerOfAttorney", "ALL", {
      message: "onProvideAttorneyLetters end",
      stack: "227 line"
    });
  }, [values]);

  const renderFile = record =>
    record.file ? (
      <a className={classes.fileName} onClick={downloadDocument(record.file)}>
        {record.file.name}
      </a>
    ) : (
      ""
    );

  return (
    <div className={active ? classes.wrapper : classes.inactively}>
      <Modal
        mode={modeModal}
        toggle={toggleModal("")}
        data={data}
        inputProps={inputProps}
        selected={selected}
      />
      <TopToolbar className={classes.toolbar}>
        <CustomAction
          label={translate("buttons.create")}
          icon={<ContentAdd />}
          onAction={toggleModal("create")}
          disabled={loading !== 0}
        />
        <EditButton
          basePath=""
          disabled={loading !== 0 || selected.length !== 1}
          onClick={toggleModal("edit")}
        />
        <CustomAction
          label={translate("ra.action.delete")}
          color="red"
          icon={<DeleteIcon />}
          onAction={onDelete}
          disabled={loading !== 0 || selected.length === 0 || isDisabledDelete}
        />
      </TopToolbar>
      <Datagrid
        basePath=""
        currentSort={currentSort}
        data={data}
        ids={ids}
        hasBulkActions={true}
        selectedIds={selected}
        size="medium"
        onToggleItem={handleClick}
        onSelect={handleSelect}
        rowClick={onRowClick}
      >
        <TextField
          label={translate("fields.signerNameColumn")}
          source="signerName"
        />
        <TextField
          label={translate("fields.attorneyLetterAct")}
          source="attorneyLetterAct.name"
        />
        <TextField label={translate("fields.status")} source="status.name" />
        <TextField
          label={translate("fields.attorneyLetterNumber")}
          source="attorneyLetterNumber"
        />
        <FunctionField
          label={translate("fields.validUntil")}
          render={record =>
            record.validUntil
              ? moment(record.validUntil).format("DD.MM.YYYY")
              : ""
          }
        />
        <FunctionField label={translate("fields.file")} render={renderFile} />
      </Datagrid>
      {supplierStatus !== constants?.DRAFT_PROVIDER_STATUS_I &&
        supplierStatus !== constants?.INACTIVE_PROVIDER_STATUS_I &&
        supplierStatus !== "" && (
          <Button
            className={classes.provideButton}
            variant="contained"
            color="primary"
            startIcon={
              loadingAttorneyLetters ? <CircularProgress size={14} /> : ""
            }
            disabled={
              loading !== 0 || supplierStatus === "" || loadingAttorneyLetters
            }
            onClick={onProvideAttorneyLetters}
          >
            {translate("buttons.provideAttorneyLetters")}
          </Button>
        )}
    </div>
  );
};

export default PowerOfAttorney;
