import * as React from 'react'
import {
  TextInput, useTranslate
} from 'react-admin'
import MuiPhoneNumber from 'material-ui-phone-number'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'
import { validPhone } from '../../../../../../helpers/utils'
import {useCallback} from "react";


const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 8,
    marginBottom: 4,
  },
  phone: {
    marginBottom: props => props.marginBottom,
  },
  hide: {
    display: 'none'
  }
}))

const PhoneField = ({
                      meta: { touched, error } = { touched: false, error: undefined },
                      input: { ...inputProps },
                      ...props
                    }) => {
  const translate = useTranslate()
  const validate = useCallback((value) => {
    return (
      !value?.replace('+', '') ? 'ra.validation.required' : ''
    )
  }, [])
  const {values, errors, submitFailed} = useFormState()
  const classes = useStyles({
    marginBottom: (errors?.phone && (submitFailed || touched)) ? 24 : 8
  })

  const onChangePhone = (value) => {
    inputProps.onChange({
      target: {
        value
      }
    })
  }

  const onFocus = () => {
    if (values.phone === undefined) {
      values.phone = ''
    }
  }

  return (
    <>
      <MuiPhoneNumber
        className={`${classes.input} ${classes.phone}`}
        label={translate('fields.phone')}
        onFocus={onFocus}
        variant="filled"
        value={`+${values.phone ?? ''}`}
        onChange={onChangePhone}
        onBlur={inputProps.onBlur}
        placeholder="+7 (999) 999-99-99"
        error={(touched || submitFailed) && errors.phone}
        helperText={(touched || submitFailed) && translate(errors.phone)}
        required
      />
      <TextInput
        className={classes.hide}
        source='phone'
        validate={[validate, validPhone]}
        disabled
      />
    </>
  )
}

export default PhoneField