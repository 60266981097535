import { stringify } from 'query-string'
import { resources } from '../helpers/enums'
import { fetchJson } from './utils'
import {getData} from "./basicAuthProvider";

export const baseApiUrl = process.env.REACT_APP_CUBA_URL
export const standardApiUrl = baseApiUrl + 'v2'
export const customApiUrl = baseApiUrl + 'api'

export const getFile = (id) => {
  const headers = new Headers()
  headers.set('Response-Type', 'blob')
  headers.set('Content-Type', 'application/octet-stream')
  return getData(`${standardApiUrl}/files/${id}`, { headers }).then(res => res.blob())
}

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }

  return fetchJson(url, options)
}

const getOperator = (field) => (
  (field === 'id' || field === 'vatPayer' || field.toLowerCase().includes('date')) ? '=' : 'contains'
)

const filterToConditions = (filter, accum = [], res = []) => {
  if (typeof filter === "object" && filter !== null && !(filter instanceof Array)) {
    for (let key in filter) {
      filterToConditions(filter[key], [...accum, key], res)
    }
  } else {
    res.push({
      property: accum.join('.') === 'q' ? 'name' : accum.join('.'),
      operator: typeof filter === 'boolean' ? '=' : getOperator(accum[accum.length - 1]),
      value: filter
    })
  }
  return res
}

const createQueryWithFilter = (resource, params) => {
  let conditions
  if (params.filter.notConvert) {
    conditions = { conditions: params.filter.filters }
  } else {
    conditions = { conditions: filterToConditions(params.filter) }
  }

  const query = {
    view: resources[resource].view,
    returnCount: true,
    filter: conditions.conditions.length ? JSON.stringify(conditions) : undefined
  }
  
  if (params.sort) {
    const { field, order } = params.sort
    query.sort = `${order === 'ASC' ? '+' : '-'}${field}`
  }

  if (params.pagination && typeof params.pagination.perPage === 'number') {
    query.offset = (params.pagination.page - 1) * params.pagination.perPage
    query.limit = params.pagination.perPage
  }

  return `${standardApiUrl}/entities/${resources[resource].source}${conditions.conditions.length ? '/search' : ''}?${stringify(query)}`
}

const httpDataProvider = {
  getList: (resource, params) => {
    const url = createQueryWithFilter(resource, params)

    return httpClient(url).then(({ headers, json }) => {
      return ({
        data: json,
        total: parseInt(headers.get('X-Total-Count')),
      })
    })
  },

  getOne: (resource, params) => {
    const url = `${standardApiUrl}/entities/${resources[resource].source}/${params.id}?view=${resources[resource].view}`
    return httpClient(url).then(({ json }) => ({
      data: json
    }))
  },

  getMany: async (resource, params) => {
    const query = {
      filter: JSON.stringify({
        conditions: [{
          property: 'id',
          operator: 'in',
          value: params.ids
        }]
      })
    }
    const url = `${standardApiUrl}/entities/${resources[resource].source}/search?${stringify(query)}&view=${resources[resource].view}`
    return httpClient(url).then(({ json }) => ({
      data: json
    }))
  },

  getManyReference: (resource, params) => {
    const url = createQueryWithFilter(resource, params)

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('X-Total-Count'))
    }))
  },

  update: (resource, params) => {
    let url = `${standardApiUrl}/entities/${resources[resource].source}/${params.id}?view=${resources[resource].view}`
    let data = params.data

    if (resource === 'suppliers') {
      url = `${customApiUrl}/supplier/update`
      if (params.verify) {
        url = `${customApiUrl}/supplier/verify`
      }
    }

    if (resource === 'users') {
      url = `${customApiUrl}/user`
    }

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }))
  },

  updateMany: (resource, params) => {
    let url = `${standardApiUrl}/entities/${resources[resource].source}`

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }))
  },

  create: (resource, params) => {
    let url = `${standardApiUrl}/entities/${resources[resource].source}?view=${resources[resource].view}`
    let data = params.data
    let method = 'POST'

    if (resource === 'users') {
      url = `${customApiUrl}/user`
    }

    return httpClient(url, {
      method,
      body: JSON.stringify(data),
    }).then(({ json }) => ({ data: json }))
  },

  delete: (resource, params) => {
    let url = `${standardApiUrl}/entities/${resources[resource].source}/${params.id}`
    let id
    if (resource === 'users') {
      url = `${customApiUrl}/user/${params.id}`
    }

    if (resource === 'pointsOfSale') {
      url = `${customApiUrl}/pointOfSale/softDelete`
      id = [{
        id: params.id
      }]
    }

    return httpClient(url, {
      method: 'DELETE',
      body: JSON.stringify(id),
    }).then(({ json }) => ({ data: json }))
  },

  deleteMany: (resource, params) => {
    let url = `${standardApiUrl}/entities/${resources[resource].source}`
    let ids = params.ids
    if (resource === 'users') {
      url = `${customApiUrl}/user`
    }

    if (resource === 'pointsOfSale') {
      url = `${customApiUrl}/pointOfSale/softDelete`
      ids = ids.map(id => ({
        id: id
      }))
    }

    return httpClient(url, {
      method: 'DELETE',
      body: JSON.stringify(ids),
    }).then(({ json }) => ({ data: json }))
  }
}

export default httpDataProvider