import {
  isNotNullAndNotCreateDate,
  clearFounderFields,
  isNotNull,
  deleteFields,
  attorneyLettersProcessing,
  attachFilesToDataObjects
} from '../../../helpers/utils'

export const transform = (constants) => async (data) => {
  const constituentDocsWithoutFiles = data.constituentDocs.map(item => {
    const doc = { ...item }
    if (!item.type) {
      doc.type = item.docKind.objectType === 'VAT_DECLARATION' ? 'VATDeclaration' : 'ConstituentDoc'
    }
    const result = deleteFields(isNotNullAndNotCreateDate)(doc)

    if (result.type === 'VATDeclaration' && !result.file) {
      result.taxPeriod = null
      result.vatDeclarationYear = null
    }

    if (result.type === 'VATDeclaration' && result.taxPeriod) {
      result.comment = null
    }

    return result
  })

  const attorneyLettersWithoutFiles = attorneyLettersProcessing(data.attorneyLetters, constants)
  const [ constituentDocs, attorneyLetters ] = await attachFilesToDataObjects([constituentDocsWithoutFiles, attorneyLettersWithoutFiles])

  const supplierFounders = data.client.supplierFounders?.map(item => ({
    ...deleteFields(clearFounderFields, constants)(item),
    client: {
      id: data.client.id
    }
  }))

  const client = deleteFields(isNotNull)(data.client)
  client.phone && (client.phone = client.phone.replace(/[\+\(\)\s\-]/g, ''))
  if (client.contactPerson?.id === '') {
    client.contactPerson = null
  }

  if (client.vatPayer === 'NO') {
    client.biggestTaxpayer = null
    client.biggestTaxpayerKpp = null
  }

  if (client.vatPayer === 'YES') {
    client.vatPaymentAbsenceBasis = null
    if (client.biggestTaxpayer === 'NO') {
      client.biggestTaxpayerKpp = null
    }
  }

  const informationLetters = data.informationLetters.map(letter => ({
    ...letter,
    file: {
      id: letter.id
    }
  }))

  return {
    ...data,
    client: {
      ...client,
      supplierFounders
    },
    constituentDocs,
    attorneyLetters,
    informationLetters
  }
}

export const arrayToObject = (array) => {
  const object = {}
  array.forEach((item, index) => {
    object[index] = item
  })
  return object
}