import React from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TextInput, useTranslate, required, number, } from 'react-admin'
import { Field, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, TextField } from '@material-ui/core'
import AccountHolderName from './components/AccountHolderName'
import FieldBIC from './components/FieldBIC'
import { lineLength } from '../../../../../../helpers/utils'

const identity = value => (value)

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    maxWidth: 1000,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      maxWidth: 'calc(100vw - 48px)'
    }
  },
  input: {
    width: '100%',
  },
  currentAccount: {
    marginTop: 8,
    marginBottom: 4,
  }
}))

const validateRequired = [required()]
const validateRequiredNumber = [required(), number()]

const BankDetails = () => {
  const translate = useTranslate()
  const classes = useStyles()

  const { values, errors } = useFormState()

  const constants = useSelector(state => state.customReducer.constants)
  const organizationType = useSelector(state => state.customReducer.organizationType)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const startsCurrentAccountNumber = value => {
    let error = ''
    switch (organizationType) {
      case 'LEGAL': {
        error = /^(40702|40703).*/g.test(value) ? '' : 'errors.currentAccountNumberLegal'
        break
      }
      case 'INDIVIDUAL': {
        error = /^40802.*/g.test(value) ? '' : 'errors.currentAccountNumberIndividual'
        break
      }
    }
    return (
      error
    )
  }
  const validateCurrentAccount = [required(), number(), startsCurrentAccountNumber, lineLength(20, translate)]

  const isDisabledFields = useMemo(() => (
    loading !== 0 || !(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === ''
    )
  ), [supplierStatus, constants, loading])

  const renderAccountHolderName = ({
                                     meta: { touched, error } = { touched: false, error: undefined },
                                     input: { ...inputProps },
                                     ...props
                                   }) => {
    return (
      <AccountHolderName inputProps={inputProps} />
    )
  }

  const renderBIC = ({
                       meta: { touched, error } = { touched: false, error: undefined },
                       input: { ...inputProps },
                       ...props
                     }) => {
    return (
      <FieldBIC
        inputProps={inputProps}
        touched={touched}
        disabled={isDisabledFields}
      />
    )
  }

  const renderCurrentAccountNumber = ({
                       meta: { touched, error } = { touched: false, error: undefined },
                       input: { ...inputProps },
                       ...props
                     }) => {
    return (
      <>
        <TextField
          className={`${classes.input} ${classes.currentAccount}`}
          variant="filled"
          label={translate('fields.currentAccount')}
          value={values.client?.currentAccountNumber ?? ''}
          onChange={inputProps.onChange}
          onBlur={inputProps.onBlur}
          inputProps={{ maxLength: 20 }}
          parse={identity}
          required
          disabled={isDisabledFields}
          error={errors.client?.currentAccountNumber}
          helperText={touched ? translate(errors.client?.currentAccountNumber) : ''}
        />
        <TextInput
          source="client.currentAccountNumber"
          validate={validateCurrentAccount}
          disabled
          style={{
            display: 'none'
          }}
        />
      </>
    )
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle} variant="subtitle1">
        {translate('formSuppliers.titles.bankDetails')}
      </Typography>
      <div className={classes.inputContainer}>
        <Field
          name="client"
          render={renderBIC}
        />
        <Field
          name="client.currentAccountHolderName"
          render={renderAccountHolderName}
        />
        <Field
          name="client.currentAccountNumber"
          render={renderCurrentAccountNumber}
        />
        <TextInput
          className={classes.input}
          label={translate('fields.correspondentAccount')}
          source="client.correspondentAccountNumber"
          validate={validateRequiredNumber}
          helperText=""
          disabled
        />
        <TextInput
          label={translate('fields.bankFullName')}
          source="client.bankFullName"
          variant="filled"
          validate={validateRequired}
          multiline
          rows={5}
          helperText=""
          disabled
        />
        <TextInput
          label={translate('fields.bankLocation')}
          source="client.bankLocation"
          variant="filled"
          validate={validateRequired}
          multiline
          rows={5}
          helperText=""
          disabled
        />
      </div>
    </div>
  )
}

export default BankDetails