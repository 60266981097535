import React, {useCallback, useState, useEffect, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {TextField, FunctionField, EditButton, TopToolbar, useTranslate} from 'react-admin'
import DeleteIcon from '@material-ui/icons/Delete'
import {makeStyles} from '@material-ui/core/styles'
import {Button} from '@material-ui/core'
import {Field, useFormState} from 'react-final-form'

import {standardApiUrl, httpClient} from '../../../../../../api/httpDataProvider'
import {setCurrentDocumentKinds} from '../../../../../../reducer/actions'
import EditDocumentModal from './EditDocumentModal'
import EditDeclarationModal from './EditDeclarationModal'
import Datagrid from './components/Datagrid'
import CustomAction from '../../../../../../components/CustomAction'
import {downloadDocument} from '../../../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(1)
  },
  inactively: {
    display: 'none',
  },
  textField: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  fileName: {
    color: '#0000FF',
    textDecoration: 'underline'
  },
  toolbar: {
    paddingTop: 0
  }
}))

const ConstituentDocs = ({active, inputProps}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const currentSort = {field: 'id', order: 'ASC'}

  const {values} = useFormState()
  const dispatch = useDispatch()

  const [isOpenModal, setOpenModal] = useState(false)
  const [kindDocuments, setKindDocuments] = useState({})
  const [noDeclaration, setNoDeclaration] = useState(false)
  const [selectedKind, setSelectedKind] = useState({})
  const [selected, setSelected] = useState([])
  const [isDeclarationReceived, setDeclarationRecieved] = useState(false)

  const ids = Object.keys(kindDocuments)

  const constituentDocsKinds = useSelector(state => state.customReducer.constituentDocKinds)
  const organizationType = useSelector(state => state.customReducer.organizationType)
  const docStatuses = useSelector(state => state.customReducer.docStatuses)
  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  useEffect(() => {
    if (loading !== 0 && isDeclarationReceived) {
      setDeclarationRecieved(false)
    }
  }, [loading, isDeclarationReceived])

  useEffect(() => {
      if (loading === 0 && !isDeclarationReceived) {
        const constDocs = values.constituentDocs;
        if(constDocs != null) {
          const vatDeclarations = constDocs.filter((doc) => doc.docKind.objectType === 'VAT_DECLARATION');
          if(vatDeclarations !=null) {
             vatDeclarations.forEach((doc) => getDeclaration(values.constituentDocs.indexOf(doc)));
          }
          setDeclarationRecieved(true);
        }
      }
  }, [values.constituentDocs, loading, isDeclarationReceived])

  const getDeclaration = useCallback(async (declarationIndex) => {
    const declarationRes = await httpClient(`${standardApiUrl}/entities/vp$VATDeclaration/${values.constituentDocs[declarationIndex].id}?view=vatDeclaration-edit`)
    const newDocuments = values.constituentDocs.slice()
    newDocuments[declarationIndex].taxPeriod = declarationRes.json?.taxPeriod
    newDocuments[declarationIndex].vatDeclarationYear = declarationRes.json?.vatDeclarationYear
    if (declarationRes.json?.noDeclaration !== undefined) {
      newDocuments[declarationIndex].noDeclaration = declarationRes.json?.noDeclaration
      newDocuments[declarationIndex].comment = declarationRes.json?.comment
      setNoDeclaration(declarationRes.json?.noDeclaration)
    }

    inputProps.onChange({
      target: {
        value: newDocuments
      }
    })
  }, [values.constituentDocs])

  useEffect(() => {
    const ids = Object.keys(kindDocuments)
    const docs = ids.map(id => kindDocuments[id])
    // for validation before Provide
    dispatch(setCurrentDocumentKinds(docs))
  }, [kindDocuments])

  const filterKindDocs = useCallback((constituentDocsKinds, organizationTypeName, vatPayer = 'NO', biggestTaxpayer = 'NO') => {
    let docs = constituentDocsKinds.filter(item => item.organizationType && item.organizationType.name === organizationTypeName)
    docs = docs.filter(item => (
      item.requestAvailability === 'DEFAULT'
      || (item.requestAvailability === 'VAT_PAYER' && vatPayer === 'YES')
      || (item.requestAvailability === 'BY_SCRIPT_CONDITION' && noDeclaration)
      || (item.requestAvailability === 'LARGEST_VAT_PAYER' && biggestTaxpayer === 'YES')
    ))
    const docKindObject = docs.reduce((accum, currentValue) => ({
      ...accum,
      [currentValue.id]: currentValue
    }), {})
    return docKindObject
  }, [noDeclaration])

  useEffect(() => {
    if (constituentDocsKinds.length) {
      let organizationTypeName = ''
      switch (organizationType) {
        case 'LEGAL': {
          organizationTypeName = constants?.JURIDICAL_ORGANIZATION_TYPE_NAME
          break
        }
        case 'INDIVIDUAL': {
          organizationTypeName = constants?.INDIVIDUAL_ORGANIZATION_TYPE_NAME
          break
        }
      }
      const filtredKindDocs = filterKindDocs(constituentDocsKinds, organizationTypeName, values.client?.vatPayer, values.client?.biggestTaxpayer)
      setKindDocuments(filtredKindDocs)
    }
  }, [values, constituentDocsKinds, organizationType, noDeclaration])

  useEffect(() => {
    if (values.client?.vatPayer === 'NO') {
      let newDocuments = values.constituentDocs.slice()
      const declarationKindIdentifier = constituentDocsKinds.find(doc => doc.requestAvailability == 'VAT_PAYER').identifier
      const documentIndex = newDocuments.findIndex(doc => (doc.docKind.identifier === declarationKindIdentifier))
      if (documentIndex !== -1) {
        newDocuments.splice(documentIndex, 1)
      }
      // delete Гарантийное письмо
      const letterKindIdentifier = constituentDocsKinds.find(doc => doc.requestAvailability == 'BY_SCRIPT_CONDITION').identifier
      const letterIndex = newDocuments.findIndex(doc => (doc.docKind.identifier === letterKindIdentifier))
      if (letterIndex !== -1) {
        newDocuments.splice(letterIndex, 1)
      }

      if (values.constituentDocs.length !== newDocuments.length) {
        inputProps.onChange({
          target: {
            value: newDocuments
          }
        })
      }
    }

    if (values.client?.biggestTaxpayer === 'NO' || !values.client?.biggestTaxpayer) {
      let newDocuments = values.constituentDocs.slice()
      const biggestTaxpayerKindIdentifier = constituentDocsKinds.find(doc => doc.requestAvailability == 'LARGEST_VAT_PAYER')?.identifier
      const biggestTaxpayerIndex = newDocuments.findIndex(doc => (doc.docKind.identifier === biggestTaxpayerKindIdentifier))
      if (biggestTaxpayerIndex !== -1) {
        newDocuments.splice(biggestTaxpayerIndex, 1)
      }

      if (values.constituentDocs.length !== newDocuments.length) {
        inputProps.onChange({
          target: {
            value: newDocuments
          }
        })
      }
    }
  }, [values])

  const toggleModal = useCallback(() => {
    setOpenModal((prev) => !prev)
  }, [isOpenModal])

  const onAddFile = useCallback((record) => (e) => {
    e?.stopPropagation()
    if (record.id) {
      setSelected([record.id])
    }
    setSelectedKind(record)
    toggleModal()
  }, [])

  const handleSelect = useCallback(() => {
    selected.length ? setSelected([]) : setSelected(ids)
  }, [selected])

  const handleClick = useCallback((item) => {
    selected.find((el) => el === item)
      ? setSelected(selected.filter((it) => it !== item))
      : setSelected([...selected, item])
  }, [selected])

  const onNoDeclaration = useCallback((comment) => {
    setNoDeclaration(true)
    const docIndex = values.constituentDocs.findIndex(doc => doc.docKind.identifier === selectedKind.identifier)
    const newDocuments = values.constituentDocs.slice()
    const docStatus = docStatuses.find(status => status.identifier === constants.FOUNDING_DOCUMENT_STATUS_ADDED_I)

    const docObj = {
      availability: true,
      docKind: selectedKind,
      foundingDocumentStatus: {
        id: docStatus.id,
        name: docStatus.name,
        identifier: docStatus.identifier
      },
      comment,
      file: null,
      noDeclaration: true,
      supplier: {
        id: values.id
      },
      type: selectedKind.objectType === 'VAT_DECLARATION' ? 'VATDeclaration' : 'ConstituentDoc',
    }

    if (docIndex !== -1) {
      newDocuments[docIndex].id && (docObj.id = newDocuments[docIndex].id)
      newDocuments[docIndex] = docObj
    } else {
      newDocuments.push(docObj)
    }

    inputProps.onChange({
      target: {
        value: newDocuments
      }
    })
  }, [values.constituentDocs, selectedKind, constants])

  const onAddDocument = useCallback(async ({
                                             file,
                                             name,
                                             noDeclaration,
                                             comment,
                                             tax,
                                             year,
                                             fileFromDocument
                                           }) => {
    if (noDeclaration) {
      onNoDeclaration(comment)
      return
    } else if (selectedKind.objectType === 'VAT_DECLARATION') {
      setNoDeclaration(false)
    }

    const docStatus = docStatuses.find(status => status.identifier === constants.FOUNDING_DOCUMENT_STATUS_ADDED_I)

    const docObj = {
      availability: true,
      docKind: selectedKind,
      foundingDocumentStatus: {
        id: docStatus.id,
        name: docStatus.name,
        identifier: docStatus.identifier
      },
      supplier: {
        id: values.id
      },
      type: selectedKind.objectType === 'VAT_DECLARATION' ? 'VATDeclaration' : 'ConstituentDoc',
    }

    if (file) {
      const arrayBuffer = await file.arrayBuffer()
      const buffer = new Uint8Array(arrayBuffer)
      docObj.file = {
        file: buffer,
        name,
        type: file.type
      }
    } else {
      docObj.file = fileFromDocument
    }

    const docIndex = values.constituentDocs.findIndex(doc => doc.docKind.identifier === selectedKind.identifier)
    const newDocuments = values.constituentDocs.slice()

    if (selectedKind.objectType === 'VAT_DECLARATION') {
      docObj.taxPeriod = tax
      docObj.vatDeclarationYear = year
      docObj.noDeclaration = false

      const letterKindIdentifier = constituentDocsKinds.find(kind => kind.requestAvailability === 'BY_SCRIPT_CONDITION').identifier
      const letterIndex = newDocuments.findIndex(doc => (doc.docKind.identifier === letterKindIdentifier))

      if (letterIndex !== -1) {
        newDocuments.splice(letterIndex, 1)
      }
    }

    if (docIndex !== -1) {
      newDocuments[docIndex]?.id && (docObj.id = newDocuments[docIndex].id)
      newDocuments[docIndex] = docObj
    } else {
      newDocuments.push(docObj)
    }

    inputProps.onChange({
      target: {
        value: newDocuments
      }
    })
  }, [selectedKind, values.constituentDocs, constituentDocsKinds])

  const onGetDocument = useCallback(doc => e => {
    downloadDocument(doc.file)(e)
  }, [])

  const onDelete = useCallback(() => {
    const newDocuments = []
    const selectedDocuments = []
    values.constituentDocs.forEach(doc => {
      if (!selected.includes(doc.docKind.id)) {
        newDocuments.push(doc)
      } else {
        selectedDocuments.push(doc)
      }
    })

    inputProps.onChange({
      target: {
        value: newDocuments
      }
    })

    const declarationKind = constituentDocsKinds.find(doc => doc.objectType === 'VAT_DECLARATION')
    if (selectedDocuments.find(doc => doc.docKind.identifier === declarationKind.identifier)) {
      setNoDeclaration(false)
    }
  }, [selected, values.constituentDocs])

  const renderFile = ({record}) => {
    const doc = values.constituentDocs.find(item => item.docKind.id === record.id)
    return doc?.file ? (
      <a
        className={classes.fileName}
        onClick={onGetDocument(doc)}
      >
        {doc.file.name}
      </a>
    ) : (
      <Button
        source="file"
        label={translate('fields.file')}
        onClick={onAddFile(record)}
        color="primary"
      >
        {translate('buttons.add')}
      </Button>
    )
  }

  const selectedDocs = useMemo(() => (
    (selected.length > 0 && values.constituentDocs?.length) ?
      selected.map(id => (
        values.constituentDocs.find(doc => doc.docKind.id === id)
      )) : null
  ), [selected, values])

  const docStatusesForSelected = useMemo(() => (
    selectedDocs?.map(doc => {
      if (doc !== undefined) {
        return docStatuses.find(status => status.identifier === doc?.foundingDocumentStatus.identifier)
      } else {
        return docStatuses.find(status => status.identifier === constants?.FOUNDING_DOCUMENT_STATUS_ADDED_I)
      }
    })
  ), [selectedDocs, values, docStatuses, selected])

  const disabledActions = useMemo(() => (
    loading !== 0 || !selectedDocs || !(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === '' || ((supplierStatus === constants?.PART_CONFIRMED_DATA_I || supplierStatus === constants?.DECLINED_PROVIDER_STATUS_I) && docStatusesForSelected && (
        docStatusesForSelected.find(status => (
          status.identifier === constants?.FOUNDING_DOCUMENT_STATUS_REJECTED_I ||
          status.identifier === constants?.FOUNDING_DOCUMENT_STATUS_ADDED_I
        ))
      ))
    )
  ), [supplierStatus, values, docStatusesForSelected, constants, loading, selected, selectedDocs, docStatuses])

  const disabledModalSave = useMemo(() => (
    loading !== 0 || selectedDocs && !(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === '' || ((supplierStatus === constants?.PART_CONFIRMED_DATA_I || supplierStatus === constants?.DECLINED_PROVIDER_STATUS_I) && docStatusesForSelected && (
        docStatusesForSelected.find(status => (
          status.identifier === constants?.FOUNDING_DOCUMENT_STATUS_REJECTED_I ||
          status.identifier === constants?.FOUNDING_DOCUMENT_STATUS_ADDED_I
        ))
      ))
    )
  ), [supplierStatus, values, docStatusesForSelected, constants, loading, selected, selectedDocs, docStatuses])
  return (
    <div
      className={active ? classes.wrapper : classes.inactively}
    >
      <TopToolbar className={classes.toolbar}>
        <EditButton
          basePath="/suppliers"
          disabled={selected.length !== 1 || disabledActions}
          onClick={onAddFile(kindDocuments[selected[0]])}
        />
        <CustomAction
          label={translate('ra.action.delete')}
          color="red"
          icon={<DeleteIcon/>}
          onAction={onDelete}
          disabled={disabledActions}
          role="delete"
        />
      </TopToolbar>
      {selectedKind.objectType === 'VAT_DECLARATION' && (
        <EditDeclarationModal
          isOpen={isOpenModal}
          toggle={onAddFile({})}
          selectedKind={selectedKind}
          documents={values.constituentDocs}
          onGetDocument={onGetDocument}
          onAddDocument={onAddDocument}
          disabledFields={disabledModalSave}
        />
      )}
      {selectedKind.objectType === 'CONSTITUENT_DOCS' && (
        <EditDocumentModal
          isOpen={isOpenModal}
          toggle={onAddFile({})}
          onAddDocument={onAddDocument}
          selectedKind={selectedKind}
          documents={values.constituentDocs}
          onGetDocument={onGetDocument}
          disabledFields={disabledModalSave}
        />
      )}
      <Datagrid
        basePath=""
        currentSort={currentSort}
        data={kindDocuments}
        ids={ids}
        hasBulkActions={true}
        selectedIds={selected}
        size="medium"
        onToggleItem={handleClick}
        onSelect={handleSelect}
        rowClick={onAddFile}
      >
        <TextField
          className={classes.textField}
          label={translate('columns.document')}
          source="document"
        />
        <FunctionField
          label={translate('columns.is_required')}
          render={(record) => (
            record.required ? translate('fields.yes') : translate('fields.no')
          )}
        />
        <Field
          label={translate('fields.file')}
          name="file"
          render={renderFile}
        />
        <FunctionField
          label={translate('fields.grantDate')}
          render={(record) => (
            values.constituentDocs.find(item => item.docKind.id === record.id)?.grantDate ?? ''
          )}
        />
        <FunctionField
          label={translate('fields.status')}
          render={(record) => {
            const foundingDocumentStatus = values.constituentDocs.find(item => item.docKind.id === record.id)?.foundingDocumentStatus
            let res = ''

            if (foundingDocumentStatus) {
              res = docStatuses.find(status => status.identifier === foundingDocumentStatus.identifier).name ?? ''
            }

            return res
          }}
        />
        <FunctionField
          label={translate('columns.reason')}
          render={(record) => (values.constituentDocs.find(doc => doc.docKind.id === record.id)?.reasonForRejection ?? '')}
        />
      </Datagrid>
    </div>
  )
}

export default ConstituentDocs