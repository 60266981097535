import * as React from 'react'
import { Datagrid, DatagridRow, DatagridBody } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  errorRow: {
    background: '#FEEBEE',
    '&:hover': {
      background: '#F4E1E4 !important',
    }
  }
}))

const DatagridRowFounders = props => {
  const { record, resource, id, children, basePath } = props
  const classes = useStyles()

  const { errors } = useFormState()

  const dataKeys = Object.keys(props.data)
  const index = dataKeys.findIndex(dataId => (dataId === id))

  return (
    <DatagridRow
      key={id}
      {...props}
      className={`${props.className} ${
        (errors?.client?.supplierFounders && errors?.client?.supplierFounders[index]) ? classes.errorRow : ''}
      `}
      onClick={props.rowClick('edit', id)}
    >
      {React.Children.map(children, field => (
        React.cloneElement(field, {
          record,
          basePath,
          resource,
        })
      ))}
    </DatagridRow>
  )
}

const DatagridBodyFounders = props => <DatagridBody {...props} row={<DatagridRowFounders data={props.data} />} />
const DatagridFounders = props => <Datagrid {...props} body={<DatagridBodyFounders data={props.data} />} />

export default DatagridFounders