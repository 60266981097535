import * as React from 'react'
import { useCallback, useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate, useNotify } from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ModalBase from '../../../../../../../../components/ModalBase'

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%'
  },
  content: {
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,
    marginBottom: theme.spacing(4),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('xs')]: {
      flex: 'unset',
      gridTemplateColumns: '1fr',
    },
  },
  input: {
    width: '100%',
    marginTop: 8,
    marginBottom: 4,
  }
}))

const Modal = ({ isOpen, toggle, inputProps, fieldsArray }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()

  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const [isNameDisabled, setNameDisabled] = useState(true)
  const [isPositionDisabled, setPositionDisabled] = useState(true)
  const [isValuesChecked, setValuesChecked] = useState(false)

  const requiredFields = [useRef(), useRef(), '', '', useRef()]

  const { values, errors } = useFormState()

  useEffect(() => {
    if (!isValuesChecked) {
      let disabled = {}
      fieldsArray.forEach(item => {
        disabled[item.name] = true
        if (!(values.client?.person && values.client?.person[item.name]) && item.required) {
          disabled[item.name] = false
        }
      })

      let nameDisabled = true

      if (!disabled.firstName || !disabled.lastName) {
        nameDisabled = false
      }

      setNameDisabled(nameDisabled)
      setPositionDisabled(disabled.position)
      setValuesChecked(true)
    }
  }, [values, fieldsArray, isValuesChecked])

  const handleChange = useCallback((field) => (e) => {
    const obj = {
      ...values.client?.person,
      [field]: e.target.value
    }
    inputProps.onChange({
      target: {
        value: {
          ...obj,
          fullName: `${obj.lastName ?? ''} ${obj.firstName ?? ''} ${obj.middleName ?? ''}`.trim()
        }
      }
    })
  }, [values.client?.person])

  const blurAllFields = useCallback(() => {
    if (errors.client?.person) {
      requiredFields.map(field => {
        field.current.focus()
        field.current.blur()
      })
      notify('ra.message.invalid_form', 'warning')
    } else {
      toggle()
    }
  }, [errors])

  return (
    <ModalBase
      title={translate('personWithRightToSignModal.title')}
      handleClose={toggle}
      isOpen={isOpen}
      contentClasses={classes.content}
      paperStyle={classes.paperStyle}
      maxWidth='lg'
      handleSave={blurAllFields}
    >
      {fieldsArray.map((item, index) => (
        <TextField
          className={classes.input}
          variant="filled"
          label={translate(`fields.${item.name}`)}
          value={(values.client?.person && values.client?.person[item.name]) ?? ''}
          onChange={handleChange(item.name)}
          inputRef={requiredFields[index]}
          required={item.required}
          helperText={errors.client?.person && translate(errors.client?.person[item.name])}
          error={errors.client?.person && errors.client?.person[item.name]}
          disabled={item.name === 'fullName' || (item.name === 'position' && isPositionDisabled) || (item.name.includes('Name') && isNameDisabled) ||
            loading !== 0 || !(
              supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
              supplierStatus === ''
            )
          }
        />
      ))}
    </ModalBase>
  )
}

export default Modal