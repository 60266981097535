//https://marmelab.com/react-admin/Authentication.html
import {standardApiUrl, baseApiUrl, customApiUrl} from "./httpDataProvider";
import { HttpError } from "react-admin";
let tokenRequest;

export async function getData(url, options) {
  if (!options) {
    options = {};
  }
  if (!options.headers) {
    options.headers = new Headers();
  }
  const tokenData = JSON.parse(localStorage.getItem("tokenData"));
  return tokenData && Date.now() < tokenData.expires_at * 1000
    ? await fetchWithToken(url, options, tokenData)
    : await getTokenRequest(tokenData).then(newToken =>
        fetchWithToken(url, options, newToken)
      );
}

async function fetchWithToken(url, options, tokenData) {
  options.headers.set("Authorization", "Bearer " + tokenData.access_token);
  return fetch(url, options);
}

async function getTokenRequest(tokenData) {
  if (!tokenRequest) {
    tokenRequest = refreshToken(tokenData).then(token => {
      tokenRequest = null;
      return token;
    });
  }
  return tokenRequest;
}

const refreshToken = tokenData => {
  let formData = new FormData();
  formData.append("grant_type", "refresh_token");
  formData.append("refresh_token", tokenData.refresh_token);
  return auth(formData);
};

const auth = formData => {
  const authorization = window.btoa(
    process.env.REACT_APP_REST_CLIENT_ID +
      ":" +
      process.env.REACT_APP_REST_CLIENT_SECRET
  );
  return fetch(`${standardApiUrl}/oauth/token`, {
    body: formData,
    method: "POST",
    headers: {
      Authorization: `Basic ${authorization}`
    }
  }).then(async res => {
    const json = await res.json();
    if (res?.status >= 200 && res?.status < 300) {
      return saveToken(json);
    }
    if (
      res?.status === 401 ||
      res?.status === 403 ||
      (res?.status === 400 && json?.error === "invalid_grant")
    ) {
      localStorage.removeItem("tokenData");
      tokenRequest = null;
      if (json?.error === "invalid_token") {
        json.error_description = "errors.errorToken";
      }
      return Promise.reject(
        new HttpError(
          json.error_description ?? "errors.unknownError",
          res.status
        )
      );
    }
  });
};

const saveToken = token => {
  token.expires_at = Math.floor(Date.now() / 1000 + token.expires_in);
  const jsonToken = JSON.stringify(token);
  localStorage.setItem("tokenData", jsonToken);
  return token;
};

const logout = () => {
  const tokenData = JSON.parse(localStorage.getItem("tokenData"));
  localStorage.removeItem("tokenData");
  tokenRequest = null;
  const authorization = window.btoa(
    process.env.REACT_APP_REST_CLIENT_ID +
      ":" +
      process.env.REACT_APP_REST_CLIENT_SECRET
  );
  let formData = new FormData();
  formData.append("token", tokenData.access_token);
  if (!tokenData) {
    return Promise.resolve("/login");
  }
  return fetch(`${standardApiUrl}/oauth/revoke`, {
    body: formData,
    method: "POST",
    headers: {
      Authorization: `Basic ${authorization}`
    }
  }).then(res => (res.ok ? "/login" : false));
};

const authProvider = {
  login: ({ username, password }) => {
    let formData = new FormData();
    formData.append("grant_type", "password");
    formData.append("username", username);
    formData.append("password", password);
    return auth(formData);
  },
  checkError: error => {
    const status = error?.status ?? 0;
    if (status === 401 || status === 403) {
      return Promise.resolve("/login");
    }
    return Promise.resolve();
  },
  checkAuth: params => {
    return localStorage.getItem("tokenData")
      ? Promise.resolve()
      : Promise.reject();
  },
  logout: () => logout(),
  getIdentity: async () => {
    const res = await getData(`${standardApiUrl}/userInfo`, {});

    if (res.status === 401 || res.status === 403) {
      localStorage.removeItem("tokenData");
      return Promise.reject();
    }
    const json = await res.json();

    
    const logLevel = await getData(`${customApiUrl}/log/level`, {});
    const lvl = await logLevel.json()


    const loggedUsersList = await getData(`${customApiUrl}/log/logins`, {});
    const loggedUsersJson = await loggedUsersList.json()

    const currentUser = json?.login
    const isCurrentUserAllowLog = (loggedUsersJson.includes(currentUser)
      || loggedUsersJson.length === 0) && lvl !== "NONE"

    localStorage.setItem("userId", json.id);

    localStorage.setItem("isCurrentUserAllowLog", isCurrentUserAllowLog);

    localStorage.setItem('logLevel', lvl)

    return {
      id: json.id,
      fullName: json.name
    };
  },
  getPermissions: params => Promise.resolve()
};

export default authProvider;
