import * as React from 'react'
import { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate, TextInput, required, SelectInput
} from 'react-admin'
import { Field, useFormState } from 'react-final-form'
import InfoPopover from '../../../../components/InfoPopover'

import { makeStyles } from '@material-ui/core/styles'
import TradeAccountField from './components/TradeAccountField'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    maxWidth: '1000px',
    padding: '9px 16px 25px',
    flex: 'unset',
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: 400,
    },
    [theme.breakpoints.down('xs')]: {
      gridColumnGap: theme.spacing(1),
    },
  },
  input: {
    display: 'block',
    width: '100%',
    maxWidth: 420,
    marginBottom: 12,
    '& > div': {
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  hint: {
    marginTop: -17
  }
}))

const validRequired = [required()]

const Form = ({ mode }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { modified } = useFormState()

  const constants = useSelector(state => state.customReducer.constants)

  useEffect(() => {
    const modifiedForCheck = { ...modified }
    delete modifiedForCheck.tradeAccount
  }, [modified])

  const organizationTypeOptions = useMemo(() => ([
    { value: constants?.SUBJECT_TYPE_LEGAL_PERSON, name: 'fields.legal' },
    { value: constants?.SUBJECT_TYPE_INDIVIDUAL, name: 'fields.individual' }
  ]), [constants])

  const renderTradeAccount = (props) => (
    <TradeAccountField
      {...props}
      className={classes.input}
      validate={validRequired}
      mode={mode}
    />
  )
  return (
    <div className={classes.formControl}>
      <Field
        name="tradeAccount"
        render={renderTradeAccount}
      />
      <TextInput
        className={classes.input}
        label={translate('fields.nameDC')}
        source="name"
        validate={validRequired}
        helperText=""
      />
      <SelectInput
        className={classes.input}
        label={translate('fields.organizationType')}
        source="organizationType"
        choices={organizationTypeOptions}
        validate={validRequired}
        optionValue="value"
        helperText=""
      />
      <TextInput
        className={classes.input}
        label={translate('fields.legalAddress')}
        source="fullAddress"
        multiline
        rows={5}
        InputProps={{
          endAdornment: (
            <InfoPopover
              id="fullAddress"
              content={translate('fields.helperTextDCFullAddress')}
              iconClass={classes.hint}
            />
          )
        }}
        validate={validRequired}
        helperText=""
      />
    </div>
  )
}

export default Form