import * as React from 'react'
import {
  Edit, SimpleForm, useTranslate
} from 'react-admin'
import Form from '../components/Form'
import UserEditToolbar from '../components/Toolbar'

const UserCreate = props => {
  const translate = useTranslate()
  return (
    <Edit
      {...props}
      title={translate('resources.users.edit')}
    >
      <SimpleForm
        toolbar={
          <UserEditToolbar mode="edit" />
        }
        alwaysEnableSaveButton={true}
      >
        <Form mode="edit" />
      </SimpleForm>
    </Edit>
  )
}

export default UserCreate