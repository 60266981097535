import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  CreateButton,
  Datagrid,
  FunctionField,
  List,
  TextField,
  TopToolbar,
  useListContext,
  useTranslate
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import BulkActionButtons from '../components/BulkActionButtons'
import Pagination from '../../../components/Pagination'
import { stylesList } from '../../../helpers/enums'

const useStyles = makeStyles(stylesList)

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()

  return (
    <TopToolbar {...props} id="list-actions-light">
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton />
    </TopToolbar>
  )
}

const DistributionCenters = (props) => {
  const classes = useStyles()
  const translate = useTranslate()

  const constants = useSelector(state => state.customReducer.constants)

  return (
    <List
      classes={{
        root: classes.root,
        bulkActionsDisplayed: classes.bulkActionsDisplayed
      }}
      title={translate('menuItem.distributionCenters')}
      {...props}
      actions={
        <ListActions />
      }
      bulkActionButtons={
        <BulkActionButtons />
      }
      pagination={<Pagination />}
      perPage={25}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField
          label={translate('fields.tradeAccount')}
          source="tradeAccount.name"
        />
        <TextField
          label={translate('fields.nameDC')}
          source="name"
        />
        <FunctionField
          label={translate('fields.organizationType')}
          render={record => (
            record.organizationType === constants?.SUBJECT_TYPE_LEGAL_PERSON ? translate('fields.legal') :
              record.organizationType === constants?.SUBJECT_TYPE_INDIVIDUAL ? translate('fields.individual') : ''
          )}
        />
        <TextField
          label={translate('fields.legalAddress')}
          source="fullAddress"
        />
      </Datagrid>
    </List>
  )
}

export default DistributionCenters