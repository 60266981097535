import englishMessages from "ra-language-english"

const customEnglishMessages = {
  ...englishMessages,
  emptyPage: {
    title: "R.Ice Vendor Portal.",
    subtitle: "Please select Create Supplier to begin.",
    button: "Create Supplier"
  },
  menuItem: {
    suppliers: "Suppliers",
    supplierCreator: "Supplier creator",
    settings: "Settings",
    help: "Help",
    contactPersons: "Contact persons",
    attorneyLetters: "Attorney letters",
    pointsOfSale: "Points of sale",
    distributionCenters: "Distribution centers"
  },
  resources: {
    suppliers: {
      edit: "Edit: Supplier",
    },
    users: {
      create: "Create: Contact person",
      edit: "Edit: Contact person"
    },
    attorneyLetters: {
      create: "Create: Attorney letter",
      edit: "Edit: Attorney letter"
    },
    pointsOfSale: {
      create: "Create: Point of sale",
      edit: "Edit: Point of sale"
    },
    distributionCenters: {
      create: "Create: Distribution center",
      edit: "Edit: Distribution center"
    }
  },
  columns: {
    parenSupplier: "Parent supplier",
    document: "Document",
    is_required: "Required",
    reason: "Reason for rejection",
    organization: "Organization"
  },
  fields: {
    organizationType: "Organization type",
    organizationFullName: "Organization full name",
    email: "Service email",
    helpertextEmail: "The email address will be used as login to log in to the Vendor portal",
    vatPayer: "Vat payer",
    taxpayer: "Are you the biggest taxpayer?",
    bankCode: "Bank Identification Code",
    initiator: "Initiator",
    status: "Status",
    presentationDate: "Presentation date",
    tradeAccount: "Trade Account",
    createDate: "Create date",
    grantDate: "Grant date",
    solution: "Decision",
    inn: "INN",
    kpp: "KPP",
    lastName: "Last name",
    firstName: "First name",
    middleName: "Middle name",
    organizationName: "Organization name",
    legalAddress: "Legal address",
    helpertextLegalAddress: "In accordance with the Constituent documents and the OGRN",
    index: "Index",
    region: "Region",
    district: "District",
    town: "Town",
    street: "Street",
    house: "House",
    housing: "Housing",
    building: "Building",
    apartment: "Apartment/Office",
    contactPerson: "Contact person",
    fullName: "Full name",
    phone: "Phone",
    serviceEmail: "Service email",
    helpertextServiceEmail: "This address will be fixed in the contact for sending notifications or other communication be e-mail",
    personWithRightToSign: "Person with right to sign without a power of attorney",
    position: "Position",
    individual: "Individual",
    legal: "Legal person",
    fullNameFounder: "Full name",
    hasMoreThenFiftyPercent: "Has more then fifty percent",
    yes: "Yes",
    no: "No",
    directorName: "Director Full name",
    organizationFounder: "Organization founder",
    basisNonPayment: "The basis for the absence of VAT payments",
    biggestTaxpayerKpp: "KPP of the biggest taxpayer",
    bankCodeHint: "Fill the BIC of the bank for payments. The rest fields will be filled in automatically.",
    currentAccountName: "Current account holder name",
    currentAccount: "Current account №",
    correspondentAccount: "Correspondednt account №",
    bankFullName: "Bank full name",
    bankLocation: "Bank location",
    buttonForDeclaration: "No declaration",
    taxPeriod: "Reporting tax period for VAT payment",
    yearSubmitted: "Year of submission of the VAT return",
    noDeclarationHelper: "Providing a VAT declaration is a mandatory requirement.\n\rIn the 'Comment' field, please describe the reason for not providing it.\n\rWe will check the full package of information you provided and come back with comments",
    comment: "Comment",
    signerNameColumn: "Signer name",
    signerName: "Signer full name",
    attorneyLetterAct: "Attorney letter act",
    attorneyLetterNumber: "Attorney letter number",
    validUntil: "Valid until",
    issueDate: "Issue date",
    validFrom: "Valid from",
    file: "File",
    emailForLogin: "Email",
    language: "Language",
    currentPassword: "Current password",
    newPassword: "New password",
    confirmNewPassword: "Confirm new password",
    gender: "Gender",
    male: "Male",
    female: "Female",
    egrip: "EGRIP",
    egripDate: "EGRIP date",
    supplier: "Supplier",
    isActive: "Active",
    namePointOfSale: "Name point of sale",
    includedInInformationLetter: "Included in information letter",
    addressPointOfSale: "Full address point of sale",
    legalName: "Legal name",
    nameDC: "Name",
    helperTextDCFullAddress: "Full address including zip code",
    dcOwnedBy: "Distribution center of the Contractor",
    dcControlledBy: "Distribution center controlled by the Contractor",
    document: "Document",
    distributionCenter: "Distribution center",
    nameFounder: "Constitutor (person name and / or organization name)" ,
    nameFounderIndividual: "Constitutor name" ,
    nameFounderOrganization: "Organization name" ,
    sent: "Sent",
    posInn: "POS inn"
  },
  formSuppliers: {
    tabs: {
      clientCard: "Client's card",
      constituentDocs: "Constituent Docs",
      contactPersons: "Contact person",
      powerOfAttorney: "Power of attorney",
      informationLetter: "Information Letter"
    },
    titles: {
      mainInfoSupplier: "Main information about provider",
      foundersOfTheVendor: "Founders of the vendor",
      vendorTaxation: "Vendor taxation",
      bankDetails: "Supplier's bank detail",
      legalAddress: "Legal address editor"
    }
  },
  createSupplierModal: {
    title: "Create new supplier"
  },
  personWithRightToSignModal: {
    title: "A person who has the right to sign without a power of attorney, editor"
  },
  attorneyLettersModal: {
    titleCreate: "Creation of attorney letter",
    titleEdit: "Edition of attorney letter",
    organizationSubtitle: "Договор об оказании услуг планируется к заключению между ООО «ФМСМ» и",
    nameSubtitle: "в лице",
    commentHint: "В поле \"Комментарий\" укажите дополнительную информацию, в таком случае\n«В лице Управляющего индивидуального предпринимателя Иванова Ивана Ивановича, действующего на основании Устава и Договора о передаче полномочий единоличного исполнительного органа общества с ограниченной ответственностью \"Ромашка\" управляющему индивидуальному предпринимателю от \"01\" марта 2021 года».",
    helperName: "",
    helperSignerName: ""
  },
  infoLetterModal: {
    title:"INFORMATION LETTER",
    mainText: "We hereby notify \"Philip Morris Sales and Marketing\" LLC that %{organizationName} (hereinafter referred to as the \"Contractor\") has all the legal powers to fulfill its obligations in relation to the Services provided on the basis of an agreement with \"Philip Morris Sales and Marketing\" LLC in the following outlets",
    responseByPointsOfSaleLabel: "%{organizationName} manages the following points of sale?",
    responseByDistributionCenterLabel: "Is tobacco and/or Nicotine-containing products (as defined in the agreement between the Contractor and \n \"Philip Morris Sales and Marketing\" LLC) purchased centrally using the Distribution Center?",
    typeOfDistributionCenterLabel: "Distribution center of the Contractor or controlled by the Contractor?",
    dateQuestion: "On the first day of which month the indicated list of outlets is valid?",
    disclaimerLabel: "TIN of points of sales verified and confirmed"
  },
  attorneyLettersConfirmationModal: {
    title: "",
    content: ""
  },
  registeredInRussia: "Founder Organization is registered in Russian Federation",
  innNotFound: "No valid entry in the Unified State Register of Legal Entities was found. Please check your input is correct.",
  founders_of_the_vendor: {
    modal: {
      title: "Supplier founder info",
      organization_head: "Organization head",
    }
  },
  confirmationVerifyModal: {
    title: "Check data",
    content: "Please confirm that the Constituent Docs do not require updating and Power of Attorney are up to date"
  },
  changePassword: {
    title: "Change password",
  },
  constituentDocModal: {
    titleConstituentDoc: "Add Constituent Doc",
    titleDeclaration: "Declaration document editor",
    downloadExampleFile: "Download file example",
    downloadHelper:
      "Upload your document in a convenient format. \n\rRecommended download size: 20 MB",
  },
  consentModal: {
    title: "Welcome to R.Ice - Supplier Portal",
    consentSubtitle: "Consent to the processing and storage of the provided personal data:",
    passwordSubtitle: "Please set a new password:",
    acceptLabelCheckbox: "Accept data processing and storage",
    passwordPolicy: {
      title: "The password must:",
      list0: "have at least 9 characters",
      list1: "include both uppercase and lowercase letters",
      list2: "have at least 1 letters",
      list3: "have at least 1 digits",
      list4: "not contain the profile ID or name",
      list5: "not be a password used during the past 365 days",
      list6: "password must be changed every 90 days",
    }
  },
  pointsOfSale: {
    subtitle: "Indicate a legal entity / individual entrepreneur, if the outlet is not a separate subdivision of the Contractor",
    addressModalTitle: "Edit sales outlet address",
    emptyList: "The list is currently empty",
    tableTitle: "List of Point of Sales for Information letter"
  },
  confirmModalUpdatePointsOfSale: {
    title: "Confirm update points of sale",
    content: "Confirm points of sale update:"
  },
  confirmModalUpdatePointOfSale: {
    title: "Warning",
    content: "Confirm updating of point of sale: "
  },
  login: {
    title: "Login",
    warning: {
      accountLocked: "Account temporarily locked. Try logging in later",
      badCredentials: "Bad credentials",
      passwordExpired: "Password expired. Please start the password recovery process"
    }
  },
  dropZone: {
    dropzoneText: "Upload file",
    dropzoneEditText: "Edit file",
    loadedFile: "Loaded file",
    chosenFile: "Chosen file",
    statusRejected: "File is not selected",
    statusConfirm: "File is uploaded",
  },
  buttons: {
    ok: "OK",
    create: 'Create',
    add: "Add",
    clone: "Clone",
    deleteSupplier: "Delete Supplier",
    provide: "Provide",
    accept: "Accept",
    cancel: "Cancel",
    save: "Save",
    send: "Send",
    back: "Back",
    provideAttorneyLetters: "Provide attorney letters",
    comeBack: "",
    refreshData: "Refresh Data",
    editData: "Edit Data",
    next: "Next",
    sendLetter: "Send a letter",
    download: "Download",
    confirm: "Confirm",
    sendInfoletter: "Send letter",
    saveChanges: "Save changes",
    sendPOSes: "Send points of sale",
    exportPOSToExcel: "Export to Excel",
    editInfoLetter: "Edit Information Letter",
    newInfoLetter: "New Information Letter",
    confirmData: "Confirm",
    confirmChanges: "Confirm changes",
    pointOfSalesForConfirmation: "PointsOfSales for confirmation",
    confirmChangesAndSave: "Confirm changes and save"
  },
  errors: {
    length: "Length must be equal to",
    inn: "Number of digits for a Legal entity: %{legalLength}\n\rNumber of digits for Individual: %{individualLength}",
    currentAccountNumberLegal: "Current account number of an Legal entity must start with 40702 or 40703...",
    currentAccountNumberIndividual: "Current account number of an Individual must start with 40802...",
    phoneLength: "Phone must contain 11 digits",
    addRequiredDocs: "Required constituent documents must be added",
    addRequiredPowerOfAttorney: "Power of Attorney must be added",
    passwordMatch: "Password mismatch",
    invalidDate: "Invalid date",
    inactiveSupplier: "Supplier is inactive",
    errorDuringInformationLetterCreation: "Error during information letter creation",
    errorDuringPOSExport: "Error during points of sales export or user does not have any points of sales",
    errorToken: "The session has timed out. Update the portal login.",
    unknownError: "Unknown error"
  },
  notify: {
    dataSaved: "The data has been saved",
    dataSent: "Data was sent",
    passwordSaved: "The password was successfully updated",
    dataProvided: "Data was provided",
    elementCreated: "Element was created",
    dataSavedAndSent: "Data was saved and sent"
  },
  passwordRecovery: "Password recovery",
  duplicateNotifyModal: {
    title: "Duplicate",
    content: "Attention! Supplier with INN %{inn} has already been created"
  },
  help: {
    titleUL: "Section for Legal entities",
    titleIndividual: "Section for Individual Entrepreneurs",
    videoTitle: "Video instructions for filling in data on the Supplier Portal",
    instructionTitle: "Instructions for filling in data on the Supplier Portal",
    instructionSubtitle: "Instructions for suppliers"
  },
  footer: {
    text: "DEAR PARTNER," +
      " please read the message from Artyom Krivtsov, Managing Director of Affiliated Companies" +
      " Philip Morris International in Russia, on the protection of minors from the effects of tobacco and nicotine. ",
    hrefName: "Familiarize"
  }
}

export default customEnglishMessages