import * as React from 'react'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate, required, ReferenceInput, SelectInput, AutocompleteInput, useDataProvider
} from 'react-admin'
import { TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Field, useFormState } from 'react-final-form'
import { genderOptions, generalFields } from '../../../../helpers/enums'
import AttorneyLetterActSelect from './components/AttorneyLetterActSelect'
import AttorneyLetterTextField from './components/AttorneyLetterTextField'
import CommentField from './components/CommentField'
import DatesField from './components/DatesField'
import AttorneyLetterNumberTextField  from './components/AttorneyLetterNumberTextField'
import FileField  from './components/FileField'
import StatusField  from './components/StatusField'
import { maxLength } from '../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,
    width: '100%',
    maxWidth: '1000px',
    padding: '9px 16px 25px',
    flex: 'unset',
    [theme.breakpoints.down('md')]: {
      width: '85%',
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: 400,
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridColumnGap: theme.spacing(1),
    },
  },
  input: {
    width: '100%',
  },
  supplier: {
    width: '100%',
  },
  organizationName: {
    gridColumn: '1/3',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1/2',
    },
  },
  supplierSelected: {
    '& label[data-shrink="false"]': {
      fontSize: '1rem',
      transform: 'translate(12px, 7px) scale(0.75) !important'
    }
  },
  subtitle: {
    gridColumn: '1/3',
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1/2',
    },
  },
  helperText: {
    margin: '0 14px'
  },
  fieldWrapper: {
    '& div': {
      width: '100%'
    }
  },
}))

const validRequired = [required()]

const Form = ({ mode, supplierStatusIdentifier, setSupplierStatusIdentifier, inputProps }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const validRequiredMaxLength = [required(), maxLength(255, translate)]
  const validMaxLength = [maxLength(255, translate)]

  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const constants = useSelector(state => state.customReducer.constants)

  const [supplier, setSupplier] = useState(null)
  const [isSupplierChanged, setSupplierChanged] = useState(false)

  const { values, errors, touched, modified } = useFormState()

  useEffect(() => {
    if (values.supplier?.id && providerStatuses.length) {
      getSelectedSupplier()
    }
  }, [values.supplier, providerStatuses])

  const getSelectedSupplier = useCallback(async () => {
    const supplier = await dataProvider.getOne('suppliers', { id: values.supplier.id })

    if (supplier?.data) {
      const supplierStatus = providerStatuses.find(status => status.identifier === supplier.data.client.providerStatus?.identifier)
      setSupplierStatusIdentifier(supplierStatus?.identifier ?? '')
    } else {
      setSupplierStatusIdentifier('')
    }

    setSupplier(supplier?.data ?? null)
  }, [values.supplier, providerStatuses])

  useEffect(() => {
    const modifiedForCheck = { ...modified }
    delete modifiedForCheck.status
    delete modifiedForCheck.date
  }, [modified])

  const isDisabledForm = useMemo(() => (
    supplierStatusIdentifier === constants?.INACTIVE_PROVIDER_STATUS_I
  ), [supplierStatusIdentifier, constants])

  const renderAttorneyLetterAct = (props) => (
    <AttorneyLetterActSelect {...props} />
  )

  const renderField = ({name, required, defaultValue}) => (props) => (
    <AttorneyLetterTextField
      {...props}
      label={translate(`fields.${name}`)}
      source={name}
      validate={required ? validRequiredMaxLength : validMaxLength}
      defaultValue={values.attorneyLetterAct?.identifier === constants.CHARTER_ACT_I ? defaultValue : ''}
      isSupplierChanged={isSupplierChanged}
      disabled={isDisabledForm}
    />
  )

  const renderSupplierComment = ({
                                   input: {...inputProps},
                                   ...props
                                 }) => (
    <CommentField
      inputProps={inputProps}
      disabled={isDisabledForm}
      validate={validMaxLength}
      {...props}
    />
  )

  const renderAttorneyLetterNumber = (props) => (
    <AttorneyLetterNumberTextField
      {...props}
      name="attorneyLetterNumber"
      validate={validRequiredMaxLength}
      inputClassName={classes.input}
      disabled={isDisabledForm}
    />
  )

  const renderDates = props => (
    <DatesField
      {...props}
      disabled={isDisabledForm}
    />
  )

  const renderFile = (props) => (
    <FileField
      {...props}
      validate={validRequired}
      disabled={isDisabledForm}
    />
  )

  const renderStatus = (props) => (
    <StatusField
      {...props}
      supplierStatusIdentifier={supplierStatusIdentifier}
    />
  )

  const onChangeSupplier = useCallback((id) => {
    setSupplierChanged(true)
  }, [])

  return (
    <div className={classes.form}>
      <Field
        name="attorneyLetterAct"
        render={renderAttorneyLetterAct}
      />
      <ReferenceInput
        className={`${classes.supplier} ${values.supplier ? classes.supplierSelected : ''}`}
        source="supplier.id"
        label={translate('fields.supplier')}
        reference="suppliers"
        filterToQuery={(searchText) => ({
          notConvert: true,
          filters: [{
            group: "AND",
            conditions: [
              {
                property: 'client.organizationName',
                operator: 'contains',
                value: searchText
              }
            ]
          }]
        })}
        onChange={onChangeSupplier}
        validate={validRequired}
      >
        <AutocompleteInput
          optionText="client.organizationName"
          helperText={supplierStatusIdentifier === constants?.INACTIVE_PROVIDER_STATUS_I ? translate('errors.inactiveSupplier') : ''}
          allowEmpty={false}
          options={{
            error: supplierStatusIdentifier === constants?.INACTIVE_PROVIDER_STATUS_I || (touched && errors.supplier?.id)
          }}
        />
      </ReferenceInput>
      <Typography className={classes.subtitle} variant="h6">
        {translate('attorneyLettersModal.organizationSubtitle')}
      </Typography>
      <TextField
        className={`${classes.organizationName} ${classes.input}`}
        label={translate('fields.organizationFullName')}
        variant="filled"
        value={supplier?.client?.organizationFullName ?? ''}
        disabled
      />
      <Typography className={classes.subtitle} variant="h6">
        {translate('attorneyLettersModal.nameSubtitle')}
      </Typography>
      <Field
        name="status"
        render={renderStatus}
      />
      {generalFields.map(field => (
        field.name !== 'gender' ?
          <div className={classes.fieldWrapper}>
            <Field
              name={field.name}
              render={
                renderField({
                  ...field,
                  defaultValue: (
                    field.name === 'signerName' ? (
                      (supplier?.client?.person?.lastName && supplier?.client?.person?.firstName) &&
                      `${supplier.client.person?.lastName} ${supplier.client.person?.firstName}${
                        supplier.client.person?.middleName ? ` ${supplier.client.person?.middleName}` : ''
                      }`
                    ) : supplier?.client?.person[field.name] ?? ''
                  ),
                })
              }
            />
            <Typography className={classes.helperText}>
              {(field.name === 'firstName' || field.name === 'lastName' || field.name === 'middleName') ?
                translate('attorneyLettersModal.helperName') : field.name === 'signerName' ?
                  translate('attorneyLettersModal.helperSignerName') : ''
              }
            </Typography>
          </div> : <SelectInput
            label={translate('fields.gender')}
            source="gender"
            choices={genderOptions}
            validate={validRequired}
            optionValue="value"
            helperText=""
            disabled={isDisabledForm}
          />
      ))}
      {values.attorneyLetterAct?.identifier === constants.POWER_OF_ATTORNEY_ACT_I && (
        <>
          <Field
            name="attorneyLetterNumber"
            render={renderAttorneyLetterNumber}
          />
          <Field
            name="date"
            render={renderDates}
          />
          <Field
            name="supplierComment"
            render={renderSupplierComment}
          />
          <Field
            name="file"
            render={renderFile}
          />
        </>
      )}
    </div>
  )
}

export default Form