import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl } from '@material-ui/core'
import DropZone from '../../../../../../components/DropZone'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    gridColumn: '1 / 3',
    marginTop: 8,
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1/2',
    },
  }
}))

const FileField = ({
                     validate,
                     disabled,
                     meta: { touched, error} = {touched: false, error: undefined},
                     input: { ...inputProps },
                     ...props
                   }) => {
  const classes = useStyles()
  const { values, submitFailed } = useFormState()

  const [file, setFile] = useState()
  const [blurred, setBlurred] = useState(false)

  useEffect(() => {
    return () => {
      setFile([])
      inputProps.onChange({
        target: {
          value: null
        }
      })
    }
  }, [])

  useEffect(() => {
    if (submitFailed) {
      setBlurred(true)
    }
  }, [submitFailed])

  const handleFile = useCallback(async (newFileObjs) => {
    const files = newFileObjs.length ? newFileObjs : []

    let file
    let value

    if (files[0]?.path) {
      const arrayBuffer = await files[0].arrayBuffer()
      const buffer = new Uint8Array(arrayBuffer)
      file = files
      value = {
        file: buffer,
        name: files[0].name,
        type: files[0].type
      }
      setBlurred(true)
    } else if (!files[0] && !values.file?.id) {
      file = []
      value = null
    }

    if (file) {
      setFile(file)
    }
    if (value || value === null) {
      inputProps.onChange({
        target: {
          value
        }
      })
    }
  }, [values])

  return (
    <>
      <FormControl className={classes.wrapper}>
        {!disabled && <DropZone
          file={file}
          valuesFile={values.file}
          handleInput={handleFile}
          onDelete={handleFile}
          blurred={blurred}
          error={error}
        />}
      </FormControl>
      <TextInput
        source="file"
        validate={validate}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default FileField