import * as React from 'react'
import { Fragment } from 'react'
import {useTranslate} from 'react-admin'
import ContentCheck from '@material-ui/icons/CheckSharp'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { makeStyles } from '@material-ui/core/styles'
import {
  useRefresh,
  useUnselectAll,
  useResourceContext,
} from 'ra-core'
import {sendConfirmChanges} from '../../../../api/customApi'
import {Button} from "@material-ui/core"
import {setChangedFields} from "../../index"

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
}))

const defaultIcon = <ContentCheck />

const ChangesConfirmButton = props => {
  const {
    icon = defaultIcon,
    label,
    selectedIds,
  } = props
  const classes = useStyles(props)
  const unselectAll = useUnselectAll()
  const refresh = useRefresh()
  const resource = useResourceContext(props)
  const translate = useTranslate()

  const confirmChanges = () => {
    unselectAll(resource)
    sendConfirmChanges(selectedIds).then(result => {
      setChangedFields(result)
      refresh()
    })
  }

  const onConfirmChanges = e => {
    confirmChanges()
    e.stopPropagation()
  }

  return (
    <Fragment>
      <Button
        className={classes.button}
        label={label}
        color="primary"
        onClick={onConfirmChanges}
      >
        {icon}
        <span>
          {translate('buttons.confirmChanges')}
        </span>
      </Button>
    </Fragment>
  );
};

export default ChangesConfirmButton;