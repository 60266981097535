import React from 'react'
import { useState, useEffect, useMemo, useCallback } from 'react'
import { useTranslate, useNotify } from 'react-admin'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DropZone from '../../../../../../../components/DropZone'
import ModalBase from '../../../../../../../components/ModalBase'

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%',
  },
  item: {
    width: '100%',
    marginBottom: '16px'
  }
}))

const EditDocumentModal = ({
                             isOpen, toggle, onAddDocument, selectedKind, documents, onGetDocument, disabledFields
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const [fileObjects, setFileObjects] = useState([])
  const [blurred, setBlurred] = useState(false)

  const document = useMemo(() => (
    documents.find(item => item.docKind.identifier === selectedKind.identifier)
  ), [documents, selectedKind])

  useEffect(() => {
    setFileObjects(document?.file ? [document.file] : [])
  }, [documents, selectedKind, document])

  const handleInput = useCallback((files) => {
    if (files.length) {
      setFileObjects(files)
      setBlurred(true)
    } else if (!fileObjects[0]?.id && !fileObjects[0]?.file) {
      setFileObjects([])
    }
  }, [fileObjects])

  const handleSave = useCallback(async () => {
    if (fileObjects.length) {
      await onAddDocument({ file: fileObjects[0], name: fileObjects[0].name })
      toggle()
    } else if (document?.file.id) {
      toggle()
    } else {
      notify('ra.message.invalid_form', 'warning')
    }
  }, [fileObjects, selectedKind])

  return (
    <ModalBase
      title={translate('constituentDocModal.titleConstituentDoc')}
      handleClose={toggle}
      isOpen={isOpen}
      paperStyle={classes.paperStyle}
      maxWidth='sm'
      handleSave={handleSave}
    >
        <Button
          className={classes.item}
          variant="outlined"
          color="primary"
          startIcon={<CloudDownloadIcon />}
          disabled={!selectedKind.file?.id}
          onClick={onGetDocument(selectedKind)}
        >
          {translate('constituentDocModal.downloadExampleFile')}
        </Button>
        <DropZone
          file={fileObjects[0]?.path ? fileObjects : []}
          valuesFile={(fileObjects[0]?.id || fileObjects[0]?.file) ? fileObjects[0] : null}
          handleInput={handleInput}
          blurred={blurred}
          error={!fileObjects[0]}
          disabled={disabledFields}
          warningText={translate('constituentDocModal.downloadHelper')}
        />
    </ModalBase>
  )
}

export default EditDocumentModal
