import * as React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TextInput } from 'react-admin'

const StatusField = ({
                       supplierStatusIdentifier,
                       meta: {touched, error} = {touched: false, error: undefined},
                       input: {...inputProps},
                       ...props
                     }) => {
  const constants = useSelector(state => state.customReducer.constants)
  const attorneyLetterStatuses = useSelector(state => state.customReducer.attorneyLetterStatuses)

  useEffect(() => {
    let value
    if (supplierStatusIdentifier === constants?.DRAFT_PROVIDER_STATUS_I) {
      value = attorneyLetterStatuses?.find(status => (
        status.identifier === constants?.ADDED_ATTORNEY_STATUS_I
      ))
    } else {
      value = attorneyLetterStatuses?.find(status => (
        status.identifier === constants?.VALID_ATTORNEY_STATUS_I
      ))
    }

    inputProps.onChange({
      target: {
        value
      }
    })
  }, [supplierStatusIdentifier, constants, attorneyLetterStatuses])

  return (
    <TextInput
      source="status"
      disabled
      style={{
        display: 'none'
      }}
    />
  )
}

export default StatusField