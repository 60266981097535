import * as React from 'react'
import { useEffect } from 'react'
import { TextInput, useTranslate } from 'react-admin'

const AttorneyLetterNumberTextField = ({
                                name,
                                validate,
                                inputClassName,
                                disabled,
                                meta: { touched, error} = {touched: false, error: undefined},
                                input: { ...inputProps },
                                ...props
                              }) => {
  const translate = useTranslate()

  useEffect(() => {
    return () => {
      inputProps.onChange({
        target: {
          value: null
        }
      })
    }
  }, [])

  return (
    <TextInput
      className={inputClassName}
      label={translate('fields.attorneyLetterNumber')}
      source="attorneyLetterNumber"
      validate={validate}
      helperText=""
      disabled={disabled}
    />
  )
}

export default AttorneyLetterNumberTextField