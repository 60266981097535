import * as React from 'react'
import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { required, TextInput, useTranslate } from 'react-admin'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'


const useStyles = makeStyles((theme) => ({
  helperText: {
    marginLeft: 14,
    marginRight: 14
  },
  formControl: {
    margin: '8px 0 4px'
  }
}))

const validRequired = [required()]

const AttorneyLetterActSelect = ({
                                   meta: { touched, error } = { touched: false, error: undefined },
                                   input: { ...inputProps },
                                   ...props
                                 }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { values, submitFailed } = useFormState()

  const attorneyLetterActs = useSelector(state => state.customReducer.attorneyLetterActs)
  const constants = useSelector(state => state.customReducer.constants)

  useEffect(() => {
    if (!values.attorneyLetterAct?.id) {
      const attorneyLetterAct = attorneyLetterActs.find(item => (
        item.identifier === constants.POWER_OF_ATTORNEY_ACT_I
      ))

      inputProps.onChange({
        target: {
          value: attorneyLetterAct
        }
      })
    }
  }, [attorneyLetterActs, constants])

  const onChange = useCallback((e) => {
    const id = e.target.value
    const attorneyLetterAct = attorneyLetterActs.find(item => (
      item.id === id
    ))
    inputProps.onChange({
      target: {
        value: attorneyLetterAct
      }
    })
  }, [attorneyLetterActs])

  return (
    <FormControl className={classes.formControl} error={touched && error} required>
      <InputLabel disabled>
        {translate('fields.attorneyLetterAct')}
      </InputLabel>
      <Select
        variant='filled'
        value={values.attorneyLetterAct?.id ?? ''}
        onChange={onChange}
        onBlur={inputProps.onBlur}
        disabled
      >
        {attorneyLetterActs.map((item) => (
          <MenuItem value={item.id}>{translate(item.name)}</MenuItem>
        ))}
      </Select>
      {(touched || submitFailed) && error && (
        <FormHelperText className={classes.helperText} error={(touched || submitFailed) && error}>
          {translate(error)}
        </FormHelperText>
      )}
      <TextInput
        source="attorneyLetterAct"
        validate={validRequired}
        disable
        style={{ display: 'none' }}
      />
    </FormControl>
  )
}

export default AttorneyLetterActSelect