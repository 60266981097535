import React from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles'
import { useTranslate } from 'react-admin'
import { downloadDocument } from '../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  dropZone: {
    gridRow: '4 / 5',
    minHeight: 47,
    height: 47,
    padding: 0,
    margin: '10px 0',
    background: ({ error, isExistFile }) => (
      isExistFile ? '#1565C0' : error ? '#F2B8B5' : '#EBF0F8'
    ),
    border: 'none',
    transition: 'all 0.2s ease-in-out',
    '&:hover': {
      background: ({ error, isExistFile }) => (
        isExistFile ? '#135fb6' : error ? '#eea6a3' : '#d9e2f1'
      ),
    },
    '& .MuiDropzoneArea-textContainer': {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    '& svg': {
      width: 24,
      height: 24,
      marginRight: 10,
      color: ({ error, isExistFile }) => (
        isExistFile ? '#fff' : error ? '#662211' : '#1565C0'
      ),
    }
  },
  dropZoneText: {
    margin: 0,
    fontWeight: 500,
    fontSize: 14,
    textTransform: 'uppercase',
    color: ({ error, isExistFile }) => (
      isExistFile ? '#fff' : error ? '#662211' : '#1565C0'
    )
  },
  helpText: {
    margin: 0,
  },
  fileName: {
    display: 'block',
    marginBottom: 10,
    color: '#646FF7',
    cursor: 'pointer'
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr',
  },
  warning: {
    gridRow: '3 / 4',
    margin: '10px 0'
  }
}))

const DropZone = ({
                    file,
                    handleInput,
                    deleteFile,
                    valuesFile,
                    blurred,
                    error,
                    disabled,
                    className = '',
                    warningText
}) => {
  const translate = useTranslate()
  const classes = useStyles({
    isExistFile: (file && file[0] || valuesFile),
    error: blurred && error
  })

  return (
    <>
      {((valuesFile?.id || valuesFile?.file) && (!file || !file[0])) && (
        <div className={className}>
          <p
            className={classes.helpText}
            style={{marginBottom: 4}}
          >
            {valuesFile?.id ? translate('dropZone.loadedFile') : translate('dropZone.chosenFile')}
          </p>
          <p className={classes.helpText}>
            <a
              className={classes.fileName}
              onClick={downloadDocument(valuesFile)}
            >
              {valuesFile?.name}
            </a>
          </p>
        </div>
      )}

      {!disabled && (
        <div className={classes.wrapper}>
          {warningText && (
            <p className={classes.warning}>
              {warningText}
            </p>
          )}
          <DropzoneArea
            initialFiles={file}
            filesLimit={1}
            acceptedFiles= {["image/*","application/pdf","application/x-7z-compressed","application/zip",".xls",".doc",".docx",".xlsx",".ppt",".pptx"]}
            useChipsForPreview
            showPreviews={true}
            showPreviewsInDropzone={false}
            showAlerts={false}
            maxFileSize={20971520}
            dropzoneClass={`${classes.dropZone} ${className}`}
            dropzoneParagraphClass={classes.dropZoneText}
            dropzoneText={(file && file[0] || valuesFile) ?
              translate('dropZone.dropzoneEditText') : translate('dropZone.dropzoneText')
            }
            previewText={translate('dropZone.chosenFile')}
            previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
            onChange={handleInput}
            onDelete={deleteFile}
            disabled={disabled}
          />
        </div>
      )}
    </>
  )
}

export default DropZone