import russianMessages from "ra-language-russian"

const customRussianMessages = {
  ...russianMessages,
  ra: {
    ...russianMessages.ra,
    message: {
      ...russianMessages.ra.message,
      bulk_delete_title: "Удалить |||| Удалить объекты: %{smart_count} |||| Удалить объекты: %{smart_count}",
      bulk_delete_content:
        "Вы уверены, что хотите удалить объект из таблицы? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ? |||| Вы уверены, что хотите удалить объекты, кол-вом %{smart_count} ?",
    },
    page: {
      ...russianMessages.ra.page,
      empty: "%{name} отсутствуют.",
    }
  },
  emptyPage: {
    title: "R.Ice Портал поставщика.",
    subtitle: "Для начала работы, пожалуйста, нажмите кнопку \"Создать поставщика\".",
    button: "Создать поставщика"
  },
  menuItem: {
    suppliers: "Поставщики",
    supplierCreator: "Создать поставщика",
    settings: "Параметры",
    help: "Помощь",
    contactPersons: "Контактные лица",
    attorneyLetters: "Подписанты",
    pointsOfSale: "Торговые точки",
    distributionCenters: "Распределительные центры"
  },
  resources: {
    suppliers: {
      edit: "Редактирование Поставщика",
    },
    users: {
      create: "Создание Контактного лица",
      edit: "Редактирование Контактного лица"
    },
    attorneyLetters: {
      create: "Создание Подписанта",
      edit: "Редактирование Подписанта"
    },
    pointsOfSale: {
      create: "Создание Торговой точки",
      edit: "Редактирование Торговой точки"
    },
    distributionCenters: {
      create: "Создание Распределительного центра",
      edit: "Редактирование Распределительного центра"
    }
  },
  columns: {
    parenSupplier: "Учредитель Организации-Учредителя (Ф.И.О. и/или наименование организации)",
    is_active: "Активен",
    document: "Документ",
    is_required: "Обязательный",
    reason: "Причина отклонения",
    organization: "Организация"
  },
  fields: {
    organizationType: "Тип организации",
    organizationFullName: "Полное название организации",
    email: "Адрес служебной электронной почты",
    helpertextEmail: "Email адрес будет использован в качестве логина для входа на Портал Поставщика",
    vatPayer: "Плательщик НДС",
    taxpayer: "Являетесь ли вы крупнейшим налогоплательщиком?",
    bankCode: "Банковский идентификационный код (БИК)",
    initiator: "Инициатор",
    status: "Статус",
    presentationDate: "Дата предоставления",
    tradeAccount: "Торговая сеть",
    createDate: "Дата создания",
    grantDate: "Дата предоставления",
    solution: "Решение",
    inn: "ИНН",
    kpp: "КПП",
    lastName: "Фамилия",
    firstName: "Имя",
    middleName: "Отчество",
    organizationName: "Название организации",
    legalAddress: "Юридический адрес",
    helpertextLegalAddress: "В соответствии с Учредительными документами и ОГРН",
    index: "Индекс",
    region: "Регион",
    district: "Район",
    town: "Населенный пункт",
    street: "Улица",
    house: "Дом",
    housing: "Корпус",
    building: "Строение",
    apartment: "Квартира/Офис",
    contactPerson: "Контактное лицо",
    fullName: "Полное имя",
    phone: "Контактный телефон",
    serviceEmail: "E-mail адрес служебной эл. почты",
    helpertextServiceEmail: "Данный адрес будет закреплен в договоре для направления уведомлений или иной коммуникации по электронной почте",
    personWithRightToSign: "Лицо, имеющее право подписи без доверенности",
    position: "Должность",
    individual: "Физическое лицо",
    legal: "Юридическое лицо",
    fullNameFounder: "Полное наименование организации-Учредителя",
    hasMoreThenFiftyPercent: "Организация учредитель владеет 50% и более Уставного капитала Организации",
    yes: "Да",
    no: "Нет",
    directorName: "Директор Организации-Учредителя (Ф.И.О.)",
    organizationFounder: "Учредитель данной организации",
    basisNonPayment: "Основание для отсутствия платежей НДС",
    biggestTaxpayerKpp: "КПП крупнейшего налогоплательщика",
    bankCodeHint: "Введите БИК банка для платежей. Остальное заполнится автоматически.",
    currentAccountName: "Наименование владельца расчетного счета",
    currentAccount: "Расчетный счет №",
    correspondentAccount: "Корреспондентский счет №",
    bankFullName: "Полное наименование учреждения банка",
    bankLocation: "Местонахождение учреждения",
    buttonForDeclaration: "Декларация отсутствует",
    taxPeriod: "Отчетный налоговый период предоставления декларации НДС",
    yearSubmitted: "Год предоставления декларации НДС",
    noDeclarationHelper: "Предоставление декларации НДС является обязательным требованием.\n\rВ поле 'Комментарий' опишите, пожалуйста, причину не предоставления.\n\rМы проверим полный пакет предоставленной вами информации и вернемся с комментариями",
    comment: "Комментарий",
    signerNameColumn: "Подписант",
    signerName: "Полное имя подписанта",
    attorneyLetterAct: "Действует на основании",
    attorneyLetterNumber: "№ доверенности",
    validUntil: "Действует до",
    issueDate: "Дата выдачи",
    validFrom: "Действует с",
    file: "Файл",
    emailForLogin: "Email",
    language: "Язык",
    currentPassword: "Текущий пароль",
    newPassword: "Новый пароль",
    confirmNewPassword: "Подтвердить новый пароль",
    gender: "Пол",
    male: "Мужской",
    female: "Женский",
    egrip: "ЕГРИП",
    egripDate: "Дата регистрации в ЕГРИП",
    supplier: "Поставщик",
    isActive: "Активный",
    namePointOfSale: "Наименование торговой точки",
    includedInInformationLetter: "Включить в информационное письмо",
    addressPointOfSale: "Полный адрес торговой точки",
    legalName: "Юридическое наименование",
    nameDC: "Наименование РЦ",
    helperTextDCFullAddress: "Полный адрес, включая индекс",
    dcOwnedBy: "Распределительный центр Исполнителя",
    dcControlledBy: "Распределительный центр, контролируемый Исполнителем",
    document: "Документ",
    distributionCenter: "Распределительный центр",
    nameFounder: "Учредитель (Ф. И. О и / или наименование организации)" ,
    nameFounderIndividual: "Учредитель (Ф. И. О)" ,
    nameFounderOrganization: "Наименование организации" ,
    sent: "Отправлено",
    posInn: "ИНН Торговой точки"
  },
  formSuppliers: {
    tabs: {
      clientCard: "Карточка клиента",
      constituentDocs: "Учредительные документы",
      contactPersons: "Контактные лица",
      powerOfAttorney: "Подписанты",
      informationLetter: "Информационное письмо"
    },
    titles: {
      mainInfoSupplier: "Основная информация о поставщике",
      foundersOfTheVendor: "Учредители поставщика",
      vendorTaxation: "Налогообложение поставщика",
      bankDetails: "Банковские реквизиты поставщика",
      legalAddress: "Редактирование юридического адреса",
    },
  },
  createSupplierModal: {
    title: "Создать нового поставщика"
  },
  personWithRightToSignModal: {
    title: "Редактирование лица, имеющего право подписи без доверенности"
  },
  constituentDocModal: {
    titleConstituentDoc: "Добавить учредительный документ",
    titleDeclaration: "Редактирование декларации",
    downloadExampleFile: "Скачать пример документа",
    downloadHelper: "Загрузите документ в удобном формате. \n\rРекомендуемый размер загружаемого файла: 20 Мб",
  },
  attorneyLettersModal: {
    titleCreate: "Создание подписанта",
    titleEdit: "Редактирование подписанта",
    organizationSubtitle: "Договор об оказании услуг планируется к заключению между ООО «ФМСМ» и",
    nameSubtitle: "в лице (в родительном падеже)",
    commentHint: "В поле \"Комментарий\" укажите дополнительную информацию, в таком случае\n«В лице Управляющего индивидуального предпринимателя Иванова Ивана Ивановича, действующего на основании Устава и Договора о передаче полномочий единоличного исполнительного органа общества с ограниченной ответственностью \"Ромашка\" управляющему индивидуальному предпринимателю от \"01\" марта 2021 года».",
    helperName: "Родительный падеж",
    helperSignerName: "Именительный падеж"
  },
  attorneyLettersConfirmationModal: {
    title: "Проверьте данные",
    content: "Пожалуйста, убедитесь что Фамилия, Имя и Отчество заполнены в Родительном (Кого?) Падеже."
  },
  infoLetterModal: {
    title: "ИНФОРМАЦИОННОЕ ПИСЬМО",
    mainText: "Настоящим письмом уведомляем ООО \"Филип Моррис Сэйлз энд Маркетинг\", что %{organizationName} (далее – «Исполнитель») обладает всеми законными полномочиями для выполнения своих обязательств в отношении Услуг, оказываемых на основании договора с ООО \"Филип Моррис Сэйлз энд Маркетинг\" в нижеперечисленных торговых точках\n",
    responseByPointsOfSaleLabel: "%{organizationName} осуществляет руководство нижеперечисленными торговыми точками?",
    responseByDistributionCenterLabel: "Табачная и/или Никотинсодержащая продукция (в значении, определяемом в договоре между Исполнителем и \nООО \"Филип Моррис Сэйлз энд Маркетинг\") закупается централизованно с использованием Распределительного Центра?",
    typeOfDistributionCenterLabel: "Распределительный центр Исполнителя или контролируемый Исполнителем?",
    dateQuestion: "На первое число какого месяца указанный список торговых точек имеет силу?",
    disclaimerLabel: "ИНН Торговых точек проверены и подтверждены"
  },
  registeredInRussia: "Организация-Учредитель зарегестрирована в РФ",
  innNotFound: "Действующей записи в ЕГРЮЛ не найдено. Пожалуйста, проверьте правильность ввода.",
  founders_of_the_vendor: {
    modal: {
      title: "Информация об учредителе поставщика",
      organization_head: "Директор данной организации",
    }
  },
  confirmationVerifyModal: {
    title: "Проверьте данные",
    content: "Пожалуйста, подтвердите что Учредительные Документы не требуют обновления и Доверенности находятся в актуальном состоянии"
  },
  consentModal: {
    title: "Добро пожаловать на R.Ice - Портал Поставщика",
    consentSubtitle: "Согласие на обработку и хранение предоставленных персональных данных:",
    passwordSubtitle: "Пожалуйста, задайте новый пароль:",
    acceptLabelCheckbox: "Принять согласие на обработку и хранение персональных данных",
    passwordPolicy: {
      title: "Пароль должен:",
      list0: "содержать как минимум 9 символов",
      list1: "включать, как строчные, так и прописные буквы",
      list2: "содержать как минимум 1 букву",
      list3: "содержать как минимум 1 цифру",
      list4: "не содержать имени или учётной записи",
      list5: "не совпадать с паролем, который был в течение последних 365 дней",
      list6: "пароль должен меняться каждые 90 дня(-ей)",
    }
  },
  pointsOfSale: {
    subtitle: "Указать юридическое лицо / ИП, если торговая точка не является обособленным подразделением Исполнителя",
    addressModalTitle: "Редактирование адреса торговой точки",
    emptyList: "В данный момент список пуст",
    tableTitle: "Список Торговых Точек для Информационного письма"
  },
  confirmModalUpdatePointsOfSale: {
    title: "Подтвердить изменение торговых точек",
    content: "Подтвердите изменение торговых точек:"
  },
  confirmModalUpdatePointOfSale: {
    title: "Предупреждение",
    content: "Подтвердите изменение торговой точки: "
  },
  changePassword: {
    title: "Сменить пароль",
  },
  login: {
    title: "Вход",
    warning: {
      accountLocked: "Аккаунт временно заблокирован. Попробуйте осуществить вход позднее",
      badCredentials: "Неверный логин и/или пароль",
      passwordExpired: "Ваш пароль истек. Пожалуйста, восстановите пароль"
    }
  },
  dropZone: {
    dropzoneText: "Загрузите файл",
    dropzoneEditText: "Изменить файл",
    loadedFile: "Загруженный файл",
    chosenFile: "Выбранный файл",
    statusRejected: "Файл не выбран",
    statusConfirm: "Файл загружен",
  },
  buttons: {
    ok: "ОК",
    create: "Создать",
    add: "Добавить",
    clone: "Дублировать",
    deleteSupplier: "Удалить Поставщика",
    provide: "Предоставить",
    accept: "Принять",
    cancel: "Отменить",
    save: "Сохранить",
    send: "Отправить",
    back: "Назад",
    provideAttorneyLetters: "Предоставить подписантов",
    comeBack: "Вернуться",
    refreshData: "Обновить данные",
    editData: "Редактировать",
    next: "Далее",
    sendLetter: "Сформировать письмо",
    download: "Скачать",
    confirm: "Подтвердить",
    sendInfoletter: "Отправить письмо",
    saveChanges: "Сохранить изменения",
    sendPOSes: "Уведомить ФМСМ об изменениях",
    exportPOSToExcel: "Выгрузить в Excel",
    editInfoLetter: "Редактировать информационное письмо",
    newInfoLetter: "Новое информационное письмо",
    confirmData: "Подтвердить",
    confirmChanges: "Подтвердить изменения",
    pointOfSalesForConfirmation: "Торговые точки для утверждения",
    confirmChangesAndSave: "Подтвердить изменения и сохранить"
  },
  errors: {
    length: "Ожидаемое количество символов:",
    inn: "Количество цифр для Юридического лица: %{legalLength}\n\rКоличество цифр для Индивидуального предпринимателя: %{individualLength}",
    currentAccountNumberLegal: "Расчетный счет Юридического лица должен начинаться с 40702 или 40703...",
    currentAccountNumberIndividual: "Расчетный счет Индивидуального предпринимателя должен начинаться с 40802...",
    phoneLength: "Телефон должен содержать 11 цифр",
    addRequiredDocs: "Необходимо добавить обязательные учредительные документы",
    addRequiredPowerOfAttorney : "Необходимо добавить доверенность",
    passwordMatch: "Пароли не совпадают",
    invalidDate: "Неверная дата",
    inactiveSupplier: "Поставщик неактивен",
    errorDuringInformationLetterCreation: "Ошибка при создании информационного письма",
    errorDuringPOSExport: "Ошибка при экспорте. Возможно у пользователя отсутствуют торговые точки",
    errorToken: "Время сессии истекло. Обновите вход на портал.",
    unknownError: "Неизвестная ошибка"
  },
  notify: {
    dataSaved: "Изменения сохранены",
    dataSent: "Данные были отправлены",
    passwordSaved: "Пароль успешно обновлен",
    dataProvided: "Данные предоставлены",
    elementCreated: "Запись успешно создана",
    dataSavedAndSent: "Данные были сохранены и отправлены"
  },
  passwordRecovery: "Восстановление пароля",
  duplicateNotifyModal: {
    title: "Дубликат",
    content: "Внимание! Поставщик с ИНН %{inn} уже был ранее создан"
  },
  help: {
    titleUL: "Раздел для Юридических лиц",
    titleIndividual: "Раздел для Индивидуальных предпринимателей",
    videoTitle: "Видео инструкция по заполнению данных на Портале поставщика",
    instructionTitle: "Инструкция по заполнению данных на Портале поставщика",
    instructionSubtitle: "Инструкция для поставщиков"
  },
  footer: {
    text: "УВАЖАЕМЫЙ ПАРТНЁР," +
      " пожалуйста, ознакомьтесь с сообщением от Артёма Кривцова, Управляющего директора аффилированных компаний" +
      " «Филип Моррис Интернэшнл» в России, о защите несовершеннолетних от воздействия табака и никотина. ",
    hrefName: "Ознакомиться"
  }
};

export default customRussianMessages;