import * as React from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { FormControl, FormHelperText, MenuItem, Select, Typography } from '@material-ui/core'
import { typeOfDCOptions, yesNoOptionsBoolean } from '../../../../../../../../helpers/enums'
import DistributionCenterField from '../DistributionCenterField'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { makeStyles } from '@material-ui/core/styles'
import { ru, enGB } from 'date-fns/locale'

const useStyles = makeStyles((theme) => ({
  text: {
    display: 'block',
    marginBottom: theme.spacing(2),
    padding: '0 12px'
  },
  title: {
    fontWeight: 700
  },
  textForm: {
    display: 'block',
    marginBottom: theme.spacing(2),
  },
  form: {
    padding: '0 12px'
  },
  select: {
    width: '100%',
    '& > div': {
      width: '100%',
      // paddingTop: 18
    }
  },
  input: {
    width: '100%',
    minWidth: 300,
    maxWidth: 420,
    '& div': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  },
  date: {
    width: '100%',
    minWidth: 300,
    maxWidth: 420,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  },
  fieldsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    columnGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
      '& .MuiTextField-root': {
        maxWidth: 'unset'
      }
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      rowGap: theme.spacing(2),
    }
  },
  formControl: {
    justifySelf: 'end',
    width: '100%',
    minWidth: 300,
    maxWidth: 420,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  }
}))

const InfoLetterForm = ({
                          form,
                          isDisabledFields,
                          onBlur,
                          onChange,
                          errors,
                          distributionCenters,
                          getDistributionCenters,
                          touched
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const language = useSelector(state => state.customReducer.appLanguage)
  const featureFlags = useSelector(state => state.customReducer.featureFlags)
  const { values } = useFormState()

  return (
    <>
      <Typography className={`${classes.text} ${classes.title}`} variant="subtitle1">
        {translate('infoLetterModal.title')}
      </Typography>
      <Typography className={classes.text} variant="h8">
        {translate('infoLetterModal.mainText', { organizationName: values.client?.organizationName })}
      </Typography>
      <div className={classes.form}>
        <Typography className={classes.textForm} variant="h8">
          {translate('infoLetterModal.responseByPointsOfSaleLabel', { organizationName: values.client?.organizationName })}
        </Typography>
        <FormControl
          className={classes.formControl}
          required
          disabled={isDisabledFields}
        >
          <Select
            className={classes.select}
            variant="filled"
            value={form.responseByPointsOfSale}
            required
            onChange={onChange('responseByPointsOfSale')}
            disabled={isDisabledFields}
          >
            {yesNoOptionsBoolean.map(option => (
              <MenuItem value={option.value}>{translate(option.name)}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <Typography className={classes.textForm} variant="h8">
          {translate('infoLetterModal.responseByDistributionCenterLabel')}
        </Typography>
        <FormControl
          className={classes.formControl}
          required
          disabled={isDisabledFields}
        >
          <Select
            className={classes.select}
            variant="filled"
            value={form.responseByDistributionCenter}
            required
            onChange={onChange('responseByDistributionCenter')}
            onBlur={onBlur('responseByDistributionCenter')}
            disabled={isDisabledFields}
          >
            {yesNoOptionsBoolean.map(option => (
              <MenuItem value={option.value}>{translate(option.name)}</MenuItem>
            ))}
          </Select>
          {(touched.responseByDistributionCenter &&(form.responseByDistributionCenter==null)) && (
            <FormHelperText>{translate(errors.responseByDistributionCenter)}</FormHelperText>
          )}
        </FormControl>
        <Typography className={classes.textForm} variant="h8">
          {translate('infoLetterModal.typeOfDistributionCenterLabel')}
        </Typography>
        <FormControl
          className={classes.formControl}
          required
          error={form.responseByDistributionCenter ? errors.typeOfDistributionCenter: ''}
          disabled={isDisabledFields}
        >
          <Select
            className={classes.select}
            variant="filled"
            value={form.responseByDistributionCenter ? form.typeOfDistributionCenter: null}
            required
            onChange={onChange('typeOfDistributionCenter')}
            onBlur={onBlur('typeOfDistributionCenter')}
            disabled={isDisabledFields || !form.responseByDistributionCenter}
          >
            {typeOfDCOptions.map(option => (
              <MenuItem value={option.value}>{translate(option.name)}</MenuItem>
            ))}
          </Select>
          {(touched.typeOfDistributionCenter && errors.typeOfDistributionCenter && form.responseByDistributionCenter) && (
            <FormHelperText>{translate(errors.typeOfDistributionCenter)}</FormHelperText>
          )}
        </FormControl>
        {(!featureFlags.features?.distributionCenters?.disabled) && (
        <DistributionCenterField
          required={form.responseByDistributionCenter}
          value={form.responseByDistributionCenter ? form.distributionCenter: null}
          onChange={onChange('distributionCenter')}
          onBlur={onBlur('distributionCenter')}
          error={form.responseByDistributionCenter ? errors.distributionCenter : ''}
          touched={touched.distributionCenter}
          isDisabledFields={isDisabledFields || !form.responseByDistributionCenter}
          distributionCenters={distributionCenters}
          onGetDistributionCenters={getDistributionCenters}
        />
        )}
        <Typography className={classes.textForm} variant="h8">
          {translate('infoLetterModal.dateQuestion')}
        </Typography>
        <MuiPickersUtilsProvider 
          locale={language ==='ru' ? ru : enGB}
          utils={DateFnsUtils}
          required
        >
          <KeyboardDatePicker
            className={classes.date}
            label={translate(`fields.validFrom`)}
            inputVariant="filled"
            format="dd.MM.yyyy"
            value={form.validFrom ?? null}
            onChange={onChange('validFrom')}
            onBlur={onBlur('validFrom')}
            error={errors.validFrom}
            helperText={touched.validFrom && translate(errors.validFrom)}
            disabled={isDisabledFields}
            views={["year", "month"]}
            required
          />
        </MuiPickersUtilsProvider>
      </div>
    </>
  )
}

export default InfoLetterForm