import * as React from "react";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  List,
  Datagrid,
  TextField,
  useTranslate,
  CreateButton,
  TopToolbar,
  useListContext
} from "react-admin";
import Filters from "../components/Filters";
import Pagination from "../../../components/Pagination";
import BulkActionButtons from "../components/BulkActionButtons";
import { makeStyles } from "@material-ui/core/styles";
import Empty from "../components/Empty";
import { toggleModalCreatorSupplier } from "../../../reducer/actions";
import { stylesList } from "../../../helpers/enums";

const useStyles = makeStyles(stylesList);

const ListActions = props => {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total
  } = useListContext();

  const dispatch = useDispatch();

  const handleCreate = useCallback(e => {
    e.preventDefault();
    dispatch(toggleModalCreatorSupplier(true));
  }, []);

  return (
    <TopToolbar {...props} id="list-actions-light">
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button"
        })}
      <CreateButton basePath={basePath} onClick={handleCreate} />
    </TopToolbar>
  );
};

const ProvidersList = props => {
  const classes = useStyles();
  const translate = useTranslate();
  const tokenData = localStorage.getItem("tokenData");
  if (!tokenData) {
    return <>Unauthorized</>;
  }

  return (
    <List
      title={translate("menuItem.suppliers")}
      {...props}
      actions={<ListActions />}
      bulkActionButtons={<BulkActionButtons />}
      filters={<Filters />}
      pagination={<Pagination />}
      classes={{
        root: classes.root,
        bulkActionsDisplayed: classes.bulkActionsDisplayed
      }}
      empty={<Empty />}
      hasCreate={true}
      perPage={25}
    >
      <Datagrid rowClick="edit">
        <TextField
          label={translate("fields.organizationType")}
          source="organizationType.name"
        />
        <TextField
          label={translate("fields.organizationName")}
          source="client.organizationName"
        />
        <TextField
          label={translate("fields.organizationFullName")}
          source="client.organizationFullName"
        />
        <TextField label={translate("fields.email")} source="client.email" />
        <TextField label={translate("fields.inn")} source="client.inn" />
        <TextField
          label={translate("fields.initiator")}
          source="client.initiator.name"
        />
        <TextField
          label={translate("fields.status")}
          source="client.providerStatus.name"
        />
        <TextField
          label={translate("fields.presentationDate")}
          source="client.presentationDate"
        />
        <TextField
          label={translate("fields.tradeAccount")}
          source="client.tradeAccount.name"
        />
      </Datagrid>
    </List>
  );
};

export default ProvidersList;
