import * as React from 'react'
import { useCallback } from 'react'
import { Button, Tooltip, useMediaQuery, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  actionButton: {
    padding: '4px 5px',
    fontSize: '0.8125rem',
    marginLeft: '0.5rem',
    color: ({ color }) => color === 'red' ? '#f44336' : '#3f51b5',
    '&:hover': {
      backgroundColor: ({ color }) => color === 'red' ? 'rgba(244, 67, 54, 0.12)' : 'rgba(63, 81, 181, 0.04)',
    },
    '& svg': {
      width: 20,
      height: 20,
      marginRight: '0.5rem'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 48,
      padding: 12,
      borderRadius: '50%',
      '& > span > span': {
        display: 'none'
      },
      '& svg': {
        width: 24,
        height: 24,
        marginRight: 0
      },
    },
  }
}))

const CustomAction = ({ onAction, disabled, label, icon, color = 'blue', role = '' }) => {
  const classes = useStyles({ color })
  const theme = useTheme()
  const showTooltip = useMediaQuery(theme.breakpoints.down('xs'))

  const onClick = useCallback(e => {
    e?.stopPropagation()
    onAction()
  }, [onAction])

  return (
    <Tooltip title={showTooltip ? label : ''}>
      <Button
        className={classes.actionButton}
        disabled={disabled}
        onClick={onClick}
        variant="text"
        role={role}
      >
        {icon}
        <span>
          {label}
        </span>
      </Button>
    </Tooltip>
  )
}

export default CustomAction