import * as React from 'react'
import {useState, useCallback, useEffect} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useTranslate, email as validationEmail, Notification, useNotify } from 'react-admin'
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles'
import {
  Button, CardActions, CircularProgress, FormLabel, TextField
} from '@material-ui/core'
import { recoveryPassword } from '../../api/customApi'
import LoginPageWrapper from '../../components/LoginPageWrapper'
import { theme } from '../../layouts/Layout'

const useStyles = makeStyles({
  form: {
    padding: '0 30px 16px',
  },
  input: {
    width: '100%',
    '& input': {
      boxSizing: 'border-box',
      height: 46
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0
    }
  },
  label: {
    display: 'block',
    marginBottom: 6,
    fontSize: 12,
    fontWeight: 400,
    '&:not(.Mui-error)': {
      color: '#000 !important'
    },
    '&.Mui-error': {
      color: '#f44336 !important'
    }
  },
  actions: {
    padding: '0 30px 30px',
  },
  button: {
    height: 42
  },
})

const RecoveryPassword = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()

  const loginEmail = useSelector(state => state.customReducer.loginEmail)

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [error, setError] = useState('ra.validation.required')
  const [blurred, setBlurred] = useState(false)

  useEffect(() => {
    onChange({
      target: {
        value: loginEmail
      }
    })
  }, [loginEmail])

  const onChange = useCallback((e) => {
    const value = e.target.value
    const isValidEmail = validationEmail()(value)?.message

    if (!value) {
      setError('ra.validation.required')
    } else if (isValidEmail) {
      setError(isValidEmail)
    } else {
      setError('')
    }
    setEmail(value)
  }, [])

  const onBlur = useCallback(() => {
    setBlurred(true)
  }, [])

  const onSend = useCallback(async () => {
    onBlur()
    if (!error) {
      setLoading(true)
      try {
        const res = await recoveryPassword(email)

        if (res.errorMessage) {
          notify(res.errorMessage ?? res.error,'warning')
        } else {
          notify(translate('notify.dataSent'))
        }
      } catch (err) {
        console.log(err)
      }
      setLoading(false)
    }
  }, [error, email])

  return (
    <LoginPageWrapper
      title={translate('passwordRecovery')}
      minHeight={600}
    >
      <div className={classes.form}>
        <FormLabel
          className={classes.label}
          error={error}
        >
          {translate('fields.emailForLogin')}
        </FormLabel>
        <TextField
          className={classes.input}
          label=""
          type="email"
          value={email}
          onChange={onChange}
          onBlur={onBlur}
          autoFocus
          variant="outlined"
          disabled={loading}
          required
          error={blurred && error}
          helperText={blurred && translate(error)}
        />
      </div>
      <CardActions className={classes.actions}>
        <Button
          className={classes.button}
          variant='outlined'
          component={Link}
          to='/login'
          fullWidth
        >
          {translate('buttons.back')}
        </Button>
        <Button
          className={classes.button}
          variant='contained'
          type='submit'
          color='primary'
          disabled={loading}
          fullWidth
          onClick={onSend}
          startIcon={loading && (
            <CircularProgress
              size={25}
              thickness={2}
            />
          )}
        >
          {translate('buttons.send')}
        </Button>
      </CardActions>
      <Notification />
    </LoginPageWrapper>
  )
}

const RecoveryPasswordWithTheme = (props) => (
  <ThemeProvider theme={createTheme(theme)}>
    <RecoveryPassword{...props} />
  </ThemeProvider>
)

export default RecoveryPasswordWithTheme