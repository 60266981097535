import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useFormState } from 'react-final-form'
import { AutocompleteInput, ReferenceInput, required, useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import {setUpdateOnePointOfSaleData} from "../../../../reducer/actions";

const useStyles = makeStyles((theme) => ({
  supplier: {
    width: '100%'
  }
}))

const SupplierField = ({
                         meta: {touched, error} = {touched: false, error: undefined},
                         input: {...inputProps},
                         ...props
                       }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const { values } = useFormState()

  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const constants = useSelector(state => state.customReducer.constants)

  const [availableStatuses, setAvailableStatuses] = useState([])

  useEffect(() => {
    inputProps.onChange({
      target: {
        value: {
          id: values.supplier?.id ?? null
        }
      }
    })
  }, [values.includedInInformationLetter])

  useEffect(() => {
    if (constants.DRAFT_PROVIDER_STATUS_I && providerStatuses.length) {
      const newAvailableStatuses = []
      providerStatuses.forEach(status => {
        if (status.identifier !== constants.DRAFT_PROVIDER_STATUS_I) {
          newAvailableStatuses.push(status.identifier)
        }
      })

      setAvailableStatuses(newAvailableStatuses)
    }
  }, [providerStatuses, constants])

  return (
    availableStatuses.length ? <ReferenceInput
      className={classes.supplier}
      source="supplier.id"
      label={translate('fields.supplier')}
      reference="suppliers"
      filterToQuery={(searchText) => ({
        notConvert: true,
        filters: [{
          group: "AND",
          conditions: [
            {
              property: 'client.organizationName',
              operator: 'contains',
              value: searchText
            },
            {
              group: "OR",
              conditions: availableStatuses.map(identifier => ({
                property: 'client.providerStatus.identifier',
                operator: '=',
                value: identifier
              }))
            }
          ]
        }]
      })}
      validate={values.includedInInformationLetter? required() : []}
      allowEmpty={true}
      onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}
      onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
    >
      <AutocompleteInput
        optionText="client.organizationName"
        helperText=""
      />
    </ReferenceInput> : ''
  )
}

export default SupplierField