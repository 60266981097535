import * as React from 'react'
import {
  Card, CardHeader
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    minHeight: ({ minHeight }) => minHeight,
    alignItems: 'center',
    background: '#EFF6FC',
  },
  card: {
    width: 360,
    marginTop: 'calc(16vh + 80px)',
    ['@media (max-height:800px)']: {
      marginTop: 'calc(10vh + 80px)',
    }
  },
  cardHeader: {
    padding: '24px 30px 16px',
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 500,
    }
  },
  projectNameWrapper: {
    position: 'absolute',
    top: '11vh',
    color: '#1565C0',
    textAlign: 'center',
    ['@media (max-height:800px)']: {
      top: '5vh',
    }
  },
  projectNameWrapper_title: {
    margin: 0,
    fontSize: 40,
    fontWeight: 700,
  },
  projectNameWrapper_subtitle: {
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
  },
  footer: {
    position: 'absolute',
    bottom: '11vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    ['@media (max-height:800px)']: {
      bottom: '6vh',
    }
  },
  footer_logo: {
    display: 'block',
    marginBottom: 24
  },
  footer_text: {
    margin: 0,
    fontSize: 10,
    fontWeight: 500,
    color: '#1565C0',
    letterSpacing: '0.46px',
    textTransform: 'uppercase'
  }
}))

const LoginPageWrapper = ({ children, title, minHeight = 700 }) => {
  const classes = useStyles({ minHeight })
  return (
    <div className={classes.main}>
      <div className={classes.projectNameWrapper}>
        <p className={classes.projectNameWrapper_title}>
          R.ICE
        </p>
        <p className={classes.projectNameWrapper_subtitle}>
          Портал поставщика
        </p>
      </div>
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          title={title}
        />
        {children}
      </Card>
      <footer className={classes.footer}>
        <img
          className={classes.footer_logo}
          src="PMI-logo.png"
          alt="PMI logo"
        />
        <p className={classes.footer_text}>
          OOO «Филип Моррис Сэйлз энд Маркетинг».
        </p>
      </footer>
    </div>
  )
}

export default LoginPageWrapper