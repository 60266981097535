import * as React from 'react'
import { Route } from 'react-router-dom'
import Settings from './pages/Settings'
import RecoveryPassword from './pages/RecoveryPassword'
import ConsentPageWithTheme from "./pages/ConsentPage";
import Help from "./pages/HelpPage";

const routes = [
  <Route exact path='/settings' render={() => <Settings />} />,
  <Route exact path='/help' render={() => <Help />} />,
  <Route exact path='/recovery' render={() => <RecoveryPassword />} noLayout />,
  <Route exact path='/password/:token' render={(props) => <ConsentPageWithTheme {...props} />} noLayout />,
]

export default routes