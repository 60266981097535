import * as React from 'react';
import {AppBar} from 'react-admin';
import {makeStyles} from "@material-ui/core/styles";
import {Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    marginRight: 0,
    marginLeft: 'auto'
  },
  phone:{
    color: 'white',
    textDecoration: 'none'
  }
}))

const VpAppBar = (props) => {
  const classes = useStyles()
  return (<AppBar
      {...props}
    >
      <Typography variant="h6" id="react-admin-title" />
      <div className={classes.column}>
        <a className={classes.phone} href="tel:8 800 600 87 74">☎ 8 800 600 87 74</a>
      </div>
    </AppBar>
  )
};

export default VpAppBar;