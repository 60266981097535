import * as React from 'react'
import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import SaveIcon from '@material-ui/icons/Save'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { Title, useSetLocale, useTranslate, useNotify } from 'react-admin'
import Select from './components/Select'
import PasswordPolicy from '../../components/PasswordPolicy'
import { languageOptions } from '../../helpers/enums'
import { validatePassword } from '../../helpers/utils'
import { updatePassword } from '../../api/customApi'
import { setAppLanguage, setCurrentUser } from '../../reducer/actions'

const useStyles = makeStyles((theme) => ({
  card: {
    position: 'relative',
    paddingBottom: `calc(${theme.spacing(3) * 2 + 36}px)`
  },
  cardContentSettings: {
    padding: `${theme.spacing(1)}px 32px ${theme.spacing(3)}px`,
  },
  cardContentPassword: {
    display: 'grid',
    flex: '1 1 auto',
    gridTemplateColumns: 'repeat(2, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(1),
    gridRowGap: theme.spacing(3),
    padding: `${theme.spacing(1)}px 32px ${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
    }
  },
  title: {
    padding: '25px 32px'
  },
  textField: {
    width: '100%',
  },
  cardActions: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    padding: '25px 32px',
    backgroundColor: '#f5f5f5'
  },
  reset: {
    gridColumn: '1 / 3',
    width: 'max-content',
    margin: `${theme.spacing(1)}px 0 0`,
    background: '#e57373',
    '&:hover, &:active': {
      background: '#f44336',
    },
    [theme.breakpoints.down('md')]: {
      gridColumn: '1 / 2',
    }
  },
  timeSelect: {
    gridArea: 'timeSelect'
  },
  timeCheck: {
    gridArea: 'timeCheck'
  },
  passwordPolicyWrapper: {
    gridColumn: '1 / 3',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 2',
    }
  }
}))

const Settings = () => {
  const classes = useStyles()
  const setLocale = useSetLocale()
  const translate = useTranslate()
  const notify = useNotify()
  const dispatch = useDispatch()

  const currentUser = useSelector(state => state.customReducer.currentUser)
  const language = useSelector(state => state.customReducer.appLanguage)

  const [password, setPassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  })
  const [focused, setFocused] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false
  })
  const [touched, setTouched] = useState({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false
  })
  const [errors, setErrors] = useState({
    currentPassword: 'ra.validation.required',
    newPassword: 'ra.validation.required',
    confirmNewPassword: 'ra.validation.required'
  })
  const [loading, setLoading] = useState(false)

  const validate = useCallback((newPassword) => {
    const newErrors = validatePassword(newPassword, errors, translate)
    setErrors(newErrors)
  }, [errors, password])

  const onChangePassword = useCallback((field) => (e) => {
    const newPassword = {
      ...password,
      [field]: e.target.value
    }
    validate(newPassword)
    setPassword(newPassword)
  }, [password])

  const onBlur = useCallback(field => e => {
    setFocused({
      ...focused,
      [field]: false
    })
    setTouched({
      ...touched,
      [field]: true
    })
  }, [touched, focused])

  const onFocus = useCallback(field => e => {
    setFocused({
      ...focused,
      [field]: true
    })
  }, [focused])

  const onSavePassword = useCallback(async () => {
    if (errors.currentPassword || errors.newPassword || errors.confirmNewPassword) {
      setTouched({
        currentPassword: true,
        newPassword: true,
        confirmNewPassword: true
      })
      notify('ra.message.invalid_form', 'warning')
      return
    }
    setLoading(true)

    const res = await updatePassword({
      oldPassword: password.currentPassword,
      newPassword: password.newPassword,
      userId: currentUser.id
    })
    if (!res.errorMessage) {
      notify(translate('notify.passwordSaved'))
    } else {
      notify(res.errorMessage, 'warning')
    }

    setLoading(false)
  }, [errors, currentUser, password])

  const onChangeLanguage = useCallback(e => {
    dispatch(setAppLanguage(e.target.value))
    setLocale(e.target.value)
  }, [])

  return (
    <>
      <Title title={translate('menuItem.settings')} />
      <Card className={classes.card}>
        <CardHeader
          className={classes.title}
          title={translate('menuItem.settings')}
        />
        <CardContent className={classes.cardContentSettings}>
          <Select
            label={translate('fields.language')}
            value={language}
            variant="filled"
            onChange={onChangeLanguage}
            options={languageOptions}
          />
        </CardContent>
        <CardHeader
          className={classes.title}
          title={translate('changePassword.title')}
        />
        <CardContent className={classes.cardContentPassword}>
          <TextField
            className={classes.textField}
            label={translate('fields.currentPassword')}
            variant="filled"
            type="password"
            value={password.currentPassword}
            onChange={onChangePassword('currentPassword')}
            onBlur={onBlur('currentPassword')}
            onFocus={onFocus('currentPassword')}
            error={touched.currentPassword && errors.currentPassword}
            helperText={touched.currentPassword && translate(errors.currentPassword)}
          />
          <span />
          <TextField
            className={classes.textField}
            label={translate('fields.newPassword')}
            variant="filled"
            type="password"
            value={password.newPassword}
            onChange={onChangePassword('newPassword')}
            onBlur={onBlur('newPassword')}
            onFocus={onFocus('newPassword')}
            error={touched.newPassword && errors.newPassword}
            helperText={touched.newPassword && translate(errors.newPassword)}
          />
          <TextField
            className={classes.textField}
            label={translate('fields.confirmNewPassword')}
            variant="filled"
            type="password"
            value={password.confirmNewPassword}
            onChange={onChangePassword('confirmNewPassword')}
            onBlur={onBlur('confirmNewPassword')}
            onFocus={onFocus('confirmNewPassword')}
            error={touched.confirmNewPassword && errors.confirmNewPassword}
            helperText={touched.confirmNewPassword && translate(errors.confirmNewPassword)}
          />
          <div className={classes.passwordPolicyWrapper}>
            <PasswordPolicy
              isShowPasswordPolicy={focused.newPassword || focused.confirmNewPassword || focused.currentPassword}
            />
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Button
            variant="contained"
            color="primary"
            startIcon={loading ? <CircularProgress size={14} /> : <SaveIcon />}
            onClick={onSavePassword}
            disabled={loading}
          >
            {translate('buttons.save')}
          </Button>
        </CardActions>
      </Card>
    </>
  )
}

export default Settings