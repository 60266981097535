import * as React from "react";
import { useState, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNotify, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  FormHelperText
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import ModalBase from "../../../../../../../../components/ModalBase";
import InfoPopover from "../../../../../../../../components/InfoPopover";
import DropZone from "../../../../../../../../components/DropZone";
import {
  genderOptions,
  fieldsCharter,
  fieldsPowerofattorney,
  generalFields,
  fieldsDatePowerofattorney
} from "../../../../../../../../helpers/enums";
import {
  attorneyLetterDatePeriodValidation,
  getMaxDateAttorneyLetter,
  getMinDateAttorneyLetter
} from "../../../../../../../../helpers/utils";
import ConfirmationModal from "../ConfirmationModal";
import { ru, enGB } from "date-fns/locale";
import { postLogs } from "../../../../../../../../api/customApi";

const useStyles = makeStyles(theme => ({
  modalBox: {
    width: "100%"
  },
  formControl: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr"
    }
  },
  input: {
    width: "100%"
  },
  subtitle: {
    gridColumn: "1/3",
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1/2"
    }
  },
  organizationName: {
    gridColumn: "1/3",
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1/2"
    }
  },
  inputComment: {
    gridColumn: "1 / 3",
    "& > div": {
      alignItems: "flex-start"
    },
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1/2"
    }
  },
  hint: {
    marginTop: -17
  },
  dropZoneWrapper: {
    gridColumn: "1 / 3",
    [theme.breakpoints.down("sm")]: {
      gridColumn: "1/2"
    }
  },
  helperText: {
    margin: "2px 14px 0"
  }
}));

const Modal = ({ mode, toggle, data, selected, inputProps }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  const attorneyLetterActs = useSelector(
    state => state.customReducer.attorneyLetterActs
  );
  const attorneyLetterStatuses = useSelector(
    state => state.customReducer.attorneyLetterStatuses
  );
  const constants = useSelector(state => state.customReducer.constants);
  const language = useSelector(state => state.customReducer.appLanguage);
  const { values } = useFormState();

  const initModalValues = useMemo(
    () => ({
      attorneyLetterAct: attorneyLetterActs.find(
        item => item.identifier === constants?.POWER_OF_ATTORNEY_ACT_I
      ),
      lastName: "",
      firstName: "",
      middleName: "",
      signerName: "",
      position: "",
      gender: "",
      attorneyLetterNumber: "",
      validUntil: null,
      issueDate: null,
      validFrom: null,
      file: null,
      supplierComment: ""
    }),
    [attorneyLetterActs, values]
  );

  const initErrors = useMemo(
    () => ({
      lastName: "ra.validation.required",
      firstName: "ra.validation.required",
      signerName: "ra.validation.required",
      middleName: "ra.validation.maxLength",
      position: "ra.validation.maxLength",
      supplierComment: "ra.validation.maxLength",
      attorneyLetterNumber: "ra.validation.required",
      validUntil: "ra.validation.required",
      issueDate: "ra.validation.required",
      validFrom: "",
      file: "ra.validation.required",
      gender: "ra.validation.required"
    }),
    [values]
  );

  const [modalValues, setModalValues] = useState(initModalValues);
  const [blurred, setBlurred] = useState({
    lastName: false,
    firstName: false,
    signerName: false,
    attorneyLetterNumber: false,
    validUntil: false,
    issueDate: false,
    validFrom: false,
    file: false,
    gender: false,
    middleName: false,
    position: false,
    supplierComment: false
  });
  const [errors, setErrors] = useState(initErrors);
  const [isOpenConfirmation, setOpenConfirmation] = useState(false);

  const setAllBlurred = useCallback(value => {
    const blurredKeys = Object.keys(blurred);
    const newBlurred = {};
    blurredKeys.forEach(key => {
      newBlurred[key] = value;
    });
    setBlurred(newBlurred);
  }, []);

  const validate = useCallback(
    field => value => {
      const newErrors = { ...errors, [field]: "" };
      const fieldIsRequired = [
        ...fieldsPowerofattorney,
        ...fieldsCharter,
        ...generalFields
      ].find(item => item.name === field).required;

      if (!value && fieldIsRequired) {
        newErrors[field] = "ra.validation.required";
      } else if (value && value.length > 255) {
        newErrors[field] = translate("ra.validation.maxLength", { max: 255 });
      }

      setErrors(newErrors);
      return newErrors[field];
    },
    [errors]
  );

  useEffect(() => {
    if (mode === "edit") {
      const modalValues = { ...data[selected[0]] };

      modalValues.issueDate = modalValues.issueDate
        ? moment(modalValues.issueDate)
        : null;
      modalValues.validFrom = modalValues.validFrom
        ? moment(modalValues.validFrom)
        : null;
      modalValues.validUntil = modalValues.validUntil
        ? moment(modalValues.validUntil)
        : null;

      setModalValues(modalValues);
      setAllBlurred(true);

      const errorKeys = Object.keys(errors);
      const newErrors = {};
      errorKeys.forEach(key => {
        newErrors[key] = validate(key)(modalValues[key]);
      });
      setErrors(newErrors);
    }
  }, [mode, data, selected]);

  useEffect(() => {
    if (mode === "") {
      setAllBlurred(false);
      setModalValues(initModalValues);
      setErrors(initErrors);
    }
  }, [mode]);

  const handleAttorneyAct = useCallback(
    e => {
      postLogs("PowerOfAttorney/Modal",  "ALL", {
        message: `handleAttorneyAct start`,
        stack: "231 line"
      });
      const identifier = e.target.value;
      postLogs("PowerOfAttorney/Modal",  "ALL", {
        message: `identifier: ${identifier}`,
        stack: "236 line"
      });
      const attorneyLetterAct = attorneyLetterActs.find(
        item => item.identifier === identifier
      );
      const newModalValues = {
        ...modalValues,
        attorneyLetterAct: attorneyLetterAct
      };
      const newErrors = { ...errors };
      const fieldsForClear = [
        ...generalFields,
        ...fieldsPowerofattorney,
        ...fieldsCharter
      ];

      fieldsForClear.forEach(field => {
        switch (typeof newModalValues[field.name]) {
          case "object":
            newModalValues[field.name] = null;
            break;
          case "boolean":
            newModalValues[field.name] = false;
            break;
          default:
            newModalValues[field.name] = "";
        }
      });

      if (attorneyLetterAct.identifier === constants?.CHARTER_ACT_I) {
        newModalValues.lastName = values.client.person?.lastName ?? "";
        newModalValues.firstName = values.client.person?.firstName ?? "";
        newModalValues.middleName = values.client.person?.middleName ?? "";
        newModalValues.signerName =
          values.client.person?.lastName && values.client.person?.firstName
            ? `${values.client.person?.lastName} ${
                values.client.person?.firstName
              }${
                values.client.person?.middleName
                  ? ` ${values.client.person?.middleName}`
                  : ""
              }`
            : "";
        newModalValues.position = values.client.person?.position ?? "";
      }

      fieldsForClear.forEach(field => {
        if (field.required) {
          newErrors[field.name] = !newModalValues[field.name]
            ? "ra.validation.required"
            : "";
        }
      });

      setErrors(newErrors);
      setModalValues(newModalValues);
      postLogs("PowerOfAttorney/Modal",  "ALL", {
        message: `handleAttorneyAct end, newErrors: ${JSON.stringify(newErrors)}, newModalValues: ${JSON.stringify(newModalValues)}`,
        stack: "294 line"
      });
    },
    [modalValues, errors]
  );

  const validateDate = useCallback(
    (field, allValues) => {
      const fieldIsRequired = fieldsPowerofattorney.find(
        item => item.name === field
      ).required;
      let error = "";

      if (!allValues[field] && fieldIsRequired) {
        error = "ra.validation.required";
      } else if (allValues[field]?.toString() === "Invalid Date") {
        error = "errors.invalidDate";
      }
      if (error) {
        postLogs("PowerOfAttorney/Modal",  "ALL", {
          message: `validateDate error field: ${field} error: ${error}`,
          stack: "315 line"
        });
        return error;
      }
      return attorneyLetterDatePeriodValidation(field, allValues);
    },
    [modalValues]
  );

  const handleDate = useCallback(
    field => value => {
      const newValues = {
        ...modalValues,
        [field]: value
      };
      const newErrors = fieldsDatePowerofattorney.reduce(
        (sum, nextItem) => ({
          ...sum,
          [nextItem.name]: validateDate(nextItem.name, newValues)
        }),
        errors
      );

      setErrors(newErrors);
      setModalValues(newValues);
    },
    [modalValues, errors]
  );

  const handleFile = useCallback(
    newFileObjs => {
      postLogs("PowerOfAttorney/Modal", "ALL", {
        message: "handleFile start",
        stack: "348 line"
      });
      const files = newFileObjs.length ? newFileObjs : [];
      if (files.length) {
        validate("file")(files[0]);
        setModalValues({
          ...modalValues,
          file: files[0]
        });
        setBlurred(prev => ({
          ...prev,
          file: true
        }));
      } else if (modalValues.file?.path) {
        validate("file")(null);
        setModalValues({
          ...modalValues,
          file: null
        });
      }
      postLogs("PowerOfAttorney/Modal", "ALL", {
        message: "handleFile end",
        stack: "370 line"
      });
    },
    [modalValues, errors]
  );

  const onChange = useCallback(
    field => e => {
      const value = e.target.value;
      validate(field)(value);
      setModalValues({
        ...modalValues,
        [field]: value
      });
    },
    [modalValues]
  );

  const onBlur = useCallback(
    field => () => {
      setBlurred({
        ...blurred,
        [field]: true
      });
    },
    [blurred]
  );

  const checkValidation = useCallback(async () => {
    postLogs("PowerOfAttorney/Modal", "ALL", {
      message: "checkValidation start",
      stack: "400 line"
    });
    setAllBlurred(true);

    let isValid = true;
    const fields =
      modalValues.attorneyLetterAct?.identifier === constants?.CHARTER_ACT_I
        ? [...generalFields, ...fieldsCharter]
        : [...generalFields, ...fieldsPowerofattorney];
    fields.find(field => {
      isValid = field.required ? !errors[field.name] : true;
      return !isValid;
    });

    if (!isValid) {
      postLogs("PowerOfAttorney/Modal",  "ALL", {
        message: `checkValidation ra.message.invalid_form`,
        stack: "417 line"
      });
      notify("ra.message.invalid_form", "warning");
      return;
    }

    localStorage.getItem("lang") === "ru"
      ? toggleConfirmationModal()
      : await handleSave();

    postLogs("PowerOfAttorney/Modal", "ALL", {
      message: "checkValidation end",
      stack: "430 line"
    });
  }, [modalValues, errors, data, mode, constants]);

  const handleSave = useCallback(async () => {
    postLogs("PowerOfAttorney/Modal", "ALL", {
      message: "handleSave start",
      stack: "437 line"
    });
    const attorneyLetter = {};
    const attorneyLetters = [...values.attorneyLetters];
    const addedAttorneyLetterStatus = attorneyLetterStatuses.find(
      item => item.identifier === constants?.ADDED_ATTORNEY_STATUS_I
    );

    let file = null;
    if (modalValues.file?.path) {
      const arrayBuffer = await modalValues.file.arrayBuffer();
      const buffer = new Uint8Array(arrayBuffer);
      file = {
        file: buffer,
        name: modalValues.file.name,
        type: modalValues.file.type
      };
    }

    attorneyLetter.validUntil =
      modalValues.validUntil !== null
        ? moment(modalValues.validUntil)
            .format("YYYY-MM-DD")
            .toString()
        : null;
    attorneyLetter.issueDate =
      modalValues.issueDate !== null
        ? moment(modalValues.issueDate)
            .format("YYYY-MM-DD")
            .toString()
        : null;
    attorneyLetter.validFrom =
      modalValues.validFrom !== null
        ? moment(modalValues.validFrom)
            .format("YYYY-MM-DD")
            .toString()
        : null;
    attorneyLetter.status = addedAttorneyLetterStatus;

    if (mode === "create") {
      attorneyLetters.push({
        ...modalValues,
        ...attorneyLetter,
        file,
        supplier: {
          id: values.id
        }
      });
    }
    if (mode === "edit") {
      if (
        modalValues.attorneyLetterAct?.identifier ===
        constants?.POWER_OF_ATTORNEY_ACT_I
      ) {
        file = file ?? data[selected[0]].file;
      }

      attorneyLetters[selected[0]] = {
        ...modalValues,
        ...attorneyLetter,
        file
      };
    }

    inputProps.onChange({
      target: {
        value: attorneyLetters
      }
    });
    postLogs("PowerOfAttorney/Modal", "ALL", {
      message: `handleSave end attorneyLetter: ${JSON.stringify(attorneyLetter)}`,
      stack: "508 line"
    });
    toggle();
  }, [modalValues, errors, data, mode, constants]);

  const toggleConfirmationModal = useCallback(() => {
    setOpenConfirmation(prev => !prev);
  }, []);

  return (
    <>
      <ModalBase
        title={translate(
          `attorneyLettersModal.title${mode === "create" ? "Create" : "Edit"}`
        )}
        handleClose={toggle}
        isOpen={mode}
        contentClasses={classes.formControl}
        paperStyle={classes.modalBox}
        maxWidth="md"
        handleSave={checkValidation}
        actionName={mode === "create" ? "buttons.create" : "buttons.save"}
      >
        <FormControl>
          <InputLabel>{translate("fields.attorneyLetterAct")}</InputLabel>
          <Select
            variant="filled"
            value={modalValues.attorneyLetterAct?.identifier}
            onChange={handleAttorneyAct}
            disabled
          >
            {attorneyLetterActs.map(item => (
              <MenuItem value={item.identifier}>
                {translate(item.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Typography className={classes.subtitle} variant="h6">
          {translate("attorneyLettersModal.organizationSubtitle")}
        </Typography>
        <TextField
          className={`${classes.organizationName} ${classes.input}`}
          label={translate("fields.organizationFullName")}
          variant="filled"
          value={values.client?.organizationFullName ?? ""}
          disabled
        />
        <Typography className={classes.subtitle} variant="h6">
          {translate("attorneyLettersModal.nameSubtitle")}
        </Typography>
        {generalFields.map(field =>
          field.name !== "gender" ? (
            <div>
              <TextField
                className={classes.input}
                label={translate(`fields.${field.name}`)}
                variant="filled"
                required={field.required}
                onChange={onChange(field.name)}
                onBlur={onBlur(field.name)}
                value={modalValues[field.name] ?? ""}
                error={blurred[field.name] && errors[field.name]}
                helperText={
                  blurred[field.name] ? translate(errors[field.name]) : ""
                }
              />
              <Typography className={classes.helperText}>
                {field.name === "firstName" ||
                field.name === "lastName" ||
                field.name === "middleName"
                  ? translate("attorneyLettersModal.helperName")
                  : field.name === "signerName"
                  ? translate("attorneyLettersModal.helperSignerName")
                  : ""}
              </Typography>
            </div>
          ) : (
            <FormControl
              required={field.required}
              error={blurred[field.name] && errors[field.name]}
            >
              <InputLabel>{translate(`fields.${field.name}`)}</InputLabel>
              <Select
                className={classes.select}
                variant="filled"
                value={modalValues[field.name]}
                onChange={onChange(field.name)}
              >
                {genderOptions.map(item => (
                  <MenuItem value={item.value}>{translate(item.name)}</MenuItem>
                ))}
              </Select>
              {blurred[field.name] && errors[field.name] && (
                <FormHelperText>{translate(errors[field.name])}</FormHelperText>
              )}
            </FormControl>
          )
        )}
        {modalValues.attorneyLetterAct?.identifier ===
          constants?.POWER_OF_ATTORNEY_ACT_I && (
          <>
            <TextField
              className={classes.input}
              label={translate("fields.attorneyLetterNumber")}
              variant="filled"
              required
              onChange={onChange("attorneyLetterNumber")}
              onBlur={onBlur("attorneyLetterNumber")}
              value={modalValues.attorneyLetterNumber ?? ""}
              error={
                blurred.attorneyLetterNumber && errors.attorneyLetterNumber
              }
              helperText={
                blurred.attorneyLetterNumber &&
                translate(errors.attorneyLetterNumber)
              }
            />
            {fieldsDatePowerofattorney.map(field => (
              <MuiPickersUtilsProvider
                locale={language === "ru" ? ru : enGB}
                utils={DateFnsUtils}
              >
                <KeyboardDatePicker
                  className={classes.input}
                  label={translate(`fields.${field.name}`)}
                  inputVariant="filled"
                  format="dd.MM.yyyy"
                  required={field.required}
                  value={modalValues[field.name]}
                  onChange={handleDate(field.name)}
                  onBlur={onBlur(field.name)}
                  error={blurred[field.name] && errors[field.name]}
                  helperText={
                    blurred[field.name] && translate(errors[field.name])
                  }
                  maxDate={getMaxDateAttorneyLetter(field.name, modalValues)}
                  minDate={getMinDateAttorneyLetter(field.name, modalValues)}
                />
              </MuiPickersUtilsProvider>
            ))}
            <TextField
              className={classes.inputComment}
              label={translate("fields.comment")}
              value={modalValues.supplierComment ?? ""}
              onChange={onChange("supplierComment")}
              onBlur={onBlur("supplierComment")}
              error={blurred["supplierComment"] && errors["supplierComment"]}
              helperText={
                blurred["supplierComment"] &&
                translate(errors["supplierComment"])
              }
              multiline
              rows={5}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InfoPopover
                    id="attorneyLetterComment"
                    content={translate("attorneyLettersModal.commentHint")}
                    iconClass={classes.hint}
                  />
                )
              }}
            />
            <FormControl className={classes.dropZoneWrapper}>
              <DropZone
                file={modalValues.file?.path ? [modalValues.file] : []}
                valuesFile={
                  modalValues.file?.id || modalValues.file?.file
                    ? modalValues.file
                    : null
                }
                handleInput={handleFile}
                blurred={blurred.file}
                error={errors.file}
              />
            </FormControl>
          </>
        )}
      </ModalBase>
      <ConfirmationModal
        isOpen={isOpenConfirmation}
        toggle={toggleConfirmationModal}
        onSave={handleSave}
      />
    </>
  );
};

export default Modal;
