import * as React from 'react'
import {
  Datagrid, DatagridRow, DatagridBody,
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import InfoPopover from '../../../../../../../../components/InfoPopover'

const useStyles = makeStyles((theme) => ({
  docCell: {
    display: 'flex',
    alignItems: 'center'
  }
}))

const DocumentsDatagridRow = (props) => {
  const { record, resource, id, children, basePath } = props
  const classes = useStyles()
  return (
    <DatagridRow
      key={id}
      {...props}
      onClick={props.rowClick(record)}
    >
      {React.Children.map(children, field => (
          field.props.source === 'document' ?
            <span
              className={classes.docCell}
            >
              {record.document}
              {record.hintText && <InfoPopover id={record.id} content={record.hintText} />}
            </span> :
            React.cloneElement(field, {
              record,
              basePath,
              resource,
            })
      ))}
    </DatagridRow>
  )
}

const DocumentsDatagridBody = props => <DatagridBody {...props} row={<DocumentsDatagridRow />} />
const DocumentsDatagrid = props => <Datagrid {...props} body={<DocumentsDatagridBody />} />

export default DocumentsDatagrid