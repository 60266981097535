import * as React from 'react'
import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { List, MenuItem, ListItemIcon, Typography, Collapse, Tooltip } from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  icon: {
    minWidth: theme.spacing(5)
  },
  sidebarIsOpen: {
    paddingLeft: theme.spacing(2),
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms'
  }
}))

const SubMenu = ({
                   sidebarIsOpen,
                   name,
                   icon,
                   children,
                   dense,
                   path
                 }) => {
  const classes = useStyles()
  const history = useHistory()
  const [isOpen, setOpen] = useState(false)

  const handleToggle = useCallback(() => {
    setOpen(prev => !prev)
  }, [])

  useEffect(() => {
    if (new RegExp(`^${path}`).test(history.location.pathname)) {
      setOpen(true)
    }
  }, [history.location])

  const Header = () => (
    <MenuItem
      dense={dense}
      button
      onClick={handleToggle}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandLess /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  )

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        <Header />
      ) : (
        <Tooltip title={name} placement="right">
          <Header />
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen
              ? classes.sidebarIsOpen
              : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </>
  )
}

export default SubMenu