import React, { useEffect, useRef } from "react";
import { Title, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";
import { postLogs } from "../../api/customApi";

const ErrorBoundary = ({
  error,
  errorInfo,
  title,
  resetErrorBoundary,
  ...rest
}) => {
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);

  useEffect(() => {
    postLogs("ErrorBoundary", "ERROR", error);

    if (pathname !== originalPathname.current) {
      resetErrorBoundary();
    }
  }, [error]);

  const translate = useTranslate();
  return (
    <div>
      <Title title="Error" />

      <div>A client error occurred and your request couldn't be completed.</div>
      {process.env.NODE_ENV !== "production" && (
        <details>
          <h2>{translate(error.toString())}</h2>
          {errorInfo.componentStack}
        </details>
      )}
    </div>
  );
};

export default ErrorBoundary;
