import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate, TextInput, useNotify } from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ModalBase from '../../../ModalBase'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%',
  },
  content: {
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flex: 'unset',
      gridTemplateColumns: '1fr 1fr',
      gridColumnGap: theme.spacing(1),
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  legalAddress: {
    gridColumn: '3 / 4',
    gridRow: '1 / 3',
    [theme.breakpoints.down('sm')]: {
      gridRow: '1 / 2',
    },
    [theme.breakpoints.down('xs')]: {
      gridColumn: '1 / 2',
    }
  },
  input: {
    width: '100%',
    marginTop: 8,
    marginBottom: 4,
  }
}))

const Modal = ({
                 isOpen,
                 toggle,
                 inputProps,
                 setBlurredMain,
                 fieldsArray,
                 title,
                 fullAddressLabel,
                 disabledOnFilled,
                 isDisabledModalFields,
                 showErrorsWithoutBlur,
                 source
}) => {
  const { values, submitFailed, errors } = useFormState()

  const loading = useSelector(state => state.admin.loading)

  const [isAllDisabled, setAllDisabled] = useState(true)
  const [isValuesChecked, setValuesChecked] = useState(false)
  const [touched, setTouched] = useState(fieldsArray.reduce((sum, value) => {
    sum[value.name] = false
    return sum
  }, {}))

  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()

  useEffect(() => {
    if (!isValuesChecked) {
      let disabled = true
      if (disabledOnFilled) {
        fieldsArray.some(item => {
          if (!(_.get(values, `${source}.${item.name}`, '')) && item.required) {
            disabled = false
            return item
          }
        })
      } else {
        disabled = false
      }

      setAllDisabled(disabled)
      setValuesChecked(true)
    }
  }, [values, fieldsArray, isValuesChecked])

  const handleChange = useCallback((field) => (e) => {
    const obj = {
      ..._.get(values, source, {}),
      [field]: e.target.value
    }

    const filteredFields = fieldsArray.filter(item => (obj[item.name]))

    inputProps.onChange({
      target: {
        value: {
          ...obj,
          fullName: filteredFields.map(item => (`${obj[item.name]}${item.name === 'index' ? ', РФ': ''}`)).join(', ')
        }
      }
    })
  }, [values])

  const onBlur = useCallback((field) => () => {
    setTouched(prev => ({
      ...prev,
      [field]: true
    }))
  }, [])

  const blurAllFields = useCallback(() => {
    if (_.get(errors, source, '')) {
      setTouched(prev => {
        const prevKeys = Object.keys(prev)
        const newTouched = {}
        prevKeys.forEach(field => {
          newTouched[field] = true
        })
        return newTouched
      })
      setBlurredMain(true)
      notify('ra.message.invalid_form', 'warning')
    } else {
      toggle()
    }
  }, [errors])

  const onClose = useCallback(() => {
    setBlurredMain(true)
    toggle()
  }, [])

  return (
    <ModalBase
      title={title}
      handleClose={onClose}
      isOpen={isOpen}
      contentClasses={classes.content}
      paperStyle={classes.paperStyle}
      maxWidth="md"
      handleSave={blurAllFields}
    >
      <TextInput
        className={classes.legalAddress}
        label={fullAddressLabel}
        source={`${source}.fullName`}
        multiline
        rows={5}
        required
        disabled
        helperText=""
      />
      {fieldsArray.map(item => (
        <TextField
          key={item.name}
          className={classes.input}
          variant="filled"
          label={translate(`fields.${item.name}`)}
          value={_.get(values, `${source}.${item.name}`, '')}
          onChange={handleChange(item.name)}
          onBlur={onBlur(item.name)}
          required={item.required}
          helperText={
            (showErrorsWithoutBlur || submitFailed || touched[item.name]) &&
            translate(_.get(errors, `${source}.${item.name}`, ''))
          }
          error={
            (showErrorsWithoutBlur || submitFailed || touched[item.name]) &&
              _.get(errors, `${source}.${item.name}`, '')
          }
          disabled={isAllDisabled || loading !== 0 || isDisabledModalFields}
        />
      ))}
    </ModalBase>
  )
}

export default Modal