import * as React from 'react'
import { useState, useCallback } from 'react'
import { TextInput, required } from 'react-admin'
import { useFormState } from 'react-final-form'
import { FormControl, TextField, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Modal from './components/Modal'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  formControlLegalAddress: {
    display: 'flex',
    flexDirection: 'row',
    gridColumn: '1 / 2',
    paddingBottom: 4,
    paddingTop: 8,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 56px',
      maxWidth: 'calc(100vw - 96px)',
      '& > div': {
        width: '100%'
      }
    },
  },
  editBtn: {
    width: 48,
    height: 48,
    marginTop: 12,
    background: 'rgba(0, 0, 0, 0.04)',
    marginLeft: theme.spacing(2),
    gridColumn: '2 / 3',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.13)'
    }
  },
  hide: {
    display: 'none'
  },
  input: {
    width: '100%'
  }
}))

const validateRequired = [required()]

const LegalAddress = ({
                        inputProps,
                        modalTitle,
                        disabledOnFilled,
                        isDisabledModalFields,
                        showErrorsWithoutBlur,
                        fullAddressLabel,
                        fieldsArray,
                        source
}) => {
  const classes = useStyles()

  const { values, submitFailed, errors } = useFormState()

  const [isOpenModal, setOpenModal] = useState(false)
  const [isBlurred, setBlurred] = useState(false)

  const toggleModal = useCallback(() => {
    setOpenModal(prev => !prev)
  }, [showErrorsWithoutBlur])

  return (
    <>
      <FormControl className={classes.formControlLegalAddress}>
        <TextField
          className={classes.input}
          label={fullAddressLabel}
          variant="filled"
          disabled
          value={_.get(values, `${source}.fullName`, '')}
          helperText=""
          multiline
          required
          rows={5}
          error={(isBlurred || showErrorsWithoutBlur || submitFailed) && _.get(errors, source, '')}
        />
      </FormControl>
      <IconButton
        className={classes.editBtn}
        aria-label="edit"
        size="small"
        style={{
          marginLeft: 0
        }}
        onClick={toggleModal}
      >
        {(!(_.get(errors, source, '')) && disabledOnFilled) ? (
          <VisibilityIcon
            fontSize="small"
          />
        ) : (
          <EditIcon
            fontSize="small"
          />
        )}
      </IconButton>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        inputProps={inputProps}
        setBlurredMain={setBlurred}
        fieldsArray={fieldsArray}
        title={modalTitle}
        fullAddressLabel={fullAddressLabel}
        isDisabledModalFields={isDisabledModalFields}
        showErrorsWithoutBlur={showErrorsWithoutBlur}
        source={source}
      />
      <div className={classes.hide}>
        {fieldsArray.map(item => (
          <TextInput
            source={`${source}.${item.name}`}
            validate={item.required ? validateRequired : () => {}}
            disable
          />
        ))}
      </div>
    </>
  )
}

export default LegalAddress