import {customApiUrl, getFile, httpClient} from '../api/httpDataProvider'
import {fieldsCharter, fieldsPowerofattorney} from './enums'
import moment from "moment";

export const phoneWithMask = (value) => {
  let phone = value

  if (value && (value[0] === '7')) {
    phone = phone.replace(/(7)([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})/g, '+$1($2)-$3-$4-$5')
  }

  return phone
}

export const validPhone = value => {
  let error = ''
  if (/^\+7.*/g.test(value) && (value.replace(/[^[0-9]/g, '').length < 11)) {
    error = 'errors.phoneLength'
  }
  return error
}

export const lineLength = (length, translate) => value => (
  value ? (
    value.toString().length !== length ? `${translate('errors.length')} ${length}` : ''
  ) : ''
)

export const maxLength = (maxLength, translate) => value => (
  value ? (
    value.toString().length > maxLength ? translate('ra.validation.maxLength', {'max': 255}) : ''
  ) : ''
)

export const innLength = (translate, constants) => value => (
  value ? (
    (
      value.toString().length !== constants?.JURIDICAL_PERSON_TIN_LENGTH &&
      value.toString().length !== constants?.SOLE_TRADER_TIN_LENGTH
    ) ? `${
      translate(
        'errors.inn',
        { legalLength: constants?.JURIDICAL_PERSON_TIN_LENGTH, individualLength: constants?.SOLE_TRADER_TIN_LENGTH }
      )
    }` : ''
  ) : ''
)

export const validatePassword = (newPassword, errors, translate) => {
  const newErrors = {
    ...errors
  }

  const newPasswordKeys = Object.keys(newPassword)
  newPasswordKeys.forEach(field => {
    if (!newPassword[field]) {
      newErrors[field] = 'ra.validation.required'
    } else if (newPassword[field].length < 9 && field !== 'currentPassword') {
      newErrors[field] = translate('ra.validation.minLength', { min: 9 })
    } else {
      newErrors[field] = ''
    }
  })

  if (!(newErrors.newPassword || newErrors.confirmNewPassword) && (newPassword.newPassword !== newPassword.confirmNewPassword)) {
    newErrors.newPassword = 'errors.passwordMatch'
    newErrors.confirmNewPassword = 'errors.passwordMatch'
  }

  return newErrors
}

export const downloadDocument = file => async e => {
  e && e.stopPropagation()
  let blob

  if (file.file) {
    blob = await new Blob([file.file], { type: file.type })
  } else {
    blob = await getFile(file.id)
  }
  const url = window.URL.createObjectURL(blob)
  window.open(url, '_blank').focus()
}

// transform utils

export const isNotNull = value => (value !== null)
export const clearFounderFields = (value, key, obj, constants) => (
  !(value === null || key === 'headFullName' ||
    (key === 'fullName' && obj.subjectType === constants?.SUBJECT_TYPE_INDIVIDUAL)
  )
)
export const isNotNullAndNotCreateDate = (value, key) => (value !== null && key !== 'createDate')

export const deleteFields = (conditionFunc, constants) => initObj => {
  if (typeof initObj === 'object') {
    const newObj = {}
    const keys = Object.keys(initObj)
    keys.forEach(key => {
      if (typeof initObj[key]  === 'object' && initObj[key] !== null) {
        newObj[key] = deleteFields(conditionFunc)(initObj[key])
      } else if (conditionFunc(initObj[key], key, initObj, constants)) {
        newObj[key] = initObj[key]
      }
    })
    return newObj
  }
}

export const attorneyLettersProcessing = (attorneyLetters, constants) => (
  attorneyLetters.map(item => {
    const attorneyLetter = { ...item }
    const result = deleteFields(isNotNullAndNotCreateDate)(attorneyLetter)

    if (result.attorneyLetterAct.identifier === constants?.POWER_OF_ATTORNEY_ACT_I) {
      fieldsCharter.forEach(field => {
        if (field.name == 'supplierComment')
          return;
        result[field.name] = null
      })

      if (!result.validFrom) {
        result.validFrom = null
      }
    }
    if (result.attorneyLetterAct.identifier === constants?.CHARTER_ACT_I) {
      fieldsPowerofattorney.forEach(field => {
        result[field.name] = null
      })
    }

    return result
  })
)

const createRequestsToLoadFiles = array => (
  array.map(async item => {
    if (item.file?.file) {
      const fileArray = Object.keys(item.file.file).map(key => item.file.file[key])
      const formData = new FormData()
      const blob = await new Blob([Uint8Array.from(fileArray)], { type: item.file.type })

      formData.append('file', blob, item.file.name)

      return httpClient(`${customApiUrl}/file/upload`, {
        method: 'POST',
        body: formData
      })
    }
  })
)

export const attachFilesToDataObjects = async arrayOfData => {
  const requests = arrayOfData.map(async data => (
    Promise.all(createRequestsToLoadFiles(data))
  ))
  const files = await Promise.all(requests)
  return (
    arrayOfData.map((array, index) => (
      array.map((data, i) => ({
        ...data,
        file: files[index][i] ? {
          id: files[index][i].json.id,
          name: files[index][i].json.name,
        } : data.file ?? null
      }))
    ))
  )
}

export const attorneyLetterDatePeriodValidation = (field, allValues) => {
  let minDate
  let maxDate
  let error

  switch (field) {
    case 'validFrom': {
      maxDate = (allValues.validUntil && allValues.validUntil.toString() !== 'Invalid Date') ? allValues.validUntil : undefined
      break
    }
    case 'validUntil': {
      let latestDate
      if (
        allValues.validFrom && allValues.validFrom.toString() !== 'Invalid Date'
        && allValues.issueDate && allValues.issueDate.toString() !== 'Invalid Date'
      ) {
        latestDate = moment(allValues.validFrom).unix() > moment(allValues.issueDate).unix() ?
          allValues.validFrom : allValues.issueDate
      } else {
        latestDate = (
          allValues.issueDate && allValues.issueDate.toString() !== 'Invalid Date'
        ) ? allValues.issueDate : allValues.validFrom
      }
      minDate = latestDate ?? undefined
      break
    }
    case 'issueDate': {
      maxDate = (
        allValues.validUntil && allValues.validUntil.toString() !== 'Invalid Date'
      ) ? allValues.validUntil : undefined
      break
    }
  }

  if (
    minDate && (moment(minDate).startOf('day').unix() > moment(allValues[field]).startOf('day').unix())
    || maxDate && (moment(maxDate).startOf('day').unix() < moment(allValues[field]).startOf('day').unix())
  ) {
    error = 'errors.invalidDate'
  } else {
    error = ''
  }
  return error
}

export const getMaxDateAttorneyLetter = (fieldName, dateValues) => {
  const maxDate = (fieldName === 'validFrom' || fieldName === 'issueDate') ? dateValues.validUntil : undefined
  return maxDate ?? undefined
}

export const getMinDateAttorneyLetter = (fieldName, dateValues) => {
  const minDate = fieldName === 'validUntil' ? (
    (dateValues.validFrom && (dateValues.validFrom.toString() !== 'Invalid Date')
      && dateValues.issueDate && (dateValues.issueDate.toString() !== 'Invalid Date')) ? (
      moment(dateValues.validFrom).unix() > moment(dateValues.issueDate).unix() ?
        dateValues.validFrom : dateValues.issueDate
    ) : (
      (dateValues.validFrom && (dateValues.validFrom.toString() !== 'Invalid Date')) ?
        dateValues.validFrom : dateValues.issueDate
    )
  ) : undefined
  return minDate ?? undefined
}

export const getFullName = (firstName, middleName, lastName) => {
  return (lastName ? lastName + ' ' : '')
       + (firstName ? firstName + ' ' : '')
       + (middleName ? middleName : '');
}
