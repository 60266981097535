import List from './List'
import Edit from './Edit'
import Create from './Create'
import Assignment from '@material-ui/icons/Assignment'

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: Assignment,
  options: {
    label: 'menuItem.attorneyLetters'
  }
}