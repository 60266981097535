import * as React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {
  Card,
  CardContent
} from '@material-ui/core'
import {Title, useTranslate} from 'react-admin'
import "video-react/dist/video-react.css";
import VideoSection from "./components/VideoSection";

const useStyles = makeStyles((theme) => ({
  column: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  whiteCard: {
    backgroundColor: '#ffffff',
    fontSize: '1 rem',
  }
}))

const Help = () => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <Title title={translate('menuItem.help')}/>
      <VideoSection
        title={translate("help.titleUL")}
        videoSrc={"video/Портал поставщика ЮЛ_new.mp4"}

      />
      <VideoSection
        title={translate("help.titleIndividual")}
        videoSrc={"video/Портал поставщика ИП.mp4"}
      />
      <Card className={classes.whiteCard}>
        <CardContent >
          <div className={classes.column}>
            <span>Номер для связи: 8 800 600 87 74</span>
            <span>с 7:00 до 17:30 (Московское время)</span>
          </div>
        </CardContent>
      </Card>
    </>
  )
}

export default Help