import * as React from 'react'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  wrapperTextBoxPassword: {
    overflow: 'hidden',
    maxHeight: ({isShowPasswordPolicy}) => (isShowPasswordPolicy ? '450px' : '0'),
    transition: 'all 0.3s',
  },
  textBoxPassword: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    backgroundColor: '#eeeeee',
    '& ul': {
      margin: 0,
    }
  }
}))

const PasswordPolicy = ({ isShowPasswordPolicy }) => {
  const translate = useTranslate()
  const classes = useStyles({
    isShowPasswordPolicy
  })
  return (
    <div className={classes.wrapperTextBoxPassword}>
      <Paper className={classes.textBoxPassword}>
        <Typography variant="subtitle1">
          {translate('consentModal.passwordPolicy.title')}
        </Typography>
        <ul>
          {new Array(7).fill(null).map((item, index) => (
            <li>{translate(`consentModal.passwordPolicy.list${index}`)}</li>
          ))}
        </ul>
      </Paper>
    </div>
  )
}

export default PasswordPolicy