import * as React from 'react'
import { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Datagrid, DatagridRow, DatagridBody, useTranslate,
} from 'react-admin'
import { Checkbox, LinearProgress, Chip, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/core/styles'
import { setUpdatePointsOfSaleData, setVisibilityModalConfirmationPointsOfSale } from '../../../../reducer/actions'
import {requestChangedFields, fieldWasChanged} from '../../../../api/customApi'
import {setChangedFields} from '../../index'
import httpDataProvider from '../../../../api/httpDataProvider'

const useStyles = makeStyles((theme) => ({
  supplier: {
    minWidth: 270,
    '& div.MuiInputBase-root': {
      height: 56,
    },
    '& input': {
      height: 'unset',
      boxSizing: 'content-box'
    }
  },
  changed : {
    'font-weight': 'bold',
    'color':'blue',
    '& span.MuiTypography-root': {
      'font-weight': 'bold',
      'color':'blue'
    }
  }
}))

const PointsOfSaleDatagridRow = (props) => {
  const {
    record, resource, id, children, basePath, dataForUpdate, setDataForUpdate, suppliers, suppliersLoading
  } = props
  const translate = useTranslate()
  const classes = useStyles()

  const onChangeIncludedInformationLetter = useCallback((e) => {
    setDataForUpdate({
      ...dataForUpdate,
      [id]: {
        ...dataForUpdate[id],
        shouldUpdate: true,
        includedInInformationLetter: e.target.checked,
        sent: false
      }
    })
  }, [setDataForUpdate, dataForUpdate, id])

  const onChangeSupplier = useCallback((e, value) => {
    setDataForUpdate({
      ...dataForUpdate,
      [id]: {
        ...dataForUpdate[id],
        shouldUpdate: true,
        includedInInformationLetter: value ? dataForUpdate[id]?.includedInInformationLetter : false,
        sent: false,
        supplier: value
      }
    })
  }, [setDataForUpdate, dataForUpdate, id])

  const stopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])
  return (
    <DatagridRow
      key={id}
      {...props}
    >
      {React.Children.map(children, field => (
          field.props.source === 'includedInInformationLetter' ? (
            dataForUpdate[id] ? <Checkbox
              checked={dataForUpdate[id].includedInInformationLetter}
              onChange={onChangeIncludedInformationLetter}
              onClick={stopPropagation}
              color="primary"
              disabled={!dataForUpdate[id].supplier?.id}
            /> : <LinearProgress />
          ) : (
            field.props.source === 'supplier.id' ? (
              (dataForUpdate[id] && !suppliersLoading) ? (
                <div
                  className={classes.supplier}
                  onClick={stopPropagation}
                >
                  <Autocomplete
                    size="small"
                    options={suppliers}
                    getOptionLabel={(option) => option.client?.organizationName}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.organizationName}
                          size="small"
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    value={dataForUpdate[id].supplier}
                    onChange={onChangeSupplier}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        label={translate('fields.supplier')}
                        placeholder={translate('fields.organizationName')}
                        onClick={stopPropagation}
                      />
                    )}
                  />
                </div>
              ) : <LinearProgress />
            ) : (
              <span
                className={fieldWasChanged(id, field) ? classes.changed : ""}
                onClick={stopPropagation}
              >
                  {
                    React.cloneElement(field, {
                      record,
                      basePath,
                      resource,
                    })
                  }
                </span>
            )
        )
      ))}
    </DatagridRow>
  )
}

const PointsOfSaleDatagridBody = props => (
  <DatagridBody
    {...props}
    row={
      <PointsOfSaleDatagridRow
        dataForUpdate={props.dataForUpdate}
        setDataForUpdate={props.setDataForUpdate}
        suppliers={props.suppliers}
        suppliersLoading={props.suppliersLoading}
      />
    }
  />
)

const PointsOfSaleDatagrid = props => {
  const { setDisabledSaveChanges, ids } = props
  const dispatch = useDispatch()
  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const constants = useSelector(state => state.customReducer.constants)
  const isOpenModal = useSelector(state => state.customReducer.updatePointsOfSale.isOpenModal)

  const [availableStatuses, setAvailableStatuses] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [suppliersLoading, setSuppliersLoading] = useState(true)
  const [changesLoading, setChangesLoading] = useState(true)
  const [dataForUpdate, setDataForUpdate] = useState({})

  useEffect(() => {
    if (constants.DRAFT_PROVIDER_STATUS_I && providerStatuses.length) {
      const newAvailableStatuses = []
      providerStatuses.forEach(status => {
        if (status.identifier !== constants.DRAFT_PROVIDER_STATUS_I) {
          newAvailableStatuses.push(status.id)
        }
      })

      setAvailableStatuses(newAvailableStatuses)
    }
  }, [providerStatuses, constants])

  const initChangedFields = async () => {
    if (changesLoading) {
      requestChangedFields().then(result => {
        setChangedFields(result)
        setChangesLoading(false)
      })
    }
  }

  const initSuppliers = useCallback(async () => {
    if (availableStatuses.length) {
      const suppliers = await httpDataProvider.getList('suppliers', {
        filter: {
          notConvert: true,
          filters: [{
            group: "OR",
            conditions: availableStatuses.map(id => ({
              property: 'client.providerStatus.id',
              operator: '=',
              value: id
            }))
          }]
        }
      })
      setSuppliers(suppliers.data)
      setSuppliersLoading(false)
    }
  }, [availableStatuses])

  useEffect(() => {
    const dataIds = Object.keys(props.data)
    const newData = { ...props.data }
    dataIds.forEach(id => {
       newData[id] = {
         ...newData[id],
         shouldUpdate: false
       }
    })

    setDataForUpdate(newData)
    return () => {
      if (props.ids.length) {
        dispatch(setVisibilityModalConfirmationPointsOfSale(true))
      }
    }
  }, [ids, props.data])

  useEffect(() => {
    if (!isOpenModal) {
      const dataForUpdateIds = Object.keys(dataForUpdate)
      const dataForStore = []
      dataForUpdateIds.forEach(id => {
        if (dataForUpdate[id].shouldUpdate) {
          const { shouldUpdate, ...item } = dataForUpdate[id]
          if (item.supplier?.id) {
            item.supplier = {
              id: item.supplier.id
            }
          } else {
            item.supplier = null
          }
          dataForStore.push(item)
        }
      })

      dispatch(setUpdatePointsOfSaleData(dataForStore))
    }
  }, [dataForUpdate, isOpenModal])

  useEffect(() => {
    const keysDataForUpdate = Object.keys(dataForUpdate)
    let isDisabledSaveChanges = true

    keysDataForUpdate.find(id => {
      if (dataForUpdate[id].shouldUpdate) {
        isDisabledSaveChanges = false
        return id
      }
    })

    setDisabledSaveChanges(isDisabledSaveChanges)
  }, [dataForUpdate])

  useEffect(() => {
    if (ids.length) {
      initSuppliers()
      initChangedFields()
    }
  }, [ids, availableStatuses, constants, providerStatuses])

  useEffect(() => {
    const keysDataForUpdate = Object.keys(dataForUpdate)
    let isDisabledSaveChanges = true

    keysDataForUpdate.find(id => {
      if (dataForUpdate[id].shouldUpdate) {
        isDisabledSaveChanges = false
        return id
      }
    })

    setDisabledSaveChanges(isDisabledSaveChanges)
  }, [dataForUpdate])

  return (
    <Datagrid
      {...props}
      body={
        <PointsOfSaleDatagridBody
          dataForUpdate={dataForUpdate}
          setDataForUpdate={setDataForUpdate}
          suppliers={suppliers}
          suppliersLoading={suppliersLoading}
        />
      }
    />
  )
}

export default PointsOfSaleDatagrid