import List from './List'
import Edit from './Edit'
import Create from './Create'
import DeviceHub from '@material-ui/icons/DeviceHub'

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: DeviceHub,
  options: {
    label: 'menuItem.distributionCenters'
  }
}