import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, TextField } from '@material-ui/core'
import { setLoginEmail } from '../../../../reducer/actions'

const useStyles = makeStyles(theme => ({
  label: {
    display: 'block',
    marginBottom: 6,
    fontSize: 12,
    fontWeight: 400,
    '&:not(.Mui-error)': {
      color: '#000 !important'
    },
    '&.Mui-error': {
      color: '#f44336 !important'
    }
  },
}))

const LoginTextField = ({
                          meta: { touched, error } = { touched: false, error: undefined },
                          input: { ...inputProps },
                          ...props
                        }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    if (inputProps.name === 'username') {
      dispatch(setLoginEmail(inputProps.value))
    }
  }, [inputProps.value])

  return (
    <>
      <FormLabel
        className={classes.label}
        error={error}
      >
        {props.label}
      </FormLabel>
      <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        label=""
        variant="outlined"
        fullWidth
      />
    </>
  )
}

export default LoginTextField