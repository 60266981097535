import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteButton, SaveButton, useTranslate, useDataProvider, useNotify, useRedirect, useRefresh } from 'react-admin'
import { useForm } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { Toolbar, Box } from '@material-ui/core'
import { transform } from '../../utils'
import { verifyAttorneyLetter } from '../../../../api/customApi'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    boxSizing: 'border-box',
    padding: '25px 32px',
    backgroundColor: "#f5f5f5",
    '& > div': {
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      boxSizing: 'border-box',
      width: '100%'
    }
  },
  delete: {
    padding: '0 16px',
    color: '#662211',
    background: '#F2B8B5',
    '&:hover': {
      background: '#eea6a3'
    },
    '&.Mui-disabled': {
      background: 'transparent',
    }
  }
}))

const AttorneyLettersToolbar = ({
                                  supplierStatusIdentifier,
                                  mode,
                                  basePath,
                                  handleSubmit,
                                  handleSubmitWithRedirect,
                                  invalid,
                                  saving,
                                  validating,
                                  record,
                                  submitOnEnter,
                                  resource,
                                  undoable,
                                  mutationMode,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const dispatch = useDispatch()

  const loading = useSelector(state => state.admin.loading)
  const attorneyLetterActs = useSelector(state => state.customReducer.attorneyLetterActs)
  const constants = useSelector(state => state.customReducer.constants)

  const [provideLoading, setProvideLoading] = useState(false)

  const charterAct = useMemo(() => (
    attorneyLetterActs.find(act => (
      act.identifier === constants?.CHARTER_ACT_I
    ))
  ), [constants, attorneyLetterActs])

  const onSave = useCallback(async () => {
    try {
      const data = await transform(constants)(form.getState().values)
      let res
      if (mode === 'edit') {
         res = await dataProvider.update('attorneyLetters', { id: data.id, data })
      }
      if (mode === 'create') {
        res = await dataProvider.create('attorneyLetters', { data })
      }

      if (res?.data?.id) {
        notify(translate('notify.dataSaved'))
        redirect(`/attorneyLetters/${res?.data?.id}`)
        refresh()
      }
      if (res?.data?.errorMessage) {
        notify(res.data.errorMessage, 'warning')
      }
    } catch (err) {
      console.log(err)
      notify(err.message, 'warning')
    }
  }, [constants, form, invalid, dataProvider])

  const onProvide = useCallback(async () => {
    setProvideLoading(true)
    try {
      const data = await transform(constants)(form.getState().values)
      const res = await verifyAttorneyLetter(data)

      if (res.responseBody?.id) {
        notify(translate('notify.dataProvided'))
        redirect(`/attorneyLetters/${res.responseBody?.id}`)
        refresh()
      }
      if (res.errorMessage) {
        notify(res.errorMessage, 'warning')
      }
    } catch (err) {
      console.log(err)
      notify(err.message, 'warning')
    }
    setProvideLoading(false)
  }, [constants, form])

  return (
    <Toolbar
      className={classes.toolbar}
      role="toolbar"
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        {supplierStatusIdentifier === constants?.DRAFT_PROVIDER_STATUS_I ? (
          <SaveButton
            transform={transform(constants)}
            handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
            onSave={onSave}
            invalid={invalid}
            saving={saving || validating || loading !== 0}
            submitOnEnter={submitOnEnter}
          />
        ) : (
          <SaveButton
            label={translate('buttons.provide')}
            handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
            onSave={onProvide}
            invalid={invalid}
            saving={saving || validating || loading !== 0 || provideLoading}
            disabled={
              saving || validating || loading !== 0 || supplierStatusIdentifier === constants?.INACTIVE_PROVIDER_STATUS_I || provideLoading
            }
            submitOnEnter={submitOnEnter}
          />
        )}
        {mode === 'edit' && (
          <DeleteButton
            className={classes.delete}
            label={translate('ra.action.delete')}
            basePath={basePath}
            record={record}
            resource={resource}
            undoable={undoable}
            mutationMode={mutationMode}
            disabled={
              charterAct?.id === form.getState().values?.attorneyLetterAct?.id ||
              supplierStatusIdentifier === constants?.INACTIVE_PROVIDER_STATUS_I
            }
          />
        )}
      </Box>
    </Toolbar>
  )
}

export default AttorneyLettersToolbar