import React, { useCallback } from 'react'
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@material-ui/core'
import { useTranslate } from 'react-admin'
import { yesNoOptions } from '../../../../../../../../../../helpers/enums'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    '& > div': {
      padding: '20px 9px 9px',
    },
  },
  helperText: {
    marginLeft: 14,
    marginRight: 14
  }
}))

const MoreThenFiftyPercentSelect = ({
                                      formClass,
                                      values,
                                      setValues,
                                      blurred,
                                      onBlur,
                                      errors,
                                      validate,
                                      disabled,
                                      required
}) => {
  const classes = useStyles()
  const translate = useTranslate()

  const handleHasMoreThanFiftyPercent = useCallback((e) => {
    const value = e.target.value
    const field = 'hasMoreThanFiftyPercent'

    const valuesNew = {
      ...values,
      [field]: value,
    }

    if (value === 'NO') {
      delete valuesNew.headLastName
      delete valuesNew.headFirstName
      delete valuesNew.headMiddleName
    }

    validate(field, value)
    setValues(valuesNew)
  }, [values, errors])

  return (
    <FormControl
      className={formClass}
      error={blurred.hasMoreThanFiftyPercent && errors.hasMoreThanFiftyPercent}
      disabled={disabled}
      required={required}
    >
      <InputLabel required={required}>
        {translate('fields.hasMoreThenFiftyPercent')}
      </InputLabel>
      <Select
        className={classes.container}
        variant='filled'
        value={values.hasMoreThanFiftyPercent}
        onChange={handleHasMoreThanFiftyPercent}
        onBlur={onBlur('hasMoreThanFiftyPercent', required)}
        translateChoice
        required={required}
      >
        {yesNoOptions.map((item) => (
          <MenuItem value={item.value}>{translate(item.name)}</MenuItem>
        ))}
      </Select>
      {(blurred.hasMoreThanFiftyPercent && errors.hasMoreThanFiftyPercent) && (
        <FormHelperText className={classes.helperText}>{errors.hasMoreThanFiftyPercent}</FormHelperText>
      )}
    </FormControl>
  )
}

export default MoreThenFiftyPercentSelect