import * as React from 'react'
import { useCallback, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import moment from 'moment'
import { FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { enGB, ru } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import { fieldsDatePowerofattorney } from '../../../../../../helpers/enums'
import {
  attorneyLetterDatePeriodValidation, getMaxDateAttorneyLetter, getMinDateAttorneyLetter
} from '../../../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    marginTop: 8,
    marginBottom: 4
  },
  input: {
    width: '100%',
  },
}))

const DatesFields = ({
  disabled,
  meta: { touched, error} = {touched: false, error: undefined},
  input: { ...inputProps },
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const { values, initialValues, submitFailed } = useFormState()

  const language = useSelector(state => state.customReducer.appLanguage)

  const [dateValues, setDateValues] = useState(
    fieldsDatePowerofattorney.reduce((sum, nextItem) => ({ ...sum, [nextItem.name]: null }), {})
  )
  const [blurred, setBlurred] = useState(
    fieldsDatePowerofattorney.reduce((sum, nextItem) => ({ ...sum, [nextItem.name]: false }), {})
  )
  const [errors, setErrors] = useState(
    fieldsDatePowerofattorney.reduce((sum, nextItem) => ({ ...sum, [nextItem.name]: '' }), {})
  )

  const dateValidation = (value) => (
    fieldsDatePowerofattorney.reduce((sum, nextItem) => (
      `${sum}${errors[nextItem.name] ?? ''}`
    ), '')
  )

  const validate = useCallback((field, allValues = dateValues) => {
    const isRequired = fieldsDatePowerofattorney.find(item => item.name === field).required
    let error
    if (isRequired && !allValues[field]) {
      error = 'ra.validation.required'
    } else if (allValues[field]?.toString() === 'Invalid Date') {
      error = 'errors.invalidDate'
    }
    if (error) {
      return error
    }

    return attorneyLetterDatePeriodValidation(field, allValues)
  }, [dateValues])

  const onBlur = useCallback(field => () => {
    setBlurred(prev => ({
      ...prev,
      [field]: true
    }))
  }, [])

  const onChange = useCallback((field) => value => {
    const newDateValues = {
      ...dateValues,
      [field]: value
    }

    const newErrors = fieldsDatePowerofattorney.reduce((sum, nextItem) => ({
      ...sum,
      [nextItem.name]: validate(nextItem.name, newDateValues)
    }), {})
    setErrors(newErrors)
    setDateValues(newDateValues)
  }, [values, dateValues, inputProps])

  useEffect(() => {
    const value = fieldsDatePowerofattorney.reduce((sum, nextItem) => ({
      ...sum,
      [nextItem.name]: dateValues[nextItem.name] && dateValues[nextItem.name].toString() !== 'Invalid Date' ?
        moment(dateValues[nextItem.name]).format('YYYY-MM-DD').toString() : dateValues[nextItem.name]
    }), {})

    inputProps.onChange({
      target: {
        value
      }
    })
  }, [dateValues])

  useEffect(() => {
    const value = fieldsDatePowerofattorney.reduce((sum, nextItem) => ({
      ...sum,
      [nextItem.name]: initialValues[nextItem.name] ?? null
    }), {})

    const error = fieldsDatePowerofattorney.reduce((sum, nextItem) => ({
      ...sum,
      [nextItem.name]: validate(nextItem.name, value)
    }))
    setErrors(error)
    setDateValues(value)
    inputProps.onChange({
      target: {
        value
      }
    })
  }, [initialValues])

  useEffect(() => {
    if (submitFailed) {
      setBlurred(
        fieldsDatePowerofattorney.reduce((sum, nextItem) => ({ ...sum, [nextItem.name]: true }), {})
      )
    }
  }, [submitFailed])

  return (
    <>
      {
        fieldsDatePowerofattorney.map(field => (
          <FormControl className={classes.inputWrapper}>
            <MuiPickersUtilsProvider
              locale={language ==='ru' ? ru : enGB}
              utils={DateFnsUtils}
            >
              <KeyboardDatePicker
                className={classes.input}
                label={translate(`fields.${field.name}`)}
                inputVariant="filled"
                format="dd.MM.yyyy"
                required={field.required}
                value={dateValues[field.name]}
                onChange={onChange(field.name)}
                onBlur={onBlur(field.name)}
                error={blurred[field.name] && errors[field.name]}
                helperText={blurred[field.name] && translate(errors[field.name])}
                disabled={disabled}
                maxDate={getMaxDateAttorneyLetter(field.name, dateValues)}
                minDate={getMinDateAttorneyLetter(field.name, dateValues)}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        ))
      }
      <TextInput
        source="date"
        disabled
        validate={[dateValidation]}
        style={{ display: 'none' }}
      />
    </>
  )
}

export default DatesFields