import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  TextInput, useTranslate, required, SelectInput, number
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Field, useFormState } from 'react-final-form'
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { innLength} from '../../../../helpers/utils'
import SupplierField from '../SupplierField'
import {
  setUpdateOnePointOfSaleData
} from "../../../../reducer/actions";
import {fieldWasChangedByName} from '../../../../api/customApi'

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,
    marginBottom: theme.spacing(1),
    flex: 'unset',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridColumnGap: theme.spacing(1),
    },
  },
  form: {
    width: '100%',
    maxWidth: '1000px',
    padding: '9px 16px 25px',
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: 400
    }
  },
  includedInInformationLetter: {
    marginTop: 8,
    '& span': {
      lineHeight: 1.2
    }
  },
  subtitle: {
    gridColumn: '1 / 3',
    margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px`,
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 2',
    },
  },
  legalAddress: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      gridTemplateColumns: '1fr 56px',
    },
  },
  changed : {
    'border':'4px solid blue'
  }
}))

const validRequired = [required()]

const Form = ({ mode, id }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const constants = useSelector(state => state.customReducer.constants)
  const pointOfSaleStatuses = useSelector(state => state.customReducer.pointOfSaleStatuses)

  const validINN = [number(), innLength(translate, constants)]

  const { values } = useFormState()


  const renderIncludedInInformationLetter = ({
                                               meta: {touched, error} = {touched: false, error: undefined},
                                               input: {...inputProps},
                                               ...props
                                             }) => {
    const handleIncludedInInformationLetter =(e) => {
      inputProps.onChange({
        target: {
          value: e.target.checked
        }
      })
    }

    return (
      <>
        <FormControlLabel
          className={classes.includedInInformationLetter}
          label={translate('fields.includedInInformationLetter')}
          control={
            <Checkbox
              checked={values.includedInInformationLetter}
              onChange={handleIncludedInInformationLetter}
              color="primary"
            />
          }
        />
        <TextInput
          source="includedInInformationLetter"
          disabled
          style={{ display: 'none' }}
        />
      </>
    )
  }

  const renderSupplier = (props) => (
    <SupplierField  {...props} />
  )

  return (
    <div className={classes.form}>
      <div className={classes.formControl}>
        <TextInput
          label={translate('fields.namePointOfSale')}
          source="name"
          onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          validate={validRequired}
          helperText=""
          className={fieldWasChangedByName(id, 'name') ? classes.changed : ""}
        />
        <SelectInput
          label={translate('fields.status')}
          source="status.identifier"
          choices={pointOfSaleStatuses}
          onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          disabled
          optionValue="identifier"
          helperText=""
          className={fieldWasChangedByName(id, 'status.identifier') ? classes.changed : ""}
        />
        <Field
          name="supplier"
          render={renderSupplier}
          className={fieldWasChangedByName(id, 'supplier') ? classes.changed : ""}
        />
        <Field
          name="includedInInformationLetter"
          onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}z
          onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          render={renderIncludedInInformationLetter}
        />
        <TextInput
          label={translate('fields.addressPointOfSale')}
          onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          source="legalAddress.fullName"
          validate={validRequired}
          helperText=""
          className={fieldWasChangedByName(id, 'legalAddress.fullName') ? classes.changed : ""}
        />
      </div>
      <Typography className={classes.subtitle} variant="h6">
        {translate('pointsOfSale.subtitle')}
      </Typography>
      <div className={classes.formControl}>
        <TextInput
          label={translate('fields.posInn')}
          onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          source="posInn"
          validate={validINN}
          helperText=""
          className={fieldWasChangedByName(id, 'posInn') ? classes.changed : ""}
        />
        <TextInput
          label={translate('fields.legalName')}
          onBlur={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          onChange={()=>dispatch(setUpdateOnePointOfSaleData(values))}
          source="registeredName"
          helperText=""
          className={fieldWasChangedByName(id, 'registeredName') ? classes.changed : ""}
        />
      </div>
    </div>
  )
}

export default Form