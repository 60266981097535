import * as React from 'react'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { BulkDeleteButton } from 'react-admin'

const BulkActionButtons = (props) => {
  const users = useSelector(state => state.admin.resources?.users?.data)
  const currentUser = useSelector(state => state.customReducer.currentUser)
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    const includeCurrentUser = props.selectedIds.find(id => (
      users[id].id === currentUser.id
    ))
    setDisabled(includeCurrentUser)
  }, [users, props.selectedIds])

  return (
    <BulkDeleteButton
      {...props}
      undoable={false}
      disabled={disabled}
    />
  )
}

export default BulkActionButtons