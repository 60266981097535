import * as React from 'react'
import { TopToolbar, BulkDeleteButton } from 'react-admin'
import {makeStyles} from "@material-ui/core/styles";
import ChangesConfirmButton from "../ChangesConfirmButton";

const useStyles = makeStyles((theme) => ({
  topToolBar: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: '0',
    paddingBottom: '8px'
  }
}))

const BulkActionButtons = (props) => {
  const classes = useStyles()
  return (
    <TopToolbar
      {...props}
      id="list-actions-bulk"
      className={classes.topToolBar}
    >
      <BulkDeleteButton
        {...props}
        undoable={false}
      ></BulkDeleteButton>
      <ChangesConfirmButton
        {...props}
      ></ChangesConfirmButton>
    </TopToolbar>
  )
}

export default BulkActionButtons