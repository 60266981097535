import * as React from 'react'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useFormState } from 'react-final-form'
import { FunctionField, TextField, useTranslate } from 'react-admin'
import Datagrid from './components/DatagridFounders'
import ListButtons from './components/ListButtons'
import { yesNoOptions } from '../../../../../../../../helpers/enums'

const ListFounders = ({ selected, setSelected, toggleModal, data, inputProps, ids, loaded }) => {
  const translate = useTranslate()
  const { values } = useFormState()

  const constants = useSelector(state => state.customReducer.constants)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const handleClick = (item) => {
    selected.find((el) => el === item)
      ? setSelected(selected.filter((it) => it !== item))
      : setSelected([...selected, item])
  }

  const handleSelect = () => {
    selected.length ? setSelected([]) : setSelected(ids)
  }

  const onDelete = useCallback(() => {
    const supplierFounders = []

    values.client?.supplierFounders.forEach((item, index) => {
      if (!selected.find(item => item === index.toString())) {
        supplierFounders.push(item)
      }
    })

    setSelected([])
    inputProps.onChange({
      target: {
        value: supplierFounders,
      },
    })
  }, [values.client?.supplierFounders, selected])

  const getSupplierFounderOrganizationName = (founder) => {
    return values.client?.supplierFounders?.find(e => (
      e.parentSupplier && e.parentSupplier.id === founder.id
    ))?.name ?? founder.client?.organizationName
  }

  return (
    <>
      {(
        supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I || supplierStatus === ''
      ) && (
        <ListButtons
          ids={selected}
          toggleModal={toggleModal}
          isOpenCopy={true}
          data={data}
          onDelete={onDelete}
        />
      )}
      <Datagrid
        basePath=""
        currentSort={{}}
        data={data}
        ids={ids}
        hasBulkActions={true}
        selectedIds={selected}
        size="medium"
        onToggleItem={handleClick}
        onSelect={handleSelect}
        rowClick={toggleModal}
        loaded={loaded}
      >

        <FunctionField
          label={translate('columns.organization')}
          render={record => (getSupplierFounderOrganizationName(record))}
        />
        <TextField
          source="name"
          label={translate('fields.nameFounder')}
        />
        <FunctionField
          label={translate('fields.hasMoreThenFiftyPercent')}
          render={record => (
            translate(yesNoOptions.find(option => (
              option.value === record.hasMoreThanFiftyPercent
            ))?.name ?? '')
          )}
        />
        <TextField
          label={translate('fields.fullNameFounder')}
          source="fullName"
        />
        <TextField
          source="headFullName"
          label={translate('fields.directorName')}
        />
        <TextField
          source="parentSupplier.fullName"
          label={translate('columns.parenSupplier')}
        />
      </Datagrid>
    </>
  )
}

export default ListFounders