import * as React from 'react'
import {useCallback, useEffect, useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useDataProvider, useTranslate, useNotify, useRefresh} from 'react-admin'
import {makeStyles} from '@material-ui/core/styles'
import {
  setVisibilityModalConfirmationOnePointOfSale,
  setUpdateOnePointOfSaleData
} from '../../reducer/actions'
import {transform} from '../../forms/PointsOfSale/utils'
import ModalWithCancel from "../ModalWithCancel";

const UpdatePointOfSaleConfirmationModal = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  const refresh = useRefresh()
  const [isOpen, setOpen] = useState(false)

  const isOpenModal = useSelector(state => state.customReducer.updateOnePointOfSale.isOpenModal)
  const data = useSelector(state => state.customReducer.updateOnePointOfSale.data)

  useEffect(() => {
    let isOpen = false
    if (isOpenModal && data != null) {
      isOpen = true
    } else {
      dispatch(setVisibilityModalConfirmationOnePointOfSale(false))
    }
    setOpen(isOpen)
  }, [data, isOpenModal])

  const handleClose = useCallback(async () => {
    dispatch(setVisibilityModalConfirmationOnePointOfSale(false))
    dispatch(setUpdateOnePointOfSaleData(null))
    setOpen(false)
  },)

  const onConfirm = useCallback(async () => {
    try {
      dispatch(setVisibilityModalConfirmationOnePointOfSale(false))
      setLoading(true)
      const transformData = transform({})(data)
      let res = await dataProvider.update('pointsOfSale', {id: transformData.id, data:transformData})
      if (res?.data?.id) {
        notify(translate('notify.dataSaved'))
        refresh()
      }
      setOpen(false)
      dispatch(setUpdateOnePointOfSaleData(null))
      setLoading(false)
    } catch (err) {
      notify(err.message, 'warning')
    }
  }, [data])

  return (
    <ModalWithCancel
      title={translate('confirmModalUpdatePointOfSale.title')}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={onConfirm}
      actionName={translate('buttons.confirm')}
      cancelName={translate('buttons.cancel')}
      maxWidth="lg"
      description={`${translate('confirmModalUpdatePointOfSale.content')} ${data?.name}`}
    />
  )
}

export default UpdatePointOfSaleConfirmationModal