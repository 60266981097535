import React from 'react'
import { Button } from '@material-ui/core'
import ModalBase from '../ModalBase'
import { useTranslate } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%'
  }
}))

const DuplicateNotification = ({ inn, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()

  const customActions = () => (
    <Button
      variant="contained"
      color="primary"
      onClick={props.handleClose}
    >
      {translate('buttons.ok')}
    </Button>
  )

  return (
    <ModalBase
      {...props}
      title={translate('duplicateNotifyModal.title')}
      maxWidth='sm'
      paperStyle={classes.paperStyle}
      customActions={customActions}
    >
      {translate('duplicateNotifyModal.content', { inn })}
    </ModalBase>
  )
}

export default DuplicateNotification