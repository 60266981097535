import * as React from 'react'
import {
  TextInput, useTranslate, required, email
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { Field, useFormState } from 'react-final-form'
import FullNameField from './components/FullNameField'
import PhoneField from './components/PhoneField'

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,
    width: '100%',
    padding: '9px 16px 25px',
    maxWidth: '1000px',
    flex: 'unset',
    [theme.breakpoints.down('md')]: {
      width: '85%',
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      width: 'unset',
      maxWidth: 400,
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
      gridColumnGap: theme.spacing(1),
    },
  },
  input: {
    marginTop: 8,
    marginBottom: 4,
  },
  lastName: {
    gridColumn: '1 / 2'
  },
  field: {
    position: 'relative',
    boxSizing: 'border-box',
    height: 56,
    padding: '18px 12px 10px',
  }
}))

const validRequired = [required()]
const validEmail = [required(), email()]

const Form = ({ mode }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { values } = useFormState()

  const renderPhone = (props) => (
    <PhoneField
      {...props}
    />
  )

  const renderFullName = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
  }) => (
    <FullNameField
      inputProps={inputProps}
      label={translate('fields.fullName')}
    />
  )
  return (
    <div className={classes.formControl}>
      {mode === 'edit' && (
        <div className={classes.field}>
          <label className={'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-filled MuiFormLabel-filled'}>
            {translate('fields.isActive')}
          </label>
          <span>
            {values.active ? translate('fields.yes') : translate('fields.no')}
          </span>
        </div>
      )}
      <TextInput
        className={`${classes.input} ${classes.lastName}`}
        source='lastName'
        label={translate('fields.lastName')}
        validate={validRequired}
        helperText=''
      />
      <TextInput
        className={classes.input}
        source='firstName'
        label={translate('fields.firstName')}
        validate={validRequired}
        helperText=''
      />
      <TextInput
        className={classes.input}
        source='middleName'
        label={translate('fields.middleName')}
        helperText=''
      />
      <Field
        name="name"
        render={renderFullName}
      />
      <Field
        name="phone"
        render={renderPhone}
      />
      <TextInput
        className={classes.input}
        source='email'
        label={translate('fields.email')}
        validate={validEmail}
        helperText={translate('fields.helpertextEmail')}
        disabled={mode === 'edit'}
      />
    </div>
  )
}

export default Form