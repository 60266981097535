import React from 'react'
import { useCallback, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { useTranslate, useNotify } from 'react-admin'
import {
  toggleModalCreatorSupplier
} from '../../reducer/actions'
import ModalBase from '../ModalBase'
import { checkSupplierDuplicate, createSupplier } from '../../api/customApi'
import DuplicateNotification from '../DuplicateNotification'

const useStyles = makeStyles((theme) => ({
  formControl: {
    position: 'relative',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100vw - 48px)',
    }
  },
  paperStyle: {
    width: '100%',
  },
  input: {
    width: '100%',
    marginTop: 8,
    '& .MuiFormHelperText-root': {
      height: 58,
      marginRight: 8,
      marginLeft: 8,
      whiteSpace: 'pre-wrap'
    }
  }
}))

const ModalSupplierCreator = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const history = useHistory()
  const dispatch = useDispatch()

  const constants = useSelector(state => state.customReducer.constants)
  const isOpenModalCreatorSupplier = useSelector(
    (state) => state.customReducer.isOpenModalCreatorSupplier
  )

  const [inn, setInn] = useState('')
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState('ra.validation.required')
  const [loading, setLoading] = useState(false)
  const [isOpenDuplicateNotification, setOpenDuplicateNotification] = useState(false)

  useEffect(() => {
    if (!isOpenModalCreatorSupplier && !isOpenDuplicateNotification) {
      setError('ra.validation.required')
      setInn('')
      setTouched(false)
    }
  }, [isOpenModalCreatorSupplier, isOpenDuplicateNotification])

  const onBlur = useCallback(() => {
    setTouched(true)
  }, [])

  const onChange = useCallback((e) => {
    const maxLength = constants?.SOLE_TRADER_TIN_LENGTH >= constants?.JURIDICAL_PERSON_TIN_LENGTH ?
      constants?.SOLE_TRADER_TIN_LENGTH : constants?.JURIDICAL_PERSON_TIN_LENGTH
    const value = e.target.value.slice(0, maxLength)
    validate(value)
    setInn(value)
  }, [constants])

  const handleClose = useCallback(() => {
    dispatch(toggleModalCreatorSupplier(false))
  }, [])

  const validate = useCallback((inn) => {
    let error = ''

    if (!inn) {
      error = 'ra.validation.required'
    } else if (!/^[0-9]+$/gm.test(inn)) {
      error = 'ra.validation.number'
    } else if (
      inn.length !== constants?.SOLE_TRADER_TIN_LENGTH &&
      inn.length !== constants?.JURIDICAL_PERSON_TIN_LENGTH
    ) {
      error = translate(
        'errors.inn', 
        { 
          legalLength: constants?.JURIDICAL_PERSON_TIN_LENGTH,
          individualLength: constants?.SOLE_TRADER_TIN_LENGTH 
        })
    }

    setError(error)
    return error
  }, [inn, constants])

  const handleSave = useCallback(async () => {
    onBlur()
    if (!validate(inn)) {
      setLoading(true)
      const hasInn = await checkSupplierDuplicate(inn)
      if (hasInn) {
        setOpenDuplicateNotification(true)
      }
      const response = await createSupplier({ inn })
      if (response.errorMessage) {
        notify(response.errorMessage, 'warning')
      }
      if (response.responseBody) {
        history.push(`/suppliers/${response.responseBody.id}`)
        dispatch(toggleModalCreatorSupplier(false))
      }
      setLoading(false)
    }
  }, [inn])

  const handleCloseDuplicateNotification = useCallback(() => {
    setOpenDuplicateNotification(prev => !prev)
  }, [])

  return (
    <>
      <ModalBase
        title={translate('createSupplierModal.title')}
        handleClose={handleClose}
        isOpen={isOpenModalCreatorSupplier}
        contentClasses={classes.formControl}
        paperStyle={classes.paperStyle}
        maxWidth='xs'
        handleSave={handleSave}
        actionName={'buttons.create'}
        loading={loading}
      >
        <TextField
          className={classes.input}
          variant="filled"
          label={translate('fields.inn')}
          onChange={onChange}
          onBlur={onBlur}
          value={inn}
          helperText={(touched && translate(error)) || translate('errors.inn', { legalLength: 10, individualLength: 12 })}
          error={touched && error}
          required
        />
      </ModalBase>
      <DuplicateNotification
        isOpen={isOpenDuplicateNotification}
        handleClose={handleCloseDuplicateNotification}
        inn={inn}
      />
    </>
  )
}

export default ModalSupplierCreator