import * as React from 'react'
import { useState, useEffect, useCallback } from 'react'
import { useNotify, useTranslate, useRefresh } from 'react-admin'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress, Button } from '@material-ui/core'
import { previewInformationLetter, createInformationLetter } from '../../../../../../../../api/customApi'
import ModalBase from '../../../../../../../../components/ModalBase'
import {isJson} from "../../../../../../../../api/utils";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%',
  },
  content: {
    height: 550
  },
  hiddenButton: {
    display: 'none'
  }
}))

const FileModal = ({ isOpen, handleClose, form }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const refresh = useRefresh()

  const [iframeSrc, setIframeSrc] = useState('')
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (isOpen) {
      getFile()
    } else {
      setIframeSrc('')
    }
  }, [isOpen, form])

  const getFile = useCallback(async () => {
    try {
      const body = {
        informationLetter: {
          ...form,
          validFrom: form.validFrom ? moment(form.validFrom).format('YYYY-MM-DD') : null
        },
        posList: []
      }
      if (form.id === null) {
        delete body.informationLetter.id
      }
      delete body.informationLetter.file

      const res = await previewInformationLetter(body)
      let text = await res.text()
      if (isJson(text)) {
        const json = JSON.parse(text);
        if (json.errorMessage) {
          text = json.errorMessage
        }
      }
      setIframeSrc(text)
    } catch (err) {
      notify(err.message, 'warning')
    }
  }, [form])

  const onDownload = useCallback(async () => {

  }, [])

  const onConfirm = useCallback(async () => {
    setSending(() => true);
    try {
      const body = {
        informationLetter: {
          ...form,
          validFrom: form.validFrom ? moment(form.validFrom).format('YYYY-MM-DD') : null
        },
        posList: []
      }
      if (form.id === null) {
        delete body.informationLetter.id
      }
      delete body.informationLetter.file

      await createInformationLetter(body)
      handleClose()
      notify('notify.dataProvided')
      refresh()
    } catch (err) {
      notify(err.message, 'warning')
    }
    setSending(() => false);
  }, [form])

  const customActions = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={onConfirm}
        disabled={sending}
      >
        {translate('buttons.sendInfoletter')}
      </Button>
      <Button
        variant="contained"
        onClick={onDownload}
        className={classes.hiddenButton}
        disabled
      >
        {translate('buttons.download')}
      </Button>
    </>
  )

  return (
    <ModalBase
      title={translate('formSuppliers.tabs.informationLetter')}
      isOpen={isOpen}
      handleClose={handleClose}
      maxWidth="lg"
      paperStyle={classes.paperStyle}
      contentClasses={classes.content}
      customActions={customActions}
    >
      {iframeSrc ?
        <iframe
          srcdoc={iframeSrc}
          width="100%"
          height="99%"
          frameBorder="0"
        /> : <CircularProgress size={28} />
      }
    </ModalBase>
  )
}

export default FileModal