import { attachFilesToDataObjects, attorneyLettersProcessing } from '../../../helpers/utils'

export const transform = (constants) => async (data) => {
  const attorneyLettersWithCorrectDate = { ...data }
  attorneyLettersWithCorrectDate.validFrom = data.date.validFrom
  attorneyLettersWithCorrectDate.validUntil = data.date.validUntil
  attorneyLettersWithCorrectDate.issueDate = data.date.issueDate
  delete attorneyLettersWithCorrectDate.date
  const attorneyLettersWithoutFiles = attorneyLettersProcessing([attorneyLettersWithCorrectDate], constants)
  const [ attorneyLetters ] = await attachFilesToDataObjects([attorneyLettersWithoutFiles])
  return attorneyLetters[0]
}