import React, { useCallback } from 'react'
import { FormControl, TextField } from '@material-ui/core'

const FounderTextField = ({
                            label,
                            field,
                            values,
                            errors,
                            blurred,
                            onBlur,
                            setValues,
                            validate,
                            required,
                            disabled,
                            formClass
}) => {
  const handleInput = useCallback((e) => {
    const value = e.target.value
    const errorsKeys = Object.keys(errors)

    if (errorsKeys.find((item) => item === field)) {
      validate(field, value, required)
    }
    const valuesNew = {
      ...values,
      [field]: value,
    }
    setValues(valuesNew)
  }, [values, errors])

  return (
    <FormControl className={formClass}>
      <TextField
        label={label}
        value={values[field]}
        onChange={handleInput}
        onBlur={onBlur(field, required)}
        variant="filled"
        error={blurred[field] && errors[field]}
        helperText={blurred[field] ? errors[field] : ""}
        required={required}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default FounderTextField