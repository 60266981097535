import * as React from 'react'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import {
  Button
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import ModalBase from '../../../../../../../../components/ModalBase'

const ConfirmationModal = ({ isOpen, toggle, onSave }) => {
  const translate = useTranslate()

  const customActions = useCallback(() => {
    const handleSave = async () => {
      await onSave()
      toggle()
    }
    return (
      <>
        <Button
          variant="contained"
          onClick={toggle}
        >
          {translate('buttons.comeBack')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          onClick={handleSave}
        >
          {translate('buttons.save')}
        </Button>
      </>
    )
  }, [onSave, toggle])
  return (
    <ModalBase
      isOpen={isOpen}
      handleClose={toggle}
      title={translate('attorneyLettersConfirmationModal.title')}
      customActions={customActions}
    >
      {translate('attorneyLettersConfirmationModal.content')}
    </ModalBase>
  )
}

export default ConfirmationModal