import * as React from 'react'
import {
  Edit, SimpleForm, useTranslate
} from 'react-admin'
import Form from '../components/Form'
import PoSToolbar from '../components/Toolbar'
import {requestChangedFields} from '../../../api/customApi'
import {getChangedFields} from "../index";
import {useState} from "react";

const PointOfSaleEdit = props => {
  const translate = useTranslate()
  const [changesLoading, setChangesLoading] = useState(true)

  if (changesLoading) {
    requestChangedFields(props.id).then(result => {
      getChangedFields()[props.id] = result[props.id]
      setChangesLoading(false)
    })
  }

  return (
    <Edit
      {...props}
      title={translate('resources.pointsOfSale.edit')}
    >
      <SimpleForm
        toolbar={
          <PoSToolbar mode="edit" needConfirmAndSave={getChangedFields()[props.id]} />
        }
      >
        <Form
          id={props.id}
          mode="edit"
        />
      </SimpleForm>
    </Edit>
  )
}

export default PointOfSaleEdit