import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useDataProvider, useTranslate, useRefresh, useNotify } from 'react-admin'
import {
  setVisibilityModalConfirmationPointsOfSale,
  setUpdatePointsOfSaleData,
  setSaveWithoutConfirmPointsOfSale,
  setSendAfterSavePointsOfSale
} from '../../reducer/actions'
import { transform } from '../../forms/PointsOfSale/utils'
import { sendPOSes } from '../../api/customApi'
import ModalWithCancel from "../ModalWithCancel";

const UpdatePointsOfSaleConfirmationModal = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  const [isOpen, setOpen] = useState(false)

  const isOpenModal = useSelector(state => state.customReducer.updatePointsOfSale.isOpenModal)
  const saveWithoutConfirm = useSelector(state => state.customReducer.updatePointsOfSale.saveWithoutConfirm)
  const data = useSelector(state => state.customReducer.updatePointsOfSale.data)
  const sendAfterSave = useSelector(state => state.customReducer.updatePointsOfSale.sendAfterSave)
  const pointOfSaleStatuses = useSelector(state => state.customReducer.pointOfSaleStatuses)
  const constants = useSelector(state => state.customReducer.constants)

  useEffect(() => {
    let isOpen = false
    const dataIds = Object.keys(data)
    if (isOpenModal && dataIds.length) {
      isOpen = true
    } else if (saveWithoutConfirm && dataIds.length) {
      onConfirm()
    } else {
      dispatch(setVisibilityModalConfirmationPointsOfSale(false))
    }
    setOpen(isOpen)
  }, [data, isOpenModal, saveWithoutConfirm])

  const handleClose = useCallback(async () => {
    if (sendAfterSave) {
      try {
        await sendPOSes()
        refresh()
        notify('notify.dataSent')
      } catch (err) {
        notify(err.message, 'warning')
      }
    }

    dispatch(setVisibilityModalConfirmationPointsOfSale(false))
    dispatch(setUpdatePointsOfSaleData([]))
    dispatch(setSendAfterSavePointsOfSale(false))
    setOpen(false)
  }, [sendAfterSave])

  const onConfirm = useCallback(async () => {
    try {
      const transformedData = data.map(item => (
        transform({ constants, pointOfSaleStatuses, mode: 'edit' })(item)
      ))
      await dataProvider.updateMany('pointsOfSale', { data: transformedData })
      if (sendAfterSave) {
        await sendPOSes()
      }
      refresh()
      if (sendAfterSave) {
        notify('notify.dataSavedAndSent')
      } else {
        notify('notify.dataSaved')
      }
      dispatch(setVisibilityModalConfirmationPointsOfSale(false))
      dispatch(setSaveWithoutConfirmPointsOfSale(false))
      dispatch(setUpdatePointsOfSaleData([]))
      dispatch(setSendAfterSavePointsOfSale(false))
      setOpen(false)
    } catch (err) {
      notify(err.message, 'warning')
    }
  }, [data, data, sendAfterSave])

  return (
    <ModalWithCancel
      title={translate('confirmModalUpdatePointsOfSale.title')}
      isOpen={isOpen}
      handleClose={handleClose}
      handleSave={onConfirm}
      actionName={translate('buttons.confirm')}
      cancelName={translate('buttons.cancel')}
      maxWidth="lg"
      description={`${translate('confirmModalUpdatePointsOfSale.content')} ${data?.map(item => item.name).join(', ')}`}
    />
  )
}

export default UpdatePointsOfSaleConfirmationModal