import React, {useCallback} from 'react'
import { useFormState } from 'react-final-form'
import { required, SelectInput, useTranslate } from 'react-admin'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { yesNoOptions } from '../../../../../../../../helpers/enums'

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
  formControl: {
    marginTop: 8,
    marginBottom: 4
  },
}))

const validateRequired = [required()]

const VatPayer = ({
                    disabled,
                    meta: { touched, error } = { touched: false, error: undefined },
                    input: { ...inputProps },
                    ...props
                  }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { values, errors } = useFormState()

  const onChange = useCallback((e) => {
    const value = {
      ...values.client,
      vatPayer: e.target.value
    }
    if (value.vatPayer === 'YES') {
      value.vatPaymentAbsenceBasis = null
    } else {
      value.biggestTaxpayer = null
      value.biggestTaxpayerKpp = null
    }

    inputProps.onChange({
      target: {
        value
      }
    })
  }, [inputProps, values.client])

  return (
    <FormControl
      className={classes.formControl}
      error={errors.client?.vatPayer}
      disabled={disabled}
    >
      <InputLabel required>
        {translate('fields.vatPayer')}
      </InputLabel>
      <Select
        className={classes.input}
        variant="filled"
        value={values.client?.vatPayer ?? ''}
        onChange={onChange}
        onBlur={inputProps.onBlur}
        disabled={disabled}
      >
        {yesNoOptions.map(item => (
          <MenuItem value={item.value}>
            {translate(item.name)}
          </MenuItem>
        ))}
      </Select>
      <SelectInput
        source="client.vatPayer"
        choices={yesNoOptions}
        validate={validateRequired}
        disabled
        style={{
          display: 'none'
        }}
      />
    </FormControl>
  )
}

export default VatPayer