import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  Filter, SelectInput, ReferenceInput, useTranslate, AutocompleteInput, TextInput
} from 'react-admin'
import { yesNoOptions } from '../../../../helpers/enums'
import {Chip} from '@material-ui/core'

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  return <Chip
    sx={{ marginBottom: 1 }}
    label={translate(label)}
  />;
};

const Filters = (props) => {
  const translate = useTranslate()
  const pointOfSaleStatuses = useSelector(state => state.customReducer.pointOfSaleStatuses)

  return (
    <Filter {...props}>
      <TextInput
        label={translate('fields.namePointOfSale')}
        source="name"
      />
      <TextInput
        label={translate('fields.addressPointOfSale')}
        source="legalAddress.fullName"
      />
      <TextInput
        label={translate('fields.inn')}
        source="posInn"
      />
      <TextInput
        label={translate('fields.legalName')}
        source="registeredName"
      />
      <SelectInput
        label={translate('fields.status')}
        source="status.id"
        choices={pointOfSaleStatuses}
        optionValue="id"
      />
      <ReferenceInput
        label={translate('fields.supplier')}
        source='supplier.id'
        reference='suppliers'
        filterToQuery={(searchText) => ({
          notConvert: true,
          filters: [{
            group: "AND",
            conditions: [
              {
                property: 'client.organizationName',
                operator: 'contains',
                value: searchText
              }
            ]
          }]
        })}
      >
        <AutocompleteInput
          optionText={(item) => item?.client?.organizationName}
        />
      </ReferenceInput>
      <SelectInput
        label={translate('fields.includedInInformationLetter')}
        source="includedInInformationLetter"
        choices={yesNoOptions.map(item => ({ ...item, value: item.value === 'YES' }))}
        optionValue="value"
      />
      <QuickFilter
        label="buttons.pointOfSalesForConfirmation"
        source="confirmed"
        defaultValue={false}
      />
    </Filter>
  )
}

export default Filters