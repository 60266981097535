import List from './List'
import Edit from './Edit'
import Create from './Create'
import PeopleOutline from '@material-ui/icons/PeopleOutline'

export default {
  list: List,
  edit: Edit,
  create: Create,
  icon: PeopleOutline,
  options: {
    label: 'menuItem.contactPersons'
  }
}