import { baseApiUrl, customApiUrl } from "./httpDataProvider";
import { getData } from "./basicAuthProvider";
import { getChangedFields } from "../forms/PointsOfSale";
const passwordApiUrl = baseApiUrl + "password";

const httpClient = (url, options = {}) => {
  options.headers = new Headers({ Accept: "application/json" });
  options.headers.set("Content-Type", "application/json");
  return getData(url, options).then(res => res);
};

export const createSupplier = data =>
  httpClient(`${customApiUrl}/supplier/create`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(res => res.json());

export const getBank = bic =>
  httpClient(`${customApiUrl}/getBank?bic=${bic}`).then(res => res.json());

export const getSupplierFounderByTin = tin =>
  httpClient(`${customApiUrl}/getSupplierFounder?tin=${tin}`).then(res =>
    res.json()
  );

export const updateUser = data =>
  httpClient(`${customApiUrl}/user`, {
    method: "PUT",
    body: JSON.stringify(data)
  }).then(res => res.json());

export const recoveryPassword = email => {
  return fetch(`${passwordApiUrl}/reset`, {
    method: "PUT",
    body: JSON.stringify({ email }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(res => res.json());
};

export const checkPassword = (email, password) => {
  return fetch(`${passwordApiUrl}/checkPassword`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(res => res.json());
};

export const getUserInfo = token => {
  return fetch(`${passwordApiUrl}/getUser?otpToken=${token}`, {
    method: "GET"
  }).then(res => res.json());
};

export const updateUserInfo = data => {
  return fetch(`${passwordApiUrl}/updateUser`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json"
    }
  }).then(res => res.json());
};

export const updatePassword = data =>
  httpClient(`${customApiUrl}/user/updatePassword`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(res => res.json());

export const checkSupplierDuplicate = inn =>
  httpClient(`${customApiUrl}/supplier/hasInn?inn=${inn}`).then(res =>
    res.json()
  );

export const verifyAttorneyList = ({ id, data }) =>
  httpClient(`${customApiUrl}/supplier/verifyAttorneyList?supplierId=${id}`, {
    method: "PUT",
    body: JSON.stringify(data)
  }).then(res => res.json());

export const refreshSupplierData = id =>
  httpClient(`${customApiUrl}/supplier/refreshData?supplierId=${id}`, {
    method: "PUT"
  }).then(res => res.json());

export const verifyAttorneyLetter = data =>
  httpClient(
    `${customApiUrl}/attorneyLetter/verify${data.id ? `?id=${data.id}` : ""}`,
    {
      method: "PUT",
      body: JSON.stringify(data)
    }
  ).then(res => res.json());

export const previewInformationLetter = data =>
  httpClient(`${customApiUrl}/informationLetter/preview`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(res => res.blob());

export const createInformationLetter = data =>
  httpClient(`${customApiUrl}/informationLetter/create`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(res => {
    if (res.status === 500) {
      throw new Error("errors.errorDuringInformationLetterCreation");
    }
    return res.json();
  });

export const sendPOSes = data =>
  httpClient(`${customApiUrl}/pointOfSale/send`, {
    method: "PUT",
    body: JSON.stringify(data)
  }).then(res => res.json());

export const exportPOSToExcel = data => {
  const options = {
    method: "GET",
    headers: new Headers()
  };
  options.headers.set("Response-Type", "blob");
  options.headers.set("Content-Type", "application/octet-stream");
  return getData(`${customApiUrl}/pointOfSale/exportToExcel`, options)
    .then(res => {
      if (res.status === 500) {
        throw new Error("errors.errorDuringPOSExport");
      }
      return res.blob();
    })
    .then(blob => {
      const file = window.URL.createObjectURL(blob);
      window.open(file, "_blank").focus();
    });
};

export const requestChangedFields = posId => {
  return httpClient(
    `${customApiUrl}/pointOfSale/getChangedPosIdFields?posId=${
      posId ? posId : "null"
    }`,
    {
      method: "GET"
    }
  ).then(res => res.json());
};

export const sendConfirmChanges = data => {
  return httpClient(`${customApiUrl}/pointOfSale/confirmChanges`, {
    method: "PUT",
    body: JSON.stringify(data)
  }).then(res => res.json());
};

export const fieldWasChanged = (id, field) => {
  return fieldWasChangedByName(id, field.props.source);
};

export const fieldWasChangedByName = (id, fieldName) => {
  return (
    getChangedFields()[id] != null &&
    getChangedFields()[id].indexOf(fieldName) > -1
  );
};

export const postLogs = async (component, level, error) => {
  const isCurrentUserAllowLog = localStorage.getItem("isCurrentUserAllowLog");
  const logLevel = localStorage.getItem("logLevel");
  if (isCurrentUserAllowLog === "true" && (logLevel === "ALL" || (logLevel === "ERROR" && logLevel === level))) {
    const browser = window.navigator.userAgent;
    const body = JSON.stringify({
      type: level,
      browser,
      component,
      message: {
        log: error.message,
        stack: error.stack
      }
    });
    return httpClient(`${customApiUrl}/log`, {
      method: "POST",
      body: body
    });
  }
};
