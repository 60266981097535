import * as React from 'react'
import { createElement } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  MenuItemLink,
  getResources,
  useTranslate
} from 'react-admin'
import { MenuItem, ListItemIcon, Typography } from '@material-ui/core'
import ViewListIcon from '@material-ui/icons/ViewList'
import SettingsIcon from '@material-ui/icons/Settings'
import HelpIcon from '@material-ui/icons/Help'
import AddBoxIcon from '@material-ui/icons/AddBox'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'

import Submenu from './components/Submenu'
import { toggleModalCreatorSupplier } from '../../reducer/actions'

const useStyles = makeStyles(theme => ({
  menu: {
    marginTop: '0.75rem',
  },
  menuIcon: {
    minWidth: 40
  },
  linkText: {
    whiteSpace: 'normal',
    lineHeight: 1.2
  },
  active: {
    background: '#BBDEFB',
    '&:hover': {
      background: '#B3D5F1',
    }
  }
}))

const Menu = ({ onMenuClick, logout, dense = false }) => {
  const classes = useStyles()
  const history = useHistory()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const translate = useTranslate()
  const dispatch = useDispatch()
  const featureFlags = useSelector(state => state.customReducer.featureFlags)
  const resources = useSelector(getResources).filter(resource => (
    (resource.hasList || resource.hasShow) && resource.options?.enable !== false)
  )
  useSelector((state) => state.theme) // force rerender on theme change

  const handleSupplierCreator = () => {
    dispatch(toggleModalCreatorSupplier(true))
  }

  return (
    <div id="ra-menu" className={classes.menu}>
      <Submenu
        sidebarIsOpen={open}
        name={translate('menuItem.suppliers')}
        icon={<ViewListIcon />}
        dense={dense}
        path="/suppliers"
      >
        <MenuItemLink
          className={/^\/suppliers/.test(history.location.pathname) ? classes.active : ''}
          to="/suppliers"
          primaryText={translate('menuItem.suppliers')}
          leftIcon={<PeopleAltIcon />}
          sidebarIsOpen={open}
        />
        <MenuItem
          primaryText={translate('menuItem.supplierCreator')}
          leftIcon={<AddBoxIcon />}
          sidebarIsOpen={open}
          onClick={handleSupplierCreator}
        >
          <ListItemIcon className={classes.menuIcon}>
            <AddBoxIcon />
          </ListItemIcon>
          <Typography variant="inherit" color="textSecondary">
            {translate('menuItem.supplierCreator')}
          </Typography>
        </MenuItem>
      </Submenu>
      {resources.filter(resource => featureFlags?.features && !featureFlags?.features[resource.name]?.disabled).map(resource => (
        resource.name !== 'suppliers' && (
          <MenuItemLink
            className={new RegExp(`^/${resource.name}`).test(history.location.pathname) ? classes.active : ''}
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              <span className={classes.linkText}>
                {resource.options.label && translate(resource.options.label) || resource.name}
              </span>
            }
            leftIcon={resource.icon ? createElement(resource.icon) : <ViewListIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        )
      ))}
      <MenuItemLink
        className={/^\/settings/.test(history.location.pathname) ? classes.active : ''}
        to="/settings"
        primaryText={translate('menuItem.settings')}
        leftIcon={<SettingsIcon />}
        sidebarIsOpen={open}
      />
      <MenuItemLink
        className={/^\/help/.test(history.location.pathname) ? classes.active : ''}
        to="/help"
        primaryText={translate('menuItem.help')}
        leftIcon={<HelpIcon />}
        sidebarIsOpen={open}
      />
      {isXSmall && logout}
    </div>
  )
}

export default Menu