import * as React from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TextField, useDataProvider, useTranslate } from 'react-admin'
import Datagrid from '../PointsOfSaleDatagrid'

const PointsOfSale = () => {
  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const { values } = useFormState()

  const loading = useSelector(state => state.admin.loading)
  const constants = useSelector(state => state.customReducer.constants)
  const pointOfSaleStatuses = useSelector(state => state.customReducer.pointOfSaleStatuses)

  const [pointsOfSale, setPointsOfSale] = useState([])

  useEffect(() => {
    if (pointOfSaleStatuses.length && Object.keys(constants).length) {
      getPointsOfSale()
    }
  }, [pointOfSaleStatuses, constants])

  const getPointsOfSale = useCallback(async () => {
    const notConfirmedIdentifier = pointOfSaleStatuses.find(status => (
      status.identifier === constants.NOT_CONFIRMED_POS_STATUS_I
    )).identifier

    const pointsOfSale  = await dataProvider.getList('pointsOfSale', {
      filter: {
        filters: [{
          group: 'AND',
          conditions: [
            {
              property: 'status.identifier',
              operator: '=',
              value: notConfirmedIdentifier
            },
            {
              property: 'supplier.id',
              operator: '=',
              value: values.id
            },
            {
              property: 'includedInInformationLetter',
              operator: '=',
              value: true
            }
          ]
        }],
        notConvert: true
      }
    })

    setPointsOfSale(pointsOfSale.data)
  }, [pointOfSaleStatuses, constants])

  const [data, ids] = useMemo(() => {
    const data = {}
    const ids = []
    pointsOfSale.forEach(point => {
      data[point.id] = point
      ids.push(point.id)
    })
    return [data, ids]
  }, [pointsOfSale])

  return (
    <>
      <Datagrid
        basePath=""
        currentSort={{}}
        data={data}
        ids={ids}
        selectedIds={[]}
        size="medium"
        loaded={loading === 0}
      >
        <TextField
          label={translate('fields.namePointOfSale')}
          source="name"
        />
        <TextField
          label={translate('fields.addressPointOfSale')}
          source="legalAddress.fullName"
        />
        <TextField
          label={translate('fields.inn')}
          source="posInn"
        />
        <TextField
          label={translate('fields.legalName')}
          source="registeredName"
        />
        <TextField
          label={translate('fields.status')}
          source="status.name"
        />
        <TextField
          label={translate('fields.includedInInformationLetter')}
          source="includedInInformationLetter"
        />
      </Datagrid>
    </>
  )
}

export default PointsOfSale