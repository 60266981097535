import * as React from 'react'
import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CreateButton,
  TopToolbar,
  useListContext,
  useTranslate,
  useNotify,
  useRefresh
} from 'react-admin'
import { Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { makeStyles } from '@material-ui/core/styles'
import {
  setSaveWithoutConfirmPointsOfSale,
  setVisibilityModalConfirmationPointsOfSale,
  setSendAfterSavePointsOfSale
} from '../../../../reducer/actions'
import CustomAction from '../../../../components/CustomAction'
import {sendPOSes, exportPOSToExcel} from '../../../../api/customApi'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1)
  },
  topToolBar: {
    display: 'flex',
    alignItems: 'center'
  },
  hiddenButton: {
    display: 'none'
  }
}))

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    isDisabledSaveChanges,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()
  const refresh = useRefresh()

  const dataUpdatePointsOfSale = useSelector(state => state.customReducer.updatePointsOfSale.data)
  const loading = useSelector(state => state.admin.loading)

  const [sendLoading, setSendLoading] = useState(false)

  const onUpdatePOSes = useCallback(async () => {
    dispatch(setSaveWithoutConfirmPointsOfSale(true))
  }, [dataUpdatePointsOfSale])

  const onSendPOSes = useCallback(async () => {
    if (dataUpdatePointsOfSale.length) {
      dispatch(setSendAfterSavePointsOfSale(true))
      dispatch(setVisibilityModalConfirmationPointsOfSale(true))
    } else {
      try {
        setSendLoading(true)
        await sendPOSes()
        refresh()
        notify('notify.dataSent')
      } catch (err) {
        notify(err.message, 'warning')
      }
      setSendLoading(false)
    }
  }, [dataUpdatePointsOfSale])

  const onExportPOSToExcel = useCallback(async () => {
    try {
      setSendLoading(true)
      await exportPOSToExcel()
    } catch (err) {
      notify(err.message, 'warning')
    }
    setSendLoading(false)
  })

  return (
    <TopToolbar
      {...props}
      id="list-actions-light"
      className={classes.topToolBar}
    >
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton
        className={classes.hiddenButton}
        disabled
      />
      <CustomAction
        label={translate('buttons.saveChanges')}
        icon={<SaveIcon />}
        onAction={onUpdatePOSes}
        disabled={loading !== 0 || isDisabledSaveChanges}
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onSendPOSes}
        disabled={loading !== 0 || sendLoading}
      >
        {translate('buttons.sendPOSes')}
      </Button>

      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={onExportPOSToExcel}
        disabled={loading !== 0 || sendLoading}
      >
        {translate('buttons.exportPOSToExcel')}
      </Button>
    </TopToolbar>
  )
}

export default ListActions