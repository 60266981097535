import * as React from 'react'
import { Admin, Resource } from 'react-admin'
import httpDataProvider from './api/httpDataProvider'
import authProvider from './api/basicAuthProvider'
import Layout from './layouts/Layout'
import Login from './layouts/Login'
import customRoutes from './routes'
import customReducer from './reducer'

import polyglotI18nProvider from 'ra-i18n-polyglot'
import customEnglishMessages from './i18n/en'
import customRussianMessages from './i18n/ru'

import { languageOptions } from './helpers/enums'

import suppliers from './forms/Suppliers'
import users from './forms/Users'
import attorneyLetters from './forms/AttorneyLetters'
import pointsOfSale from './forms/PointsOfSale'
import distributionCenters from './forms/DistributionCenters'

let langDefault = localStorage.getItem('lang')

if (langDefault === null) {
  langDefault = languageOptions[0].value
}

export const i18nProvider = polyglotI18nProvider(locale => (
  locale === 'en' ? customEnglishMessages : customRussianMessages
), langDefault)

const originalRemoveItem = localStorage.removeItem
const originalSetItem = localStorage.setItem

localStorage.removeItem = function () {
  const eventRemoveAuth = new Event('onRemoveAuthFromLocalStorage')
  originalRemoveItem.apply(this, arguments)
  switch(arguments[0]) {
    case 'tokenData': {
      document.dispatchEvent(eventRemoveAuth)
      break
    }
  }
}

localStorage.setItem = function () {
  const eventSetUserId = new Event('onSetUserId')
  originalSetItem.apply(this, arguments)
  switch(arguments[0]) {
    case 'userId': {
      document.dispatchEvent(eventSetUserId)
    }
  }
}

const App = () => {
  return (
    <Admin
      customReducers={{ customReducer }}
      customRoutes={customRoutes}
      layout={Layout}
      authProvider={authProvider}
      dataProvider={httpDataProvider}
      i18nProvider={i18nProvider}
      loginPage={Login}
      disableTelemetry
    >
      <Resource name='suppliers' {...suppliers} />
      <Resource name='organizationTypes' />
      <Resource name='clients' />
      <Resource name='constituentDocs' />
      <Resource name='legalAddresses' />
      <Resource name='vatPaymentAbsenceBasises' />
      <Resource name='users' {...users} />
      <Resource name='tradeAccounts' />
      <Resource name='persons' />
      <Resource name='providerStatuses' />
      <Resource name='attorneyLetters' {...attorneyLetters} />
      <Resource name='pointsOfSale' {...pointsOfSale} />
      <Resource name='distributionCenters' {...distributionCenters} />
      <Resource name='supplierFounders' />
    </Admin >
  )
}

export default App
