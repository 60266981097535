import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  Filter, SelectInput, ReferenceInput, NumberInput,
  useTranslate, AutocompleteInput, TextInput, DateInput
} from 'react-admin'
import { yesNoOptions } from '../../../../helpers/enums'

const Filters = (props) => {
  const translate = useTranslate()
  const organizationTypes = useSelector(state => state.customReducer.organizationTypes)
  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)

  return (
    <Filter {...props}>
      <SelectInput
        label={translate('fields.organizationType')}
        source="organizationType.id"
        choices={organizationTypes}
        optionValue="id"
      />
      <TextInput
        label={translate('fields.organizationName')}
        source="client.organizationName"
      />
      <TextInput
        label={translate('fields.organizationFullName')}
        source="client.organizationFullName"
      />
      <TextInput
        label={translate('fields.email')}
        source="client.email"
      />
      <TextInput
        label={translate('fields.inn')}
        source="client.inn"
      />
      <ReferenceInput
        label={translate('fields.initiator')}
        source='client.initiator.id'
        reference='users'
      >
        <AutocompleteInput
          optionText={(item) => item.name}
        />
      </ReferenceInput>
      <SelectInput
        label={translate('fields.status')}
        source="client.providerStatus.identifier"
        choices={providerStatuses}
        optionValue="identifier"
      />
      <DateInput
        label={translate('fields.presentationDate')}
        source='client.presentationDate'
      />
      <ReferenceInput
        label={translate('fields.tradeAccount')}
        source='client.tradeAccount.id'
        reference='tradeAccounts'
      >
        <AutocompleteInput
          optionText={(item) => item.name}
        />
      </ReferenceInput>
    </Filter>
  )
}

export default Filters