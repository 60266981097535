import {getData} from "../basicAuthProvider";

class HttpError extends Error {
  constructor(name, message) {
    super(message)
    Object.setPrototypeOf(this, HttpError.prototype)
    this.name = name
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
    this.stack = new Error().stack
  }
}

export function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const fetchJson = (url, options = {}) => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    }))
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json')
  }

  return getData(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json
      try {
        json = JSON.parse(body)
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300 || json?.errorMessage) {
        return Promise.reject(
          new HttpError(
            json && (json.errorMessage || json.message || json.error) || statusText,
            json && (json.errorMessage || json.details)
          )
        )
      }
      return Promise.resolve({ status, headers, body, json })
    })
}