import * as React from 'react'
import { useMemo } from 'react'
import { Datagrid, FunctionField, TextField, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { arrayToObject } from '../../../../../../utils'
import { downloadDocument } from '../../../../../../../../helpers/utils'

const useStyles = makeStyles((theme) => ({
  fileName: {
    color: '#0000FF',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  datagrid: {
    marginBottom: 60
  }
}))

const InfoLetterDatagrid = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const { values } = useFormState()

  const renderFile = record => (
    record.file ? (
      <a
        className={classes.fileName}
        onClick={downloadDocument(record.file)}
      >
        {record.file.name}
      </a>
    ) : ''
  )

  const data = useMemo(() => {
    const filteredData = values.informationLetters?.filter(letter => letter.id)
    return (
      filteredData?.length ?
        arrayToObject(filteredData.sort((letter1, letter2) => (
          moment(letter2.presentationDate).valueOf() - moment(letter1.presentationDate).valueOf()
        ))) : {}
    )
  }, [values.informationLetters])

  const ids = useMemo(() => {
    const filteredData = values.informationLetters?.filter(letter => letter.id)
    return (
      filteredData?.length ? (
        filteredData.sort((letter1, letter2) => (
          moment(letter2.presentationDate).valueOf() - moment(letter1.presentationDate).valueOf()
        )).map((item, index) => index.toString())
      ) : []
    )
  }, [values.informationLetters])

  return (
    <Datagrid
      className={classes.datagrid}
      basePath=""
      currentSort={{}}
      data={data}
      ids={ids}
      hasBulkActions={false}
      selectedIds={[]}
      size="medium"
    >
      <TextField
        label={translate('fields.document')}
        source="docKind.document"
      />
      <FunctionField
        label={translate('fields.file')}
        render={renderFile}
      />
      <FunctionField
        label={translate('fields.presentationDate')}
        render={record => (
          record.presentationDate ? moment(record.presentationDate).format('DD.MM.YYYY') : ''
        )}
      />
      <TextField
        label={translate('fields.status')}
        source="status.name"
      />
      <TextField
        label={translate('columns.reason')}
        source="reasonForRejection"
      />
    </Datagrid>
  )
}

export default InfoLetterDatagrid