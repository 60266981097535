import * as React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Edit, SimpleForm, useTranslate } from 'react-admin'
import { transform } from '../utils'
import Form from '../components/Form'
import AttorneyLetterToolbar from '../components/Toolbar'

const AttorneyLetterEdit = props => {
  const translate = useTranslate()
  const constants = useSelector(state => state.customReducer.constants)

  const [supplierStatusIdentifier, setSupplierStatusIdentifier] = useState('')

  return (
    <Edit
      {...props}
      transform={transform(constants)}
      title={translate('resources.attorneyLetters.edit')}
    >
      <SimpleForm
        toolbar={
          <AttorneyLetterToolbar
            supplierStatusIdentifier={supplierStatusIdentifier}
            mode="edit"
          />
        }
      >
        <Form
          mode="edit"
          supplierStatusIdentifier={supplierStatusIdentifier}
          setSupplierStatusIdentifier={setSupplierStatusIdentifier}
        />
      </SimpleForm>
    </Edit>
  )
}

export default  AttorneyLetterEdit