import * as React from 'react'
import {useState, useCallback, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Notification, useTranslate, useNotify, useRedirect} from 'react-admin'
import {createTheme, makeStyles, ThemeProvider} from '@material-ui/core/styles'
import {
  Button, Checkbox, FormControlLabel, Paper, TextField, Typography
} from '@material-ui/core'
import {getUserInfo, updateUserInfo} from '../../api/customApi'
import {theme} from '../../layouts/Layout'
import {validatePassword} from "../../helpers/utils";
import moment from "moment";
import {setUserInfo} from "../../reducer/actions";
import PasswordPolicy from "../../components/PasswordPolicy";
import {Card, CardActions} from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    background: '#EFF6FC',
    height: '100vh',
    flexDirection: 'column',
  },
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 22px',
    columnGap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    }
  },
  input: {
    width: '100%',
    '& input': {
      boxSizing: 'border-box',
      height: 46
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0
    },
    marginTop: 8
  },
  label: {
    display: 'block',
    marginBottom: 6,
    fontSize: 12,
    '&:not(.Mui-error)': {
      color: '#000 !important'
    },
    '&.Mui-error': {
      color: '#f44336 !important'
    }
  },
  actions: {
    padding: '16px 0 16px',
  },
  button: {
    height: 42
  },
  textBox: {
    padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    backgroundColor: '#eeeeee',
    [theme.breakpoints.down('md')]: {
      minHeight: 100
    }
  },
  contentClasses: {
    display: 'grid',
    rowGap: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  main: {
    background: '#EFF6FC',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: ({minHeight}) => minHeight,
    alignItems: 'center',
  },
  card: {
    position: 'relative',
    padding: '0 30px 16px',
    width: '60%',
    paddingTop: '16px',
    marginTop: '5vh',
  },
  cardHeader: {
    padding: '24px 30px 16px',
    '& .MuiTypography-root': {
      fontSize: 18,
      fontWeight: 500,
    }
  },
  projectNameWrapper: {
    marginTop: '6vh',
    color: '#1565C0',
    textAlign: 'center',
  },
  projectNameWrapper_title: {
    margin: 0,
    fontSize: 40,
    fontWeight: 700,
  },
  projectNameWrapper_subtitle: {
    margin: 0,
    fontSize: 20,
    fontWeight: 500,
  },
  footer: {
    position: 'relative',
    bottom: '6vh',
    flexDirection: 'column',
    alignItems: 'center'
  },
  footer_logo: {
    display: 'block',
    marginBottom: 20,
    padding: '10vh 25% 0'
  },
  footer_text: {
    margin: 0,
    fontSize: 10,
    fontWeight: 500,
    color: '#1565C0',
    letterSpacing: '0.46px',
    textTransform: 'uppercase'
  },
})

const ConsentPage = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const dispatch = useDispatch()
  const userInfo = useSelector(state => state.customReducer.userInfo)
  const [password, setPassword] = useState({
    newPassword: '',
    confirmNewPassword: ''
  })
  const [focused, setFocused] = useState({
    newPassword: false,
    confirmNewPassword: false
  })
  const [touched, setTouched] = useState({
    newPassword: false,
    confirmNewPassword: false
  })
  const [errors, setErrors] = useState({
    newPassword: 'ra.validation.required',
    confirmNewPassword: 'ra.validation.required'
  })
  const [accept, setAccept] = useState(false)
  const token = props.match.params.token
  useEffect(() => {
    getUserInformation(token)
  }, [token])

  const getUserInformation = useCallback(async (token) => {
    const userInfo = await getUserInfo(token)
    if (userInfo.errorMessage) {
      notify(userInfo.errorMessage, 'warning')
      redirect(`/login`)
    } else {
      dispatch(setUserInfo(userInfo))
    }

  }, [token])

  const onChange = useCallback(field => e => {
    const newPassword = {
      ...password,
      [field]: e.target.value
    }
    validate(newPassword)
    setPassword(newPassword)
  }, [password, errors])

  const validate = useCallback((newPassword) => {
    const newErrors = validatePassword(newPassword, errors, translate)
    setErrors(newErrors)
  }, [errors, password])

  const onBlur = useCallback(field => e => {
    setFocused({
      ...focused,
      [field]: false
    })
    setTouched({
      ...touched,
      [field]: true
    })
  }, [touched, focused])

  const onFocus = useCallback(field => e => {
    setFocused({
      ...focused,
      [field]: true
    })
  }, [focused])

  const toggleAccept = useCallback(() => {
    setAccept(prev => !prev)
  }, [])

  const onAccept = useCallback(async () => {
    if (errors.newPassword || errors.confirmNewPassword) {
      setTouched({
        newPassword: true,
        confirmNewPassword: true
      })
      notify('ra.message.invalid_form', 'warning')
      return
    }
    const userUpdateRes = await updateUserInfo({
      otpToken: userInfo.otpToken,
      password: password.newPassword,
      consentReceived: true,
      consentDate: userInfo.consentDate ? userInfo.consentDate : moment().format('YYYY-MM-DD')
    })
    if (userUpdateRes.id) {
      notify(translate('notify.passwordSaved'), 'info')
      redirect(`/login`)
      return
    }
    if (userUpdateRes.errorMessage) {
      notify(userUpdateRes.errorMessage, 'warning')
      return
    }
    notify('Ошибка сервера', 'warning')
  }, [errors, password])

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.projectNameWrapper}>
          <p className={classes.projectNameWrapper_title}>
            R.ICE
          </p>
          <p className={classes.projectNameWrapper_subtitle}>
            Портал поставщика
          </p>
        </div>
        <Card className={classes.card}>
          <div>
            <Typography variant="subtitle1" gutterBottom>
              {translate('consentModal.passwordSubtitle')}
            </Typography>
            <div className={classes.form}>
              <TextField
                className={classes.input}
                label={translate('fields.newPassword')}
                value={password.newPassword}
                onChange={onChange('newPassword')}
                onBlur={onBlur('newPassword')}
                onFocus={onFocus('newPassword')}
                type="password"
                variant="filled"
                required
                error={touched.newPassword && errors.newPassword}
                helperText={touched.newPassword && translate(errors.newPassword)}
              />
              <TextField
                className={classes.input}
                label={translate('fields.confirmNewPassword')}
                value={password.confirmNewPassword}
                onChange={onChange('confirmNewPassword')}
                onBlur={onBlur('confirmNewPassword')}
                onFocus={onFocus('confirmNewPassword')}
                type="password"
                variant="filled"
                required
                error={touched.confirmNewPassword && errors.confirmNewPassword}
                helperText={touched.confirmNewPassword && translate(errors.confirmNewPassword)}
              />
            </div>
            <PasswordPolicy isShowPasswordPolicy={focused.newPassword || focused.confirmNewPassword}/>
          </div>
          {(userInfo.consentReceived === false) &&
          <>
            <div>
              <Typography variant="subtitle1" gutterBottom>
                {translate('consentModal.consentSubtitle')}
              </Typography>
              <Paper className={classes.textBox}>
                <Typography>
                  Все персональные данные, которые Вы предоставляете на Портале поставщика, будут обрабатываться OOO
                  «Филип Моррис Сэйлз энд Маркетинг» в соответствии с законодательством РФ. Предоставляя данные на
                  Портале поставщика, Вы подтверждаете, что имеете законное основание и Вами получено надлежащее
                  согласие субъектов персональных данных на обработку таких персональных данных, включая их передачу OOO
                  «Филип Моррис Сэйлз энд Маркетинг», для целей заключения и исполнения договора с Вашей компанией.
                </Typography>
                <Typography>
                  Предоставляя данные вашей компании на Портале поставщика согласно указанным на нём требованиям, Вы
                  подтверждаете, что надлежащим образом уполномочены на предоставление таких данных со стороны Вашей
                  компании.
                </Typography>
              </Paper>
            </div>
            <FormControlLabel
              label={translate('consentModal.acceptLabelCheckbox')}
              control={
                <Checkbox
                  checked={accept}
                  onChange={toggleAccept}
                  color="primary"
                />
              }
            />
          </>
          }
          <CardActions className={classes.actions}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              disabled={!accept && userInfo.consentReceived === false}
              onClick={onAccept}
            >
              {translate('buttons.confirm')}
            </Button>
          </CardActions>
        </Card>
        <Notification/>
        <footer className={classes.footer}>
          <img
            className={classes.footer_logo}
            src="PMI-logo.png"
            alt="PMI logo"
          />
          <p className={classes.footer_text}>
            OOO «Филип Моррис Сэйлз энд Маркетинг».
          </p>
        </footer>
      </div>
    </div>
  )
}

const ConsentPageWithTheme = (props) => (
  <ThemeProvider theme={createTheme(theme)}>
    <ConsentPage{...props} />
  </ThemeProvider>
)

export default ConsentPageWithTheme