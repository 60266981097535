import React from 'react'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-admin'
import { Field, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import BasisNonPayment from './components/BasisNonPayment'
import BiggestTaxpayerKpp from './components/BiggestTaxpayerKpp'
import VatPayer from './components/VatPayer'
import BiggestTaxipayer from './components/BiggestTaxipayer'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
  inputContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(180px, 200px) minmax(470px, 470px) minmax(300px, 350px)',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      maxWidth: 'calc(100vw - 48px)'
    }
  }
}))

const VendorTaxation = () => {
  const translate = useTranslate()
  const classes = useStyles()

  const { values, errors } = useFormState()

  const vatPaymentAbsenceBasis = useSelector(state => state.customReducer.vatPaymentAbsenceBasis)
  const organizationType = useSelector(state => state.customReducer.organizationType)
  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const isDisabledFields = useMemo(() => (
    loading !== 0 || !(
      supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
      supplierStatus === constants?.DECLINED_PROVIDER_STATUS_I ||
      supplierStatus === constants?.PART_CONFIRMED_DATA_I || 
      supplierStatus === ''
    )
  ), [constants, loading])

  const renderBasisNonPayment = ({
                                   meta: { touched, error } = { touched: false, error: undefined },
                                   input: { ...inputProps },
                                   ...props
                                 }) => (
    <BasisNonPayment
      inputProps={inputProps}
      touched={touched}
      choices={vatPaymentAbsenceBasis}
      disabled={isDisabledFields}
    />
  )

  const renderBiggestTaxpayerKpp = ({
                                   meta: { touched, error } = { touched: false, error: undefined },
                                   input: { ...inputProps },
                                   ...props
                                 }) => (
    <BiggestTaxpayerKpp
      inputProps={inputProps}
      touched={touched}
      disabled={isDisabledFields}
    />
  )

  const renderVatPayer = (props) => (
    <VatPayer
      disabled={isDisabledFields}
      {...props}
    />
  )

  const renderBiggestTaxipayer = (props) => (
    <BiggestTaxipayer
      disabled={isDisabledFields}
      {...props}
    />
  )
  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle} variant="subtitle1">
        {translate('formSuppliers.titles.vendorTaxation')}
      </Typography>
      <div className={classes.inputContainer}>
        <Field
          name="client"
          render={renderVatPayer}
        />
        {values.client?.vatPayer === 'NO' && (
          <Field
            name="client.vatPaymentAbsenceBasis"
            render={renderBasisNonPayment}
          />
        )}
        {(organizationType === 'LEGAL' && values.client.vatPayer === 'YES') && (
          <Field
            name="client"
            render={renderBiggestTaxipayer}
          />
        )}
        {values.client?.biggestTaxpayer === 'YES' && (
          <Field
            name="client.biggestTaxpayerKpp"
            render={renderBiggestTaxpayerKpp}
          />
        )}
      </div>
    </div>
  )
}

export default VendorTaxation
