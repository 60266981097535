import * as React from 'react'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate
} from 'react-admin'
import { Autocomplete } from '@material-ui/lab'
import {
  FormControl,
  FormHelperText,
  TextField,
  Chip,
  IconButton,
  LinearProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DistributionCenterCreateModal from '../DistributionCenterCreateModal'

const useStyles = makeStyles((theme) => ({
  formControlDistributionCenters: {
    display: 'block',
    width: '100%',
    minWidth: 300,
    maxWidth: 420,
    marginBottom: theme.spacing(2),
    '& div.MuiFilledInput-root': {
      height: 56,
    },
    '& input': {
      height: 'unset',
      boxSizing: 'content-box'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset'
    }
  },
  formHelper: {
    marginLeft: 14
  },
  addBtn: {
    width: 48,
    height: 48,
    marginTop: 4,
    background: 'rgba(0, 0, 0, 0.04)',
    '& svg': {
      fontSize: '1.5rem'
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.13)'
    }
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'minmax(300px, 420px) 48px'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 48px'
    },
  }
}))

const DistributionCenterField = ({
                                   value,
                                   onChange,
                                   onBlur,
                                   error,
                                   required,
                                   touched,
                                   isDisabledFields,
                                   distributionCenters,
                                   onGetDistributionCenters
                                 }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const loading = useSelector(state => state.admin.loading)

  const [isOpenModal, setOpenModal] = useState(false)

  const toggleModal = useCallback(() => {
    setOpenModal(prev => !prev)
  }, [])

  return (
    <div className={classes.wrapper}>
      {(distributionCenters && loading === 0) ? (
        <FormControl
          className={classes.formControlDistributionCenters}
          required
          error={error}
          disabled={isDisabledFields}
        >
          <Autocomplete
            size="small"
            options={distributionCenters}
            getOptionLabel={(option) => option.name}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option.name}
                  size="small"
                  {...getTagProps({ index })}
                />
              ))
            }
            value={value}
            onChange={onChange}
            disabled={isDisabledFields}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label={translate('fields.distributionCenter')}
                required={required}
                onBlur={onBlur}
                error={error}
              />
            )}
          />
          {(touched && error) && (
            <FormHelperText
              className={classes.formHelper}
            >
              {translate(error)}
            </FormHelperText>
          )}
        </FormControl>
      ) : <LinearProgress />}
      <IconButton
        className={classes.addBtn}
        aria-label="add"
        size="small"
        onClick={toggleModal}
        disabled={isDisabledFields}
      >
        <AddIcon
          fontSize="small"
        />
      </IconButton>
      <DistributionCenterCreateModal
        isOpen={isOpenModal}
        toggle={toggleModal}
        onGetDistributionCenters={onGetDistributionCenters}
      />
    </div>
  )
}

export default DistributionCenterField