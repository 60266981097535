import * as React from 'react'
import { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Field, withTypes } from 'react-final-form'
import { useLocation } from 'react-router-dom'
import {
  Button, CardActions, CircularProgress, TextField, FormLabel
} from '@material-ui/core'
import { createTheme, makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { Notification, useTranslate, useLogin, useNotify, useRedirect} from 'react-admin'
import LoginPageWrapper from '../../components/LoginPageWrapper'
import { theme } from '../Layout'
import LoginTextField from "./components/LoginTextField";


const useStyles = makeStyles(theme => ({
  form: {
    padding: '0 30px 16px',
  },
  input: {
    marginBottom: '1em',
    '& input': {
      boxSizing: 'border-box',
      height: 46
    },
    '& .MuiFormHelperText-root': {
      marginLeft: 0
    }
  },
  recoverLink: {
    marginTop: theme.spacing(1),
    textDecoration: 'underline'
  },
  actions: {
    padding: '0 30px 30px',
  },
  button: {
    height: 42
  },
}))

const { Form } = withTypes()

const Login = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const login = useLogin()
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const handleSubmit = (auth) => {
    setLoading(true)
    login(auth, location.state ? location.state.nextPathname : '/').catch(
      (error) => {
        setLoading(false)
        const errorsCodesToLocalizationKeyMap = {
          'Bad credentials': 'login.warning.badCredentials',
          'Password expired': 'login.warning.passwordExpired',
          'User temporarily blocked': 'login.warning.accountLocked',
          undefined: error.message || error
        }
        const localizationKeyOrMessage = errorsCodesToLocalizationKeyMap[error.message]
        notify(localizationKeyOrMessage, 'warning')
        if (localizationKeyOrMessage === 'login.warning.passwordExpired') {
          history.push('/recovery')
        }
      }
    )
  }

  const validate = (values) => {
    const errors = {}
    if (!values.username) {
      errors.username = translate('ra.validation.required')
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required')
    }
    return errors
  }

  const renderInput = (props) => (
    <LoginTextField {...props} />
  )

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <LoginPageWrapper title={translate('login.title')}>
            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  autoFocus
                  name='username'
                  component={renderInput}
                  label={translate('ra.auth.username')}
                  disabled={loading}
                />
              </div>
              <div className={classes.input}>
                <Field
                  name='password'
                  component={renderInput}
                  label={translate('ra.auth.password')}
                  type='password'
                  disabled={loading}
                />
              </div>
              <div className={classes.recoverLink}>
                <Link to="/recovery">
                  {translate('passwordRecovery')}
                </Link>
              </div>
            </div>
            <CardActions className={classes.actions}>
              <Button
                className={classes.button}
                variant='contained'
                type='submit'
                color='primary'
                disabled={loading}
                fullWidth
                startIcon={loading && <CircularProgress size={25} thickness={2} />}
              >
                {translate('ra.auth.sign_in')}
              </Button>
            </CardActions>
            <Notification />
          </LoginPageWrapper>
        </form>
      )}
    />
  )
}

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props) => (
  <ThemeProvider theme={createTheme(theme)}>
    <Login {...props} />
  </ThemeProvider>
)

export default LoginWithTheme