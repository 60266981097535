import * as React from 'react'
import {
  FormControl, Card, CardHeader, CardContent, FormLabel
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Collapsible from 'react-collapse';
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import {useTranslate} from "react-admin";
import {useCallback, useState} from "react";
import "../../../../../app/App.css";
import DocumentContent from "../DocumentContent";

const useStyles = makeStyles((theme) => ({
  formControl: {
    display: 'flex',
    width: '100%',
  },
  fileName: {
    color: '#0000FF',
    textDecoration: 'underline'
  },
  card: {
    position: 'relative',
  },
  formLabel: {
    fontSize: '1,3rem',
    border: '0.5px solid #cdcdcd',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
    color: '#1565C0',
    background: '#ffffff',
    padding: '15px 20px'
  },
  cardContent: {
    paddingBottom: '1px'
  },
  cardHeader:{
    padding: '10px',
    '& .MuiTypography-root': {
      fontSize: '1rem',
      fontWeight: 400,
      padding:'10px'
    }
  },
  center:{
    padding: '1px 40% 1px 20px'
  },
  padding:{
    padding: '20px'
  }
}))

const VideoSection = ({title, label, options, className, videoSrc, ...props }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [opened, setOpened] = useState(false)
  const toggleModal = useCallback(() => {
    setOpened(prev => !prev)
  },[])
  return (
    <>
      <FormControl className={`${classes.formControl} ${className}`}>
        <FormLabel onClick={toggleModal} className={`${classes.formLabel}`}>
          <span>{title}</span>
        </FormLabel>
        <Collapsible isOpened={opened} >
          <Card className={classes.card}>
            <CardHeader className={classes.cardHeader} title={translate('help.videoTitle')}/>
            <CardContent  className={`${classes.cardContent} ${classes.center}`}>
              <Player playsInline
                src={videoSrc}
              />
            </CardContent>
            <DocumentContent
              title={translate("help.instructionTitle")}
            />
          </Card>
        </Collapsible>
      </FormControl>
    </>
  )
}

export default VideoSection