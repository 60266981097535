import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DeleteButton, SaveButton, useDataProvider, useNotify, useRefresh, useTranslate } from 'react-admin'
import { Toolbar, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useForm } from 'react-final-form'
import { transform } from '../../../utils'
import {postLogs} from "../../../../../api/customApi";

const useStyles = makeStyles((theme) => ({
  toolbar: {
    boxSizing: 'border-box',
    padding: '25px 32px',
    backgroundColor: "#f5f5f5",
    '& > div': {
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      boxSizing: 'border-box',
      width: '100%'
    }
  },
  delete: {
    padding: '0 16px',
    color: '#662211',
    background: '#F2B8B5',
    '&:hover': {
      background: '#eea6a3'
    },
    '&.Mui-disabled': {
      background: 'transparent',
    }
  }
}))

const ToolbarSupplier = props => {
  const {
    basePath,
    handleSubmit,
    invalid,
    redirect,
    saving,
    validating,
    record,
    submitOnEnter,
    resource,
    undoable,
    mutationMode,
    tab,
    handleTabs
  } = props
  const classes = useStyles()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const translate = useTranslate()
  const refresh = useRefresh()

  const providerStatuses = useSelector(state => state.customReducer.providerStatuses)
  const constants = useSelector(state => state.customReducer.constants)
  const loading = useSelector(state => state.admin.loading)
  const supplierStatus = useSelector(state => state.customReducer.supplierStatus)

  const draftStatus = useMemo(() => (
    providerStatuses.find(status => (
      status.identifier === constants?.DRAFT_PROVIDER_STATUS_I
    ))
  ), [providerStatuses, constants])

  const onSubmit = useCallback(async () => {
    try {
      postLogs("Suppliers/Edit/Toolbar", "ALL", {
        message: `onSubmit start`,
        stack: "76 line"
      });
      const data = await transform(constants)(form.getState().values)
      postLogs("Suppliers/Edit/Toolbar", "ALL", {
        message: `onSubmit data: ${JSON.stringify(data)}`,
        stack: "81 line"
      });
      data.client.providerStatus = providerStatuses.find(status => status.identifier === constants.DRAFT_PROVIDER_STATUS_I)
      data.client.presentationDate = ''
      postLogs("Suppliers/Edit/Toolbar", "ALL", {
        message: `onSubmit change providerStatus: ${JSON.stringify(data.client.providerStatus)} and presentationDate`,
        stack: "87 line"
      });
      const res = await dataProvider.update('suppliers', { id: data.id, data })
      postLogs("Suppliers/Edit/Toolbar", "ALL", {
        message: `onSubmit res: ${JSON.stringify(res)}`,
        stack: "92 line"
      });
      if (res?.data?.responseBody) {
        notify(translate('notify.dataSaved'))
        refresh()
      }
      if (res?.data?.errorMessage) {
        postLogs("Suppliers/Edit/Toolbar", "ERROR", {
          message: `onSubmit res.data.errorMessage: ${res.data.errorMessage}`,
          stack: "101 line"
        });
        notify(res.data.errorMessage, 'warning')
      }
    } catch (err) {
      postLogs("Suppliers/Edit/Toolbar", "ERROR", {
        message: `onSubmit err: ${err}`,
        stack: "108 line"
      });
      console.log(err)
      notify( err.message, 'warning')
    }
    postLogs("Suppliers/Edit/Toolbar", "ALL", {
      message: `onSubmit end`,
      stack: "76 line"
    });
  }, [form])

  const onNext = useCallback(() => {
    handleTabs({}, 1)
  }, [])

  return (
    <Toolbar
      className={classes.toolbar}
      role="toolbar"
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        {tab > 0 ? <SaveButton
          handleSubmitWithRedirect={onSubmit}
          invalid={false}
          saving={saving || validating || loading !== 0}
          submitOnEnter={submitOnEnter}
          disabled={
            !(
              supplierStatus === constants?.DRAFT_PROVIDER_STATUS_I ||
              supplierStatus === ''
            )
          }
        /> : <Button
          variant="contained"
          color="primary"
          endIcon={<NavigateNextIcon />}
          onClick={onNext}
        >
          {translate('buttons.next')}
        </Button>}
        <DeleteButton
          className={classes.delete}
          label={translate('buttons.deleteSupplier')}
          basePath={basePath}
          record={record}
          resource={resource}
          undoable={undoable}
          mutationMode={mutationMode}
          disabled={
            form.getState().values?.client?.providerStatus &&
            form.getState().values?.client?.providerStatus?.identifier !== draftStatus?.identifier
          }
        />
      </Box>
    </Toolbar>
  )
}

export default ToolbarSupplier