import * as React from 'react'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { arrayToObject } from '../../../../utils'
import CreateAndEditSupplierFounder from './components/CreateAndEditSupplierFounder'
import List from './components/ListFounders'
import httpDataProvider from '../../../../../../api/httpDataProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px 0`,
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  }
}))

const FoundersVendor = ({ inputProps }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { values } = useFormState()

  const loading = useSelector(state => state.admin.loading)
  const constants = useSelector(state => state.customReducer.constants)

  const [selected, setSelected] = useState([])
  const [isOpenModal, setOpenModal] = useState(false)
  const [modalMode, setModalMode] = useState()
  const [founders, setFounders] = useState(null)

  useEffect(() => {
    return () => {
      if (loading === 0) {
        setFounders(null)
      }
    }
  }, [loading, values.client?.id])

  useEffect(() => {
    if (loading === 0 && values.client?.id && founders === null) {
      initFounders()
    }
  }, [values.client?.id, founders, loading])

  const initFounders = async () => {
    const res = await httpDataProvider.getList('supplierFounders', {
      filter: {
        filters: [{
          group: 'OR',
          conditions: [
            {
              property: 'client.id',
              operator: '=',
              value: values.client.id
            }
          ]
        }],
        notConvert: true
      },
      sort: {
        field: 'hasMoreThanFiftyPercent',
        order: 'DESC'
      }
    })
    setFounders(res.data)
  }

  useEffect(() => {
    inputProps.onChange({
      target: {
        value: founders ?? []
      }
    })
  }, [founders])

  // for case then ra set values.client.supplierFounders to undefined on save or provide (sometimes)
  useEffect(() => {
    if (!values.client.supplierFounders && loading === 0) {
      setFounders(prev => (
        prev ? [ ...prev ] : prev
      ))
    }
  }, [values.client.supplierFounders, loading])

  const validateFounder = founder => {
    if (typeof founder === 'object') {
      let error = ''
      if (founder.subjectType === constants?.SUBJECT_TYPE_LEGAL_PERSON) {
        error = (
          founder.foreignCompany || (founder.tin && founder.fullName && founder.name && (
            founder.hasMoreThanFiftyPercent === 'NO' || (
              founder.hasMoreThanFiftyPercent === 'YES' && founder.headLastName && founder.headFirstName
            )
          )) ? '' : 'ra.message.invalid_form'
        )

        if (!error && founder.parentSupplier) {
          error = validateFounder(founder.parentSupplier)
        }
      }

      if (founder.subjectType === constants?.SUBJECT_TYPE_INDIVIDUAL) {
        error = (
          founder.foreignCompany || (founder.firstName && founder.lastName && founder.name)
        ) ? ''  : 'ra.message.invalid_form'
      }

      return error
    }
  }

  const customValidate = value => {
    let errors = []
    const valueForValidate = value ?? []
    valueForValidate.forEach((founder, index) => {
      errors[index] = validateFounder(founder)
    })

    const isEmptyErrors = !errors.find(error => error)
    if (isEmptyErrors) {
      errors = undefined
    }

    return (
      errors
    )
  }

  const data = useMemo(() => (
    values.client?.supplierFounders?.length ?
      arrayToObject(
        values.client.supplierFounders
      ) : {}
  ), [values])

  const ids = useMemo(() => (
    values.client?.supplierFounders?.length ? values.client.supplierFounders.map((item, index) => index.toString()) : []
  ), [values])

  let selectedData = selected.map((item) => data[item])

  const toggleModal = useCallback((mode, selected = '') => (e) => {
    e?.stopPropagation()
    if (selected) {
      setSelected([selected])
    }
    setModalMode(mode ?? '')
    setOpenModal((prev) => !prev)
  },[isOpenModal])

  useEffect(() => {
    if (loading !== 0 && selected.length !== 0) {
      setSelected([])
    }
  }, [selected, loading])

  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle} variant="subtitle1">
        {translate('formSuppliers.titles.foundersOfTheVendor')}
      </Typography>
      <CreateAndEditSupplierFounder
        isOpen={isOpenModal}
        toggle={toggleModal('')}
        data={selectedData}
        mode={modalMode}
        globalData={values.client?.supplierFounders ?? []}
        parentClient={values.client}
        inputProps={inputProps}
        selected={selected}
        setSelected={setSelected}
      />
      <List
        selected={selected}
        setSelected={setSelected}
        toggleModal={toggleModal}
        data={data}
        ids={ids}
        inputProps={inputProps}
        loaded={founders !== null}
      />
      <TextInput
        source="client.supplierFounders"
        validate={customValidate}
        disable
        style={{
          display: 'none'
        }}
      />
    </div>
  )
}

export default FoundersVendor