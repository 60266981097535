import * as React from 'react'
import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Toolbar } from '@material-ui/core'
import {
  DeleteButton, SaveButton, useDataProvider, useNotify, useRedirect, useRefresh, useTranslate
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useForm } from 'react-final-form'

const useStyles = makeStyles((theme) => ({
  toolbar: {
    boxSizing: 'border-box',
    padding: '25px 32px',
    backgroundColor: '#f5f5f5',
    '& > div': {
      display: 'flex'
    },
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: 0,
      boxSizing: 'border-box',
      width: '100%'
    },
  },
  delete: {
    padding: '0 16px',
    color: '#662211',
    background: '#F2B8B5',
    '&:hover': {
      background: '#eea6a3'
    },
    '&.Mui-disabled': {
      background: 'transparent',
    }
  }
}))

const ToolbarDistributionCenters = props => {
  const {
    basePath,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    saving,
    validating,
    record,
    submitOnEnter,
    resource,
    undoable,
    mutationMode,
    mode
  } = props
  const classes = useStyles()
  const translate = useTranslate()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      const data = form.getState().values
      let res
      if (mode === 'edit') {
        res = await dataProvider.update('distributionCenters', { id: data.id, data })
      } else {
        res = await dataProvider.create('distributionCenters', { data })
      }

      if (res?.data?.id) {
        notify(translate('notify.dataSaved'))
        redirect(`/distributionCenters/${res?.data?.id}`)
        refresh()
      }
    } catch (err) {
      notify(err.message, 'warning')
    }
    setLoading(false)
  }, [invalid, form, dataProvider])

  return (
    <Toolbar
      className={classes.toolbar}
      role="toolbar"
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <SaveButton
          handleSubmitWithRedirect={handleSubmitWithRedirect || handleSubmit}
          onSave={onSubmit}
          invalid={invalid}
          saving={saving || validating || loading}
          submitOnEnter={submitOnEnter}
        />
        {mode === "edit" && (
          <DeleteButton
            className={classes.delete}
            label={translate('ra.action.delete')}
            basePath={basePath}
            record={record}
            resource={resource}
            undoable={undoable}
            mutationMode={mutationMode}
            disabled={form.getState().values.active}
          />
        )}
      </Box>
    </Toolbar>
  )
}

export default ToolbarDistributionCenters