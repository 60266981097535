import * as React from 'react'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslate } from 'react-admin'
import { Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InboxIcon from '@material-ui/icons/Inbox'
import AddIcon from '@material-ui/icons/Add'
import { toggleModalCreatorSupplier } from '../../../../reducer/actions'

const useStyles = makeStyles(theme => ({
    message: {
      textAlign: 'center',
      opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
      margin: '0 1em',
      color:
        theme.palette.type === 'light'
          ? 'inherit'
          : theme.palette.text.primary,
    },
    icon: {
      width: '9em',
      height: '9em',
    },
    toolbar: {
      textAlign: 'center',
      marginTop: '2em',
    },
  }),
  { name: 'RaEmpty' }
)

const Empty = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const dispatch = useDispatch()

  const onCreate = useCallback(() => {
    dispatch(toggleModalCreatorSupplier(true))
  }, [])

  return (
    <>
      <div className={classes.message}>
        <InboxIcon className={classes.icon} />
        <Typography variant="h4" paragraph>
          {translate('emptyPage.title')}
        </Typography>
        <Typography variant="body1">
          {translate('emptyPage.subtitle')}
        </Typography>
      </div>
      <div className={classes.toolbar}>
        <Button
          variant="contained"
          onClick={onCreate}
          startIcon={<AddIcon />}
          color="primary"
        >
          {translate('emptyPage.button')}
        </Button>
      </div>
    </>
  )
}

export default Empty