import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDataProvider, useNotify, useTranslate } from "react-admin";
import { useFormState } from "react-final-form";
import {
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import FileModal from "./components/FileModal";
import PointsOfSale from "./components/PointsOfSale";
import InfoLetterDatagrid from "./components/InfoLetterDatagrid";
import InfoLetterForm from "./components/InfoLetterForm";

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginBottom: theme.spacing(1)
  },
  textForm: {
    display: "block",
    marginBottom: theme.spacing(2),
    fontWeight: 700,
    padding: "10px 12px"
  },
  inactively: {
    display: "none"
  },
  sendLetterButtonContainer: {
    display: "block",
    width: "fit-content",
    margin: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px auto`
  },
  sendLetterButton: {
    display: "inline-block"
  },
  textPointsOfSale: {
    display: "block",
    marginTop: theme.spacing(3)
  },
  button: {
    display: "block",
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px auto`
  }
}));

const InformationLetter = ({ active, inputProps }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();

  const { values } = useFormState();

  const loading = useSelector(state => state.admin.loading);
  const constants = useSelector(state => state.customReducer.constants);
  const docStatuses = useSelector(state => state.customReducer.docStatuses);
  const constituentDocsKinds = useSelector(
    state => state.customReducer.constituentDocKinds
  );
  const supplierStatus = useSelector(
    state => state.customReducer.supplierStatus
  );
  const featureFlags = useSelector(state => state.customReducer.featureFlags);
  const dataProvider = useDataProvider();

  const firstDayOfGivenDate = date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  };

  const [form, setForm] = useState({
    id: null,
    responseByPointsOfSale: false,
    responseByDistributionCenter: false,
    typeOfDistributionCenter: null,
    distributionCenter: null,
    pointOfSalesVerifiedAndConfirmed: false,
    validFrom: null,
    presentationDate: null,
    status: null
  });
  const [touched, setTouched] = useState({
    responseByDistributionCenter: false,
    typeOfDistributionCenter: false,
    distributionCenter: false,
    pointOfSalesVerifiedAndConfirmed: false,
    validFrom: false
  });
  const [errors, setErrors] = useState({
    typeOfDistributionCenter: "ra.validation.required",
    distributionCenter: "ra.validation.required",
    pointOfSalesVerifiedAndConfirmed: "ra.validation.required",
    validFrom: "ra.validation.required"
  });
  const [distributionCenters, setDistributionCenter] = useState();
  const [isDisabledFields, setDisabledFields] = useState(false);
  const [isDisabledCheckbox, setDisabledCheckbox] = useState(false);
  const [isOpenFileModal, setOpenFileModal] = useState(false);
  const [myPos, setMyPos] = useState([]);
  const pointOfSaleStatuses = useSelector(
    state => state.customReducer.pointOfSaleStatuses
  );

  const initialStatus = useMemo(
    () =>
      docStatuses.find(
        status =>
          status.identifier === constants.FOUNDING_DOCUMENT_STATUS_ADDED_I
      ),
    [docStatuses, constants]
  );

  const declinedStatus = useMemo(
    () =>
      docStatuses.find(
        status =>
          status.identifier === constants.FOUNDING_DOCUMENT_STATUS_REJECTED_I
      ),
    [docStatuses, constants]
  );

  const confirmedStatus = useMemo(
    () =>
      docStatuses.find(
        status =>
          status.identifier === constants.FOUNDING_DOCUMENT_STATUS_APPROVED_I
      ),
    [docStatuses, constants]
  );

  const getPointsOfSale = useCallback(async () => {
    const notConfirmedIdentifier = pointOfSaleStatuses.find(
      status => status.identifier === constants.NOT_CONFIRMED_POS_STATUS_I
    ).identifier;

    const getMyPos = await dataProvider.getList("pointsOfSale", {
      filter: {
        filters: [
          {
            group: "AND",
            conditions: [
              {
                property: "status.identifier",
                operator: "=",
                value: notConfirmedIdentifier
              },
              {
                property: "supplier.id",
                operator: "=",
                value: values.id
              },
              {
                property: "includedInInformationLetter",
                operator: "=",
                value: true
              }
            ]
          }
        ],
        notConvert: true
      }
    });

    setMyPos(getMyPos.data);
    setDisabledCheckbox(getMyPos.data == null || getMyPos.data.length == 0);
  });

  const init = useCallback(() => {
    const verificationStatus = docStatuses.find(
      status =>
        status.identifier === constants.FOUNDING_DOCUMENT_STATUS_ON_APPROVAL_I
    );

    const lettersSorted = values.informationLetters.sort(
      (letter1, letter2) =>
        moment(letter2.presentationDate).valueOf() -
        moment(letter1.presentationDate).valueOf()
    );
    let letter =
      lettersSorted.find(
        letter =>
          letter.status.identifier === initialStatus.identifier ||
          letter.status.identifier === verificationStatus.identifier
      ) ?? lettersSorted[0];
    getPointsOfSale();
    let newForm;
    let newErrors;

    // fill form fields if exist element
    if (letter) {
      newErrors = {
        typeOfDistributionCenter: letter.typeOfDistributionCenter
          ? ""
          : "ra.validation.required",
        distributionCenter: letter.distributionCenter
          ? ""
          : "ra.validation.required",
        pointOfSalesVerifiedAndConfirmed: letter.pointOfSalesVerifiedAndConfirmed
          ? ""
          : "ra.validation.required",
        validFrom: letter.validFrom ? "" : "ra.validation.required"
      };
      newForm = {
        ...letter,
        responseByPointsOfSale: letter.responseByPointsOfSale ?? false,
        responseByDistributionCenter:
          letter.responseByDistributionCenter ?? false,
        typeOfDistributionCenter: letter.typeOfDistributionCenter ?? null,
        distributionCenter: letter.distributionCenter ?? null,
        validFrom: letter.validFrom ? moment(letter.validFrom) : null,
        pointOfSalesVerifiedAndConfirmed:
          letter.pointOfSalesVerifiedAndConfirmed ?? false,
        presentationDate: letter.presentationDate ?? null,
        status: letter.status ?? null,
        reasonForRejection: null
      };
    } else {
      // in another case set init values to form
      newErrors = {
        typeOfDistributionCenter: "",
        distributionCenter: "",
        pointOfSalesVerifiedAndConfirmed: "ra.validation.required",
        validFrom: "ra.validation.required"
      };
      newForm = {
        docKind: constituentDocsKinds.find(
          doc => doc.document === constants.INFORMATION_LETTER_DOCUMENT
        ),
        responseByPointsOfSale: false,
        responseByDistributionCenter: false,
        distributionCenter: null,
        typeOfDistributionCenter: null,
        pointOfSalesVerifiedAndConfirmed: false,
        validFrom: null,
        presentationDate: null,
        status: initialStatus,
        supplier: {
          id: values.id
        }
      };

      inputProps.onChange({
        target: {
          value: [
            ...values.informationLetters,
            {
              ...newForm,
              validFrom: newForm.validFrom
                ? moment(newForm.validFrom).format("YYYY-MM-DD")
                : null
            }
          ]
        }
      });
    }

    if (
      newForm.status.identifier === verificationStatus.identifier ||
      newForm.status.identifier === declinedStatus.identifier ||
      newForm.status.identifier === confirmedStatus.identifier
    ) {
      setDisabledFields(true);
      setDisabledCheckbox(myPos == null || myPos.length == 0);
    }

    if (newForm.file) {
      newForm.file = {
        id: newForm.file.id,
        name: newForm.file.name
      };
    }

    setErrors(newErrors);
    setForm(newForm);
  }, [
    values.informationLetters,
    docStatuses,
    constants,
    constituentDocsKinds,
    declinedStatus,
    confirmedStatus,
    initialStatus
  ]);

  const onToggleEditForm = useCallback(() => {
    setDisabledFields(false);
    setDisabledCheckbox(myPos == null || myPos.length == 0);
  }, [myPos]);

  const onCreateInfoLetter = useCallback(() => {
    const newForm = {
      file: null,
      validFrom: null,
      presentationDate: null,
      status: initialStatus,
      id: null
    };

    setForm((prev) => ({ ...prev, ...newForm }));

    inputProps.onChange({
      target: {
        value: [
          ...values.informationLetters,
          {
            ...newForm,
            validFrom: newForm.validFrom
              ? moment(newForm.validFrom).format("YYYY-MM-DD")
              : null
          }
        ]
      }
    });

    setDisabledFields(false);
    setDisabledCheckbox(myPos == null || myPos.length == 0);
  }, [inputProps, form, initialStatus, values.informationLetters]);

  const getDistributionCenters = useCallback(async () => {
    const distributionCenters = await dataProvider.getList(
      "distributionCenters",
      {
        filter: {
          filters: [],
          notConvert: true
        }
      }
    );
    setDistributionCenter(distributionCenters.data);
  }, [form]);

  const onChange = useCallback(
    field => (e, value) => {
      const newErrors = {
        ...errors,
        [field]: ""
      };
      const newForm = {
        ...form
      };

      switch (field) {
        case 'distributionCenter': {
          newForm[field] = value
          if (!value) {
            newErrors[field] = 'ra.validation.required'
          }
          break
        }
        case 'validFrom': {
          if (e === 'Invalid Date') {
            newErrors[field] = 'errors.invalidDate'
            newForm[field] = form[field]
          } else if (!value) {
            newErrors[field] = 'ra.validation.required'
          } else {
            newForm[field] = firstDayOfGivenDate(e)
          }
          break
        }
        case 'pointOfSalesVerifiedAndConfirmed': {
          newForm[field] = value
          if (!value) {
            newErrors[field] = 'ra.validation.required'
          }
          break
        }
        case 'responseByDistributionCenter': {
          newForm[field] = e.target.value
          if (e.target.value) {
            newErrors.distributionCenter = 'ra.validation.required'
            newErrors.typeOfDistributionCenter = 'ra.validation.required'
          }
          break
        }
        default: {
          newForm[field] = e.target.value
        }
      }

      const letterIndex = values.informationLetters.findIndex(
        letter => letter.id === form.id
      );

      const newLetters = [...values.informationLetters];
      newLetters[letterIndex] = {
        ...newForm,
        validFrom: newForm.validFrom
          ? moment(newForm.validFrom).format("YYYY-MM-DD")
          : null
      };

      inputProps.onChange({
        target: {
          value: newLetters
        }
      });
      console.log(newForm)
      setErrors(newErrors);
      setForm(newForm);
    },
    [form, values, errors, distributionCenters]
  );

  const onBlur = useCallback(
    field => () => {
      setTouched({
        ...touched,
        [field]: true
      });
    },
    [touched]
  );

  const toggleFileModal = useCallback(() => {
    setOpenFileModal(prev => !prev);
  }, []);

  const onSendLetter = () => {
    if (featureFlags.features?.pointsOfSale?.disabled) {
      setErrors(({ distributionCenter: _, ...other }) => ({
        ...other
      }));
    }
    if (form.responseByDistributionCenter === false) {
      setErrors(prev => ({
        ...prev,
        distributionCenter: "",
        typeOfDistributionCenter: ""
      }));

      setForm(prev => ({
        ...prev,
        distributionCenter: null,
        typeOfDistributionCenter: null
      }));
    }

    const errorKeys = Object.keys(errors);
    let isValid = !errorKeys.find(key => !!errors[key]);

    if (isValid) {
      toggleFileModal();
    } else {
      notify("ra.message.invalid_form", "warning");
    }
  };

  useEffect(() => {
    getDistributionCenters();
  }, []);

  useEffect(() => {
    if (form.status === null && distributionCenters && loading === 0) {
      init();
    }
  }, [
    values.informationLetters,
    form,
    docStatuses,
    constants,
    distributionCenters,
    loading
  ]);

  useEffect(() => {
    const newErrors = {
      typeOfDistributionCenter:
        !form.responseByDistributionCenter || form.typeOfDistributionCenter
          ? ""
          : "ra.validation.required",
      distributionCenter:
        !form.responseByDistributionCenter || form.distributionCenter
          ? ""
          : "ra.validation.required",
      pointOfSalesVerifiedAndConfirmed: form.pointOfSalesVerifiedAndConfirmed
        ? ""
        : "ra.validation.required",
      validFrom: form.validFrom ? "" : "ra.validation.required"
    };

    setErrors(newErrors);

  }, [form])

  return (
    <div className={active ? classes.wrapper : classes.inactively}>
      <InfoLetterDatagrid />
      {form.status &&
        form.status.identifier === declinedStatus.identifier &&
        isDisabledFields && (
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={onToggleEditForm}
          >
            {translate("buttons.editInfoLetter")}
          </Button>
        )}
      {form.status && form.status.identifier === confirmedStatus.identifier && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onCreateInfoLetter}
        >
          {translate("buttons.newInfoLetter")}
        </Button>
      )}
      <InfoLetterForm
        form={form}
        isDisabledFields={isDisabledFields}
        onBlur={onBlur}
        onChange={onChange}
        errors={errors}
        distributionCenters={distributionCenters}
        getDistributionCenters={getDistributionCenters}
        touched={touched}
      />
      <Typography className={classes.textForm} variant="subtitle1">
        {translate("pointsOfSale.tableTitle")}
      </Typography>
      {!featureFlags.features?.pointsOfSale?.disabled && (
        <Typography className={classes.textPointsOfSale} variant="h8">
          {translate("menuItem.pointsOfSale")}
        </Typography>
      ) && <PointsOfSale />}
      <Container className={classes.sendLetterButtonContainer}>
        <FormControlLabel
          label={translate("infoLetterModal.disclaimerLabel")}
          control={
            <Checkbox
              required
              checked={form.pointOfSalesVerifiedAndConfirmed}
              onChange={onChange("pointOfSalesVerifiedAndConfirmed")}
              disabled={isDisabledFields || isDisabledCheckbox}
            />
          }
        />

        {supplierStatus === constants?.ACTIVE_PROVIDER_STATUS_I && (
          <Button
            className={classes.sendLetterButton}
            variant="contained"
            color="primary"
            onClick={onSendLetter}
            startIcon={loading ? <CircularProgress size={14} /> : ""}
            disabled={
              loading !== 0 ||
              isDisabledFields ||
              !form.pointOfSalesVerifiedAndConfirmed
            }
          >
            {translate("buttons.sendLetter")}
          </Button>
        )}
      </Container>
      <FileModal
        isOpen={isOpenFileModal}
        handleClose={toggleFileModal}
        form={form}
      />
    </div>
  );
};

export default InformationLetter;
