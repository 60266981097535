import * as React from 'react'
import { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate, TextInput, required } from 'react-admin'
import { useFormState } from 'react-final-form'
import {
  TextField, IconButton,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import Modal from './components/Modal'

const useStyles = makeStyles((theme) => ({
  editBtn: {
    width: 48,
    height: 48,
    marginTop: 4,
    background: 'rgba(0, 0, 0, 0.04)',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.13)'
    }
  },
  input: {
    gridColumn: '1 / 2',
    marginBottom: 4,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100vw - 96px)',
    },
  },
  hide: {
    display: 'none'
  }
}))

const validateRequired = [required()]

const fieldsArray = [
  { name: 'lastName', required: false },
  { name: 'firstName', required: false },
  { name: 'middleName', required: false },
  { name: 'fullName', required: false },
  { name: 'position', required: false },
]

const PersonWithRightToSignInput = ({ inputProps }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const organizationType = useSelector(state => state.customReducer.organizationType)

  const { values, submitFailed } = useFormState()

  const [isOpenModal, setOpenModal] = useState(false)
  const [isBlurred, setBlurred] = useState(false)

  const toggleModal = useCallback(() => {
    if (isOpenModal) {
      setBlurred(true)
    }
    setOpenModal(prev => !prev)
  }, [isOpenModal])

  return (
    <>
      <TextField
        className={classes.input}
        variant="filled"
        label={translate('fields.personWithRightToSign')}
        value={values.client?.person?.fullName ?? ''}
        disabled
      />
      <IconButton
        className={classes.editBtn}
        aria-label="edit"
        size="small"
        onClick={toggleModal}
      >
        <VisibilityIcon fontSize="small"/>
      </IconButton>

      <TextField
        className={classes.input}
        label={translate('fields.position')}
        disabled
        value={values.client?.person?.position ?? ''}
        variant="filled"
      />
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
        inputProps={inputProps}
        fieldsArray={fieldsArray}
      />
      <div className={classes.hide}>
        {fieldsArray.map(item => (
          <TextInput
            source={`client.person.${item.name}`}
            disable
          />
        ))}
      </div>
    </>
  )
}

export default PersonWithRightToSignInput