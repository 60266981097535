import * as React from 'react'
import { Edit, SimpleForm, useTranslate } from 'react-admin'
import Form from '../components/Form'
import Toolbar from '../components/Toolbar'

const DistributedCenterEdit = props => {
  const translate = useTranslate()
  return (
    <Edit
      {...props}
      title={translate('resources.distributionCenters.edit')}
    >
      <SimpleForm
        toolbar={
          <Toolbar mode="edit" />
        }
      >
        <Form mode="edit" />
      </SimpleForm>
    </Edit>
  )
}

export default DistributedCenterEdit