import React from 'react'
import { useEffect } from 'react'
import { useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 8,
    marginBottom: 4,
  }
}))

const AccountHolderName = ({ inputProps }) => {
  const classes = useStyles()
  const translate = useTranslate()

  const { values } = useFormState()

  useEffect(() => {
    let value = ''
    if (values.client?.organizationFullName) {
      value = values.client.organizationFullName
    }

    inputProps.onChange({
      target: {
        value
      }
    })
  }, [values])

  return (
    <TextField
      className={classes.input}
      label={translate('fields.currentAccountName')}
      variant="filled"
      value={values.client?.currentAccountHolderName ?? ''}
      disabled
      helperText=""
    />
  )
}

export default AccountHolderName