import React, { useCallback } from 'react'
import { useTranslate, required, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { Select, FormControl, InputLabel, MenuItem, FormHelperText } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginTop: 8,
    marginBottom: 4
  },
  hide: {
    display: 'none'
  },
  helperText: {
    marginLeft: 14,
    marginRight: 14
  }
}))

const validateRequired = [required()]

const BasisNonPayment = ({ inputProps, touched, choices, disabled }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const { values, errors, submitFailed } = useFormState()

  const onChange = useCallback((e) => {
    inputProps.onChange({
      target: {
        value: {
          identifier: e.target.value
        }
      }
    })
  }, [inputProps])

  return (
    <>
      <FormControl
        className={classes.formControl}
        error={errors.client?.vatPaymentAbsenceBasis}
        disabled={disabled}
      >
        <InputLabel required>
          {translate('fields.basisNonPayment')}
        </InputLabel>
        <Select
          variant="filled"
          value={values.client?.vatPaymentAbsenceBasis?.identifier ?? ''}
          onChange={onChange}
          onBlur={inputProps.onBlur}
        >
          {choices.map(item => (
            <MenuItem value={item.identifier}>{item.name}</MenuItem>
          ))}
        </Select>
        <FormHelperText className={classes.helperText}>
          {translate((submitFailed || touched) && (errors.client?.vatPaymentAbsenceBasis ?? ''))}
        </FormHelperText>
        <TextInput
          className={classes.hide}
          source="client.vatPaymentAbsenceBasis"
          validate={validateRequired}
          disabled
        />
      </FormControl>
      <TextInput
        className={classes.hide}
        source="client.vatPaymentAbsenceBasis"
        validate={validateRequired}
        disabled
      />
    </>
  )
}

export default BasisNonPayment