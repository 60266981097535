import * as React from 'react'
import { useSelector } from 'react-redux'
import {
  Create, SimpleForm, useTranslate
} from 'react-admin'
import Form from '../components/Form'
import Toolbar from '../components/Toolbar'

const DistributionCenterCreate = props => {
  const translate = useTranslate()

  const currentUser = useSelector(state => state.customReducer.currentUser)

  return (
    <Create
      {...props}
      title={translate('resources.distributionCenters.create')}
    >
      <SimpleForm
        initialValues={{
          tradeAccount: currentUser?.tradeAccount
        }}
        toolbar={
          <Toolbar mode="create" />
        }
      >
        <Form mode="create" />
      </SimpleForm>
    </Create>
  )
}

export default DistributionCenterCreate