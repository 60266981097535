import React from 'react'
import { useCallback, useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate, useNotify } from 'react-admin'
import {
  Button, Select, MenuItem, Checkbox,
  FormControlLabel, FormControl, Typography,
  TextField, InputLabel, FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import DropZone from '../../../../../../../components/DropZone'
import ModalBase from '../../../../../../../components/ModalBase'

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: '100%',
    maxWidth: '670px'
  },
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(1),
    overflowX: 'hidden',
    [theme.breakpoints.down('xs')]: {
      flex: 'unset',
      gridTemplateColumns: '1f',
      gridColumnGap: theme.spacing(1),
      gridTemplateRows: 'unset',
      width: 'unset'
    },
  },
  helpText: {
    width: '100%',
    margin: 0,
    whiteSpace: 'pre-wrap',
  },
  year: {
    marginTop: 8,
    marginBottom: 4,
  }
}))

const NoDeclarationForm = ({
                             blurred,
                             onBlur,
                             errors,
                             comment,
                             onChange,
                             disabledFields
                           }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <Typography className={classes.helpText}>
        {translate('fields.noDeclarationHelper')}
      </Typography>
      <TextField
        label={translate('fields.comment')}
        value={comment}
        onChange={onChange}
        multiline
        rows={5}
        defaultValue=""
        variant="filled"
        required
        error={blurred.comment && errors.comment}
        helperText={blurred.comment ? errors.comment : ''}
        onBlur={onBlur}
        disabled={disabledFields}
      />
    </>
  )
}

const EditDeclarationModal = ({ isOpen, toggle, documents, selectedKind, onGetDocument, onAddDocument, disabledFields }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const notify = useNotify()

  const document = useMemo(() => (
    documents.find(item => item.docKind.identifier === selectedKind.identifier)
  ), [documents, selectedKind])

  const [declarationValues, setDeclarationValues] = useState({
    noDeclaration: document?.noDeclaration === true ?? false,
    taxPeriod: document?.taxPeriod?.identifier ?? '',
    year: document?.vatDeclarationYear ?
      new Date(document.vatDeclarationYear) :
      null,
    file: [],
    comment: document?.comment ?? ''
  })
  const [blurred, setBlurred] = useState({
    taxPeriod: false,
    comment: false,
    year: false
  })
  const [errors, setErrors] = useState({
    taxPeriod: (document?.taxPeriod?.identifier || document?.noDeclaration === true) ? '' : translate('ra.validation.required'),
    year: (document?.noDeclaration === true || document?.vatDeclarationYear) ? '' : translate('ra.validation.required'),
    file: document?.noDeclaration === true ? '' : translate('ra.validation.required'),
    comment: document?.noDeclaration === true ? translate('ra.validation.required') : '',
  })

  const taxPeriods = useSelector(state => state.customReducer.taxPeriods)

  useEffect(() => {
    setDeclarationValues({
      noDeclaration: document?.noDeclaration === true ?? false,
      taxPeriod: document?.taxPeriod?.identifier ?? '',
      year: document?.vatDeclarationYear ?
        new Date(document.vatDeclarationYear) :
        null,
      file: document?.file ? [document?.file] : [],
      comment: document?.comment ?? ''
    })
  }, [document])

  const onBlur = useCallback((field) => () => {
      setBlurred({
        ...blurred,
        [field]: true,
      })
    },
    [blurred]
  )

  const handleDeclaration = useCallback(e => {
    setDeclarationValues({
      ...declarationValues,
      noDeclaration: e.target.checked
    })
  }, [declarationValues])

  const validate = useCallback(field => value => {
    const newErrors = { ...errors }
    switch (field) {
      case 'file': {
        if (!value.length) {
          newErrors.file = translate('ra.validation.required')
        } else {
          newErrors.file = ''
        }
        break
      }
      case 'year': {
        if (!value?.toString()) {
          newErrors.year = translate('ra.validation.required')
        } else if (value?.toString() === 'Invalid Date') {
          newErrors.year = translate('errors.invalidDate')
        } else {
          newErrors.year = ''
        }
        break
      }
      default: {
        if (!value) {
          newErrors[field] = translate('ra.validation.required')
        } else {
          newErrors[field] = ''
        }
      }
    }
    setErrors(newErrors)
    return newErrors[field]
  }, [errors])

  const validateAll = useCallback(() => {
    const errorsKeys = Object.keys(errors)
    const newErrors = {}
    errorsKeys.forEach(key => {
      newErrors[key] = validate(key)(declarationValues[key])
    })
    if (declarationValues.noDeclaration) {
      newErrors.file = ''
      newErrors.taxPeriod = ''
      newErrors.year = ''
    } else {
      newErrors.comment = ''
    }
    setErrors(newErrors)
    return newErrors
  }, [declarationValues, errors])

  const onChange = useCallback(field => e => {
    let value

    switch (field) {
      case 'year':
        value = e
        break
      case 'file':
        if (e.length) {
          validate(field)(e)
          setDeclarationValues({
            ...declarationValues,
            [field]: e
          })
        } else if (!declarationValues.file[0]?.id && !declarationValues.file[0]?.file) {
          validate(field)([])
          setDeclarationValues({
            ...declarationValues,
            [field]: []
          })
        }
        return
      default:
        value = e.target.value
    }

    validate(field)(value)
    setDeclarationValues({
      ...declarationValues,
      [field]: value
    })
  }, [declarationValues, errors])

  const handleSave = useCallback(async () => {
    const errorsAfterValidation = validateAll()
    const errorsKeys = Object.keys(errorsAfterValidation)
    const hasError = errorsKeys.find(key => !!errorsAfterValidation[key])

    const blurredNew = {}
    for (const key in blurred) {
      blurredNew[key] = true
    }
    setBlurred(blurredNew)

    if (hasError) {
      notify('ra.message.invalid_form', 'warning')
      return
    }

    await onAddDocument({
      file: declarationValues.file[0],
      name: declarationValues.file[0]?.name,
      noDeclaration: declarationValues.noDeclaration,
      comment: declarationValues.comment,
      tax: {identifier: declarationValues.taxPeriod},
      year: declarationValues.year ? `${declarationValues.year.getFullYear().toString()}-03-03` : null,
      fileFromDocument: document?.file
    })
    toggle()
  }, [blurred, errors, declarationValues, document])

  return (
    <ModalBase
      title={translate('constituentDocModal.titleDeclaration')}
      handleClose={toggle}
      isOpen={isOpen}
      contentClasses={classes.content}
      paperStyle={classes.paperStyle}
      maxWidth="md"
      handleSave={handleSave}
    >
      <Button
        variant="outlined"
        color="primary"
        startIcon={<CloudDownloadIcon />}
        disabled={!selectedKind.file?.id}
        onClick={onGetDocument(selectedKind)}
      >
        {translate('constituentDocModal.downloadExampleFile')}
      </Button>
      <FormControlLabel
        control={
          <Checkbox
            checked={declarationValues.noDeclaration}
            onChange={handleDeclaration}
            color="primary"
            disabled={disabledFields}
          />
        }
        label={translate('fields.buttonForDeclaration')}
      />
      {declarationValues.noDeclaration ? (
        <NoDeclarationForm
          blurred={blurred}
          onBlur={onBlur('comment')}
          comment={declarationValues.comment}
          onChange={onChange('comment')}
          errors={errors}
          disabledFields={disabledFields}
        />
      ) : (
        <>
          <FormControl
            error={blurred.taxPeriod && errors.taxPeriod}
          >
            <InputLabel disabled={disabledFields}>
              {translate('fields.taxPeriod')}
            </InputLabel>
            <Select
              variant="filled"
              value={declarationValues.taxPeriod}
              onChange={onChange('taxPeriod')}
              onBlur={onBlur('taxPeriod')}
              error={blurred.taxPeriod && errors.taxPeriod}
              required
              disabled={disabledFields}
            >
              {taxPeriods.map((item) => (
                <MenuItem value={item.identifier}>{item.name}</MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {blurred.taxPeriod ? errors.taxPeriod : ""}
            </FormHelperText>
          </FormControl>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.year}
              views={['year']}
              format="yyyy"
              inputVariant="filled"
              required
              label={translate('fields.yearSubmitted')}
              value={declarationValues.year ?? null}
              onChange={onChange('year')}
              onBlur={onBlur('year')}
              maxDate={new Date()}
              disabled={disabledFields}
              error={!!(blurred.year && errors.year)}
              helperText={blurred.year && errors.year}
            />
          </MuiPickersUtilsProvider>
          <div>
            <DropZone
              file={declarationValues.file[0]?.path ? declarationValues.file : []}
              valuesFile={
                (declarationValues.file[0]?.id || declarationValues.file[0]?.file) ? declarationValues.file[0] : null
              }
              handleInput={onChange('file')}
              blurred={true}
              error={errors.file}
              disabled={disabledFields}
              warningText={translate('constituentDocModal.downloadHelper')}
            />
          </div>
        </>
      )}
    </ModalBase>
  )
}

export default EditDeclarationModal