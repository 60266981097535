import * as React from 'react'
import {Button} from '@material-ui/core'
import ModalBase from '../ModalBase'
import {makeStyles} from '@material-ui/core/styles'

const ModalWithCancel = ({title, isOpen, handleClose, handleSave, actionName, cancelName, maxWidth, description}) => {
  const useStyles = makeStyles((theme) => ({
    content: {
      minWidth: 430,
      marginTop: 0,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        minWidth: '100%',
      },
    }
  }))
  const classes = useStyles()
  const customActions = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        {actionName}
      </Button>
      <Button
        variant="contained"
        onClick={handleClose}
      >
        {cancelName}
      </Button>
    </>
  )

  return (
    <ModalBase
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      customActions={customActions}
      maxWidth={maxWidth}>
      <p className={classes.content}>
      {description}
    </p>
    </ModalBase>
  )
}

export default ModalWithCancel