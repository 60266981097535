import * as React from 'react'
import {
  List, Datagrid, TextField, FunctionField, useTranslate, useListContext, TopToolbar, CreateButton,
} from 'react-admin'
import Pagination from '../../../components/Pagination'
import { makeStyles } from '@material-ui/core/styles'
import BulkActionButtons from '../components/BulkActionButtons'
import { phoneWithMask } from '../../../helpers/utils'
import { stylesList } from '../../../helpers/enums'

const useStyles = makeStyles(stylesList)

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext()

  return (
    <TopToolbar {...props} id="list-actions-light">
      {filters && React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton />
    </TopToolbar>
  )
}

const UsersList = props => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <List
      classes={{
        root: classes.root,
        bulkActionsDisplayed: classes.bulkActionsDisplayed
      }}
      title={translate('menuItem.contactPersons')}
      {...props}
      actions={
        <ListActions />
      }
      bulkActionButtons={
        <BulkActionButtons />
      }
      pagination={<Pagination />}
      perPage={25}
    >
      <Datagrid
        rowClick="edit"
      >
        <TextField
          label={translate('fields.contactPerson')}
          source="name"
        />
        <TextField
          label={translate('fields.tradeAccount')}
          source="tradeAccount.name"
        />
        <TextField
          label={translate('fields.email')}
          source="email"
        />
        <FunctionField
          label={translate('fields.phone')}
          render={record => (
            phoneWithMask(record.phone)
          )}
        />
        <FunctionField
          label={translate('fields.isActive')}
          render={record => (record.active ? translate('fields.yes') : translate('fields.no'))}
        />
      </Datagrid>
    </List>
  )
}

export default UsersList