import TYPES from './types'

export const setOrganizationType = (payload) => {
  return {
    type: TYPES.SET_ORGANIZATION_TYPE, payload
  }
}

export const setOrganizationTypes = (payload) => {
  return {
    type: TYPES.SET_ORGANIZATION_TYPES, payload
  }
}

export const toggleModalCreatorSupplier = (payload) => {
  return {
    type: TYPES.TOGGLE_MODAL_CREATOR_SUPPLIER, payload
  }
}

export const setConstituentDocKinds = (payload) => {
  return {
    type: TYPES.SET_CONSTITUENT_DOC_KINDS, payload
  }
}

export const setDocStatuses = (payload) => {
  return {
    type: TYPES.SET_DOC_STATUSES, payload
  }
}

export const setTaxPeriods = (payload) => {
  return {
    type: TYPES.SET_TAX_PERIODS, payload
  }
}

export const setVatPaymentAbsenceBasis = (payload) => {
  return {
    type: TYPES.SET_VAT_PAYMENT_ABSENCE_BASIS, payload
  }
}

export const setConstants = (payload) => {
  return {
    type: TYPES.SET_CONSTANTS, payload
  }
}

export const setCurrentDocumentKinds = (payload) => {
  return {
    type: TYPES.SET_CURRENT_DOCUMENT_KINDS, payload
  }
}

export const setProviderStatuses = (payload) => {
  return {
    type: TYPES.SET_PROVIDER_STATUSES, payload
  }
}

export const setCurrentUser = (payload) => {
  return {
    type: TYPES.SET_CURRENT_USER, payload
  }
}

export const setUserInfo = (payload) => {
  return {
    type: TYPES.SET_USER_INFO, payload
  }
}

export const setSupplierStatus = (payload) => {
  return {
    type: TYPES.SET_SUPPLIER_STATUS, payload
  }
}

export const setAttorneyLetterActs = (payload) => {
  return {
    type: TYPES.SET_ATTORNEY_LETTER_ACTS, payload
  }
}

export const setAttorneyLetterStatuses = (payload) => {
  return {
    type: TYPES.SET_ATTORNEY_LETTER_STATUSES, payload
  }
}

export const setPointOfSaleStatuses = (payload) => {
  return {
    type: TYPES.SET_POINT_OF_SALE_STATUSES, payload
  }
}

export const setUpdatePointsOfSaleData = (payload) => {
  return {
    type: TYPES.SET_UPDATE_POINTS_OF_SALE_DATA, payload
  }
}

export const setUpdateOnePointOfSaleData = (payload) => {
  return {
    type: TYPES.SET_UPDATE_ONE_POINT_OF_SALE_DATA, payload
  }
}

export const setVisibilityModalConfirmationPointsOfSale = (payload) => {
  return {
    type: TYPES.SET_VISIBILITY_MODAL_CONFIRMATION_POINTS_OF_SALE, payload
  }
}

export const setVisibilityModalConfirmationOnePointOfSale = (payload) => {
  return {
    type: TYPES.SET_VISIBILITY_MODAL_CONFIRMATION_ONE_POINT_OF_SALE, payload
  }
}

export const setSaveWithoutConfirmPointsOfSale = (payload) => {
  return {
    type: TYPES.SET_SAVE_WITHOUT_CONFIRM_POINTS_OF_SALE, payload
  }
}

export const setAppLanguage = (payload) => {
  localStorage.setItem('lang', payload)
  return {
    type: TYPES.SET_APP_LANGUAGE, payload
  }
}

export const setSendAfterSavePointsOfSale = (payload) => {
  return {
    type: TYPES.SET_SEND_AFTER_SAVE_POINTS_OF_SALE, payload
  }
}

export const setLoginEmail = (payload) => {
  return {
    type: TYPES.SET_LOGIN_EMAIL, payload
  }
}

export const setFeatureFlags = (payload) => {
  return {
    type: TYPES.SET_FEATURE_FLAGS, payload
  }
}