import * as React from 'react'
import { useCallback, useState } from 'react'
import {
  Edit, SimpleForm, useTranslate
} from 'react-admin'
import Form from '../components/Form'
import { transform } from '../utils'
import SupplierEditToolbar from './components/Toolbar'

const SupplierEdit = props => {
  const translate = useTranslate()

  const [tab, setTab] = useState(0)

  const handleTabs = useCallback((e, value) => {
    setTab(value)
  }, [])
  return (
    <Edit
      {...props}
      transform={transform}
      title={translate('resources.suppliers.edit')}
    >
      <SimpleForm
        toolbar={
          <SupplierEditToolbar
            tab={tab}
            handleTabs={handleTabs}
          />
        }
        alwaysEnableSaveButton={true}
      >
        <Form
          handleTabs={handleTabs}
          tab={tab}
        />
      </SimpleForm>
    </Edit>
  )
}

export default SupplierEdit