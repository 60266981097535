import * as React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate, TextInput
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { TextField } from '@material-ui/core'

const TradeAccountField = ({
                            mode,
                            className,
                            validate,
                            meta: {touched, error} = {touched: false, error: undefined},
                            input: {...inputProps},
                            ...props
                          }) => {
  const translate = useTranslate()

  const { values } = useFormState()
  const currentUser = useSelector(state => state.customReducer.currentUser)

  useEffect(() => {
    if (mode === 'create') {
      inputProps.onChange({
        target: {
          value: currentUser?.tradeAccount
        }
      })
    }
  }, [mode, currentUser])

  return (
    <>
      <TextField
        className={className}
        label={translate('fields.tradeAccount')}
        value={values.tradeAccount?.name ?? ''}
        variant="filled"
        disabled
        required
      />
      <TextInput
        source="tradeAccount"
        disabled
        validate={validate}
        style={{
          display: 'none'
        }}
      />
    </>
  )
}

export default TradeAccountField