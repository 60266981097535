import TYPES from './types'

const initState = {
  organizationType: '',
  organizationTypes: [],
  isOpenModalCreatorSupplier: false,
  constituentDocKinds: [],
  docStatuses: [],
  taxPeriods: [],
  vatPaymentAbsenceBasis: [],
  constants: {},
  featureFlags: {},
  currentDocumentKinds: [],
  providerStatuses: [],
  currentUser: {},
  supplierStatus: '',
  attorneyLetterActs: [],
  attorneyLetterStatuses: [],
  pointOfSaleStatuses: [],
  updatePointsOfSale: {
    isOpenModal: false,
    data: [],
    saveWithoutConfirm: false,
    sendAfterSave: false
  },
  updateOnePointOfSale: {
    isOpenModal: false,
    data: null
  },
  appLanguage: '',
  loginEmail: '',
  userInfo: {}
}

const customReducer = (state = initState, action) => {
  switch (action.type) {
    case TYPES.SET_ORGANIZATION_TYPE: {
      return { ...state, organizationType: action.payload }
    }
    case TYPES.SET_ORGANIZATION_TYPES: {
      return { ...state, organizationTypes: action.payload }
    }
    case TYPES.TOGGLE_MODAL_CREATOR_SUPPLIER: {
      return { ...state, isOpenModalCreatorSupplier: action.payload }
    }
    case TYPES.SET_CONSTITUENT_DOC_KINDS: {
      return { ...state, constituentDocKinds: action.payload }
    }
    case TYPES.SET_DOC_STATUSES: {
      return { ...state, docStatuses: action.payload }
    }
    case TYPES.SET_TAX_PERIODS: {
      return { ...state, taxPeriods: action.payload }
    }
    case TYPES.SET_VAT_PAYMENT_ABSENCE_BASIS: {
      return { ...state, vatPaymentAbsenceBasis: action.payload }
    }
    case TYPES.SET_CONSTANTS: {
      return { ...state, constants: action.payload }
    }
    case TYPES.SET_FEATURE_FLAGS: {
      return { ...state, featureFlags: action.payload }
    }
    case TYPES.SET_CURRENT_DOCUMENT_KINDS: {
      return { ...state, currentDocumentKinds: action.payload }
    }
    case TYPES.SET_PROVIDER_STATUSES: {
      return { ...state, providerStatuses: action.payload }
    }
    case TYPES.SET_CURRENT_USER: {
      return { ...state, currentUser: action.payload }
    }
    case TYPES.SET_SUPPLIER_STATUS: {
      return { ...state, supplierStatus: action.payload }
    }
    case TYPES.SET_ATTORNEY_LETTER_ACTS: {
      return { ...state, attorneyLetterActs: action.payload }
    }
    case TYPES.SET_ATTORNEY_LETTER_STATUSES: {
      return { ...state, attorneyLetterStatuses: action.payload }
    }
    case TYPES.SET_POINT_OF_SALE_STATUSES: {
      return { ...state, pointOfSaleStatuses: action.payload }
    }
    case TYPES.SET_UPDATE_POINTS_OF_SALE_DATA: {
      return {
        ...state,
        updatePointsOfSale: {
          ...state.updatePointsOfSale,
          data: action.payload
        }
      }
    }
    case TYPES.SET_UPDATE_ONE_POINT_OF_SALE_DATA: {
      return {
        ...state,
        updateOnePointOfSale: {
          ...state.updateOnePointOfSale,
          data: action.payload
        }
      }
    }
    case TYPES.SET_VISIBILITY_MODAL_CONFIRMATION_POINTS_OF_SALE: {
      return {
        ...state,
        updatePointsOfSale: {
          ...state.updatePointsOfSale,
          isOpenModal: action.payload
        }
      }
    }
    case TYPES.SET_VISIBILITY_MODAL_CONFIRMATION_ONE_POINT_OF_SALE: {
      return {
        ...state,
        updateOnePointOfSale: {
          ...state.updateOnePointOfSale,
          isOpenModal: action.payload
        }
      }
    }
    case TYPES.SET_SAVE_WITHOUT_CONFIRM_POINTS_OF_SALE: {
      return {
        ...state,
        updatePointsOfSale: {
          ...state.updatePointsOfSale,
          saveWithoutConfirm: action.payload
        }
      }
    }
    case TYPES.SET_APP_LANGUAGE: {
      return {
        ...state,
        appLanguage: action.payload
      }
    }
    case TYPES.SET_SEND_AFTER_SAVE_POINTS_OF_SALE: {
      return {
        ...state,
        updatePointsOfSale: {
          ...state.updatePointsOfSale,
          sendAfterSave: action.payload
        }
      }
    }
    case TYPES.SET_LOGIN_EMAIL: {
      return {
        ...state,
        loginEmail: action.payload
      }
    }
    case TYPES.SET_USER_INFO: {
      return { ...state, userInfo: action.payload }
    }
    default:
      return state
  }
}

export default customReducer