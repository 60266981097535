import * as React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFormState } from 'react-final-form'
import { TextInput, useTranslate } from 'react-admin'
import { TextField, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  input: {
    marginTop: 8,
    marginBottom: 4,
  },
  selectedField: {
    '& label[data-shrink="false"]': {
      fontSize: '1rem',
      transform: 'translate(12px, 7px) scale(0.75) !important'
    }
  }
}))

const AttorneyLetterTextField = ({
                                   label,
                                   className,
                                   source,
                                   validate,
                                   defaultValue,
                                   isSupplierChanged,
                                   disabled,
                                   meta: { touched, error } = { touched: false, error: undefined },
                                   input: { ...inputProps },
                                   ...props
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const { values } = useFormState()

  const constants = useSelector(state => state.customReducer.constants)

  useEffect(() => {
    if (isSupplierChanged && values.attorneyLetterAct?.identifier === constants.CHARTER_ACT_I) {
      inputProps.onChange({
        target: {
          value: defaultValue ?? ''
        }
      })
    }
  }, [defaultValue, isSupplierChanged, values.attorneyLetterAct?.identifier, constants])

  return (
    <FormControl className={values[source] ? classes.selectedField : ''}>
      <TextField
        className={`${classes.input} ${className}`}
        label={label}
        variant="filled"
        required={!!validate.length}
        value={values[source] ?? ''}
        onBlur={inputProps.onBlur}
        onChange={inputProps.onChange}
        error={touched && error}
        helperText={(touched && error) ? translate(error) : ''}
        disabled={disabled}
      />
      <TextInput
        source={source}
        validate={validate}
        style={{ display: 'none' }}
        disabled
      />
    </FormControl>
  )
}

export default  AttorneyLetterTextField