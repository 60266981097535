import * as React from 'react'
import { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useTranslate,
  AutocompleteInput,
  required,
  ReferenceInput,
  useDataProvider
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import { IconButton } from '@material-ui/core'
import Modal from './components/Modal'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  form: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 420px))',
    gridColumnGap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 48px',
      gridColumnGap: theme.spacing(1),
    },
  },
  contactPerson: {
    gridColumn: '1 / 2',
    '& div': {
      width: '100%',
    }
  },
  input: {
    width: '100%',
  },
  addBtn: {
    width: 48,
    height: 48,
    marginTop: 12,
    background: 'rgba(0, 0, 0, 0.04)',
    '& svg': {
      fontSize: '1.5rem'
    },
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.13)'
    }
  }
}))

const validateRequired = [required()]

const ContactPersonInput = ({ inputProps, disabled }) => {
  const translate = useTranslate()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const users = useSelector(state => state.admin.resources.users?.data)

  const { values } = useFormState()

  const [isOpenModal, setOpenModal] = useState(false)

  const toggleModal = useCallback(() => {
    setOpenModal(prev => !prev)
  }, [])

  const onChange = useCallback(async (id) => {
    let contactPerson = users[id]
    if (!contactPerson) {
      const user = await dataProvider.getOne('users', { id })
      contactPerson = user.data
    }

    let email = values.client.email
    let phone = values.client.phone
    if (contactPerson.email) {
      email = contactPerson.email
    }
    if (contactPerson.phone) {
      phone = contactPerson.phone
    }

    inputProps.onChange({
      target: {
        value: {
          ...values.client,
          phone,
          email,
          contactPerson
        }
      }
    })
  }, [values])

  return (
    <>
      <div className={`${classes.contactPerson} ${classes.input}`}>
        <ReferenceInput
          label={translate('fields.contactPerson')}
          source="client.contactPerson.id"
          reference="users"
          validate={validateRequired}
          onChange={onChange}
          disabled={disabled}
        >
          <AutocompleteInput
            helperText=""
          />
        </ReferenceInput>
      </div>
      <IconButton
        className={classes.addBtn}
        aria-label="add"
        size="small"
        onClick={toggleModal}
        disabled={disabled}
      >
        <AddIcon
          fontSize="small"
        />
      </IconButton>
      <Modal
        isOpen={isOpenModal}
        toggle={toggleModal}
      />
    </>
  )
}

export default ContactPersonInput