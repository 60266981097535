import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setOrganizationTypes,
  setConstituentDocKinds,
  setDocStatuses,
  setTaxPeriods,
  setVatPaymentAbsenceBasis,
  setConstants,
  setProviderStatuses,
  setCurrentUser,
  setAttorneyLetterActs,
  setAttorneyLetterStatuses,
  setPointOfSaleStatuses,
  setSaveWithoutConfirmPointsOfSale,
  setAppLanguage,
  setLoginEmail,
  setFeatureFlags,
  setUserInfo,
  setVisibilityModalConfirmationOnePointOfSale
} from "../../reducer/actions";
import { defaultTheme, Layout, useDataProvider } from "react-admin";
import {
  httpClient,
  standardApiUrl,
  customApiUrl
} from "../../api/httpDataProvider";
import { languageOptions } from "../../helpers/enums";

import Menu from "../Menu";
import ModalSupplierCreator from "../../components/ModalSupplierCreator";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import _ from "lodash";
import indigo from "@material-ui/core/colors/indigo";
import pink from "@material-ui/core/colors/pink";
import red from "@material-ui/core/colors/red";
import UpdatePointsOfSaleConfirmationModal from "../../components/UpdatePointsOfSaleConfirmationModal";
import VpAppBar from "../AppBar";
import UpdatePointOfSaleConfirmationModal from "../../components/UpdatePointOfSaleConfirmationModal";
import VpFooter from "../Footer";
import ErrorBoundary from "../../components/ErrorBoundary";

export const theme = createTheme(
  _.merge({}, defaultTheme, {
    palette: {
      secondary: {
        ...pink,
        main: "#1565C0"
      },
      primary: indigo,
      error: red,
      contrastThreshold: 3,
      tonalOffset: 0.2,
      background: {
        default: "#EFF6FC"
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          "&.MuiAppBar-colorSecondary": {
            // color: 'rgba(255,255,255,0.77)',
          },
          "& .MuiButtonBase-root:hover": {
            // color: 'rgba(255,255,255,1)',
            // backgroundColor: 'rgba(255,255,255,0.04)'
          }
        }
      },
      MuiToolbar: {
        root: {
          "&#list-actions-light .MuiButtonBase-root:not(.MuiFab-root), & .MuiButtonBase-root.hide-filter": {
            // color: 'rgba(255,255,255,0.54)',
            "&:hover": {
              // color: '#fff',
              // backgroundColor: 'rgba(255,255,255,0.04)'
            }
          },
          "& .MuiInputBase-root": {
            // backgroundColor: 'rgba(255,255,255,0.7)',
            "&:hover, &:active, &:focus, &.Mui-focused": {
              // backgroundColor: 'rgba(255,255,255,1)'
            }
          },
          '&[data-test="bulk-actions-toolbar"]': {
            overflow: "hidden",
            justifyContent: "flex-start",
            marginTop: "0",
            transition: "unset",
            position: "absolute",
            boxSizing: "border-box",
            width: "100%",
            borderRadius: "4px 4px 0 0",
            "& > .MuiToolbar-root": {
              backgroundColor: "rgb(234, 236, 247) !important"
            },
            "& > div": {
              margin: "0 8px"
            }
          },
          "& > form > .filter-field > .MuiFormControl-root:not(.MuiTextField-root) .MuiFormLabel-root": {
            // color: '#fff !important'
          },
          "&#list-actions-light": {
            backgroundColor: "transparent"
          }
        }
      },
      MuiDrawer: {
        root: {
          "& #ra-menu .MuiButtonBase-root, & #ra-menu svg, & #ra-menu .MuiTypography-root": {
            color: "rgba(0, 0, 0, 0.87)"
          },
          "& #ra-menu .MuiButtonBase-root:hover": {
            // backgroundColor: 'rgba(255,255,255,0.04)',
            color: "rgba(0, 0, 0, 0.87) !important",
            "& svg, .MuiTypography-root": {
              // color: 'rgba(255,255,255,1) !important',
            }
          }
        }
      },
      MuiFab: {
        primary: {
          backgroundColor: "#1565C0",
          "&:hover": {
            backgroundColor: "#2477D6"
          }
        }
      },
      MuiButton: {
        containedPrimary: {
          backgroundColor: "#1565C0",
          minHeight: 42,
          padding: "6px 22px !important",
          fontSize: 15,
          lineHeight: "1.25",
          "&:hover": {
            backgroundColor: "#2477D6"
          }
        },
        outlinedPrimary: {
          minHeight: 47,
          color: "#1565C0",
          border: "1px solid rgb(21,101,192, .83)",
          "&:hover": {
            border: "1px solid rgb(21,101,192, 1)"
          }
        }
      },
      MuiCheckbox: {
        colorPrimary: {
          "&.Mui-checked:not(.Mui-disabled)": {
            color: "#1565C0"
          }
        }
      },
      MuiFilledInput: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7) !important",
            backgroundColor: "rgba(0, 0, 0, 0.01) !important"
          },
          "& input": {
            boxSizing: "border-box",
            height: 56
          }
        }
      },
      MuiSelect: {
        root: {
          "&:not(.MuiTablePagination-select)": {
            boxSizing: "border-box",
            height: "56px !important",
            paddingTop: 27
          }
        }
      },
      MuiFormLabel: {
        root: {
          fontWeight: "500 !important",
          ".Mui-disabled:not(.Mui-error)": {
            color: "rgba(0, 0, 0, 0.54) !important"
          },
          "&:not(.Mui-disabled):not(.Mui-error)": {
            color: "#1565C0"
          },
          '&[data-shrink="true"]': {
            transform: "translate(12px, 10px) scale(0.75) !important"
          },
          '&[data-shrink="false"]': {
            transform: "translate(12px, 17px) scale(1) !important"
          }
        }
      },
      MuiTableCell: {
        head: {
          fontWeight: 700
        }
      }
    }
  })
);

const CustomLayout = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const dataProvider = useDataProvider();

  const dataUpdateOnePointOfSale = useSelector(
    state => state.customReducer.updateOnePointOfSale.data
  );
  const dataUpdatePointsOfSale = useSelector(
    state => state.customReducer.updatePointsOfSale.data
  );
  const loading = useSelector(state => state.admin.loading);

  useEffect(() => {
    if (loading !== 0) {
      dispatch(setSaveWithoutConfirmPointsOfSale(false));
    }
  }, [loading]);

  useEffect(() => {
    dispatch(setLoginEmail(""));
    dispatch(setUserInfo({}));

    const token = localStorage.getItem("tokenData");
    if (token) {
      initHandbooks();
    }
    document.addEventListener("onRemoveAuthFromLocalStorage", redirectToLogin);
    document.addEventListener("onSetUserId", requestUser);
    dispatch(
      setAppLanguage(localStorage.getItem("lang") ?? languageOptions[0].value)
    );
    return () => {
      document.removeEventListener(
        "onRemoveAuthFromLocalStorage",
        redirectToLogin
      );
      dispatch(setCurrentUser({}));
    };
  }, []);

  // warning data remove
  useEffect(() => {
    const ids = Object.keys(dataUpdatePointsOfSale);
    if (ids.length !== 0 || dataUpdateOnePointOfSale !== null) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    history.block(tx => {
      if (dataUpdateOnePointOfSale !== null) {
        dispatch(setVisibilityModalConfirmationOnePointOfSale(true));
      }
    });
  }, [dataUpdatePointsOfSale, dataUpdateOnePointOfSale]);

  const initHandbooks = async () => {
    const urlArray = [
      `${standardApiUrl}/entities/vp_OrganizationType`,
      `${standardApiUrl}/entities/vp$ConstituentDocKind?view=constituentDocKind-edit`,
      `${standardApiUrl}/entities/vp_FoundingDocumentStatus`,
      `${standardApiUrl}/entities/vp_TaxPeriod`,
      `${standardApiUrl}/entities/vp_VatPaymentAbsenceBasis`,
      `${customApiUrl}/vpConstants`,
      `${standardApiUrl}/entities/vp$ProviderStatus`,
      `${standardApiUrl}/entities/vp_AttorneyLetterAct`,
      `${standardApiUrl}/entities/vp_AttorneyLetterStatus`,
      `${standardApiUrl}/entities/vp_PointOfSaleStatus`,
      `${customApiUrl}/vpFeatures`
    ];
    const initData = await Promise.all(urlArray.map(url => httpClient(url)));
    const [
      organizationTypes,
      constituentDocKinds,
      docStatuses,
      taxPeriods,
      vatPaymentAbsenceBasis,
      constants,
      providerStatuses,
      attorneyLetterActs,
      attorneyLetterStatuses,
      pointOfSaleStatuses,
      featureFlags
    ] = initData;

    dispatch(setAttorneyLetterStatuses(attorneyLetterStatuses.json));
    dispatch(setAttorneyLetterActs(attorneyLetterActs.json));
    dispatch(setProviderStatuses(providerStatuses.json));
    dispatch(setConstants(constants.json));
    dispatch(setVatPaymentAbsenceBasis(vatPaymentAbsenceBasis.json));
    dispatch(setTaxPeriods(taxPeriods.json));
    dispatch(setDocStatuses(docStatuses.json));
    dispatch(setOrganizationTypes(organizationTypes.json));
    dispatch(setConstituentDocKinds(constituentDocKinds.json));
    dispatch(setPointOfSaleStatuses(pointOfSaleStatuses.json));
    dispatch(setFeatureFlags(featureFlags.json));
  };

  const redirectToLogin = () => {
    history.push("/login");
  };

  const requestUser = async () => {
    document.removeEventListener("onRemoveAuthFromLocalStorage", requestUser);
    const id = localStorage.getItem("userId");
    const user = await dataProvider.getOne("users", { id });
    dispatch(setCurrentUser(user.data));
  };

  return (
    <>
      <Layout
        {...props}
        theme={theme}
        appBar={VpAppBar}
        // sidebar={Sidebar}
        menu={Menu}
        error={ErrorBoundary}
      />
      <VpFooter />
      <ThemeProvider theme={createTheme(theme)}>
        <ModalSupplierCreator />
        <UpdatePointsOfSaleConfirmationModal />
        <UpdatePointOfSaleConfirmationModal />
      </ThemeProvider>
    </>
  );
};

export default CustomLayout;
