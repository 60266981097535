import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useTranslate } from 'react-admin'
import ModalBase from '../../../../../../../../../../components/ModalBase'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  modalBox: {
    width: '100%'
  },
  formControl: {
    overflowX: 'hidden',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(2),
    gridRowGap: 0,

    [theme.breakpoints.down('xs')]: {
      display: 'block',
      gridColumnGap: theme.spacing(1),
    },
  },
  radioGroup: {
    gridColumn: '1/3',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      gridColumn: '1 / 2',
    },
  }
}))

const FounderModalBase = ({
                            isOpen,
                            toggle,
                            handleSave,
                            children,
                            modalValues,
                            setModalValues,
                            disabled,
                            setAllBlur
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const constants = useSelector(state => state.customReducer.constants)

  const handleRadio = useCallback((e) => {
    setAllBlur(false)

    const value = e.target.value
    const valuesNew = {}

    for (const key in modalValues) {
      valuesNew[key] = ''
    }

    // keep access to edit inn, kpp or headName
    if (modalValues?.accessToEditTin === null) {
      valuesNew.accessToEditTin = null
    }
    if (modalValues?.accessToEditIec === null) {
      valuesNew.accessToEditIec = null
    }
    if (modalValues?.accessHasMoreThanFiftyPercent === null) {
      valuesNew.accessHasMoreThanFiftyPercent = null
    }
    if (modalValues?.accessHeadName === null) {
      valuesNew.accessHeadName = null
    }
    if (modalValues?.accessFullName === null) {
      valuesNew.accessFullName = null
    }
    if (modalValues?.accessIndividualName === null) {
      valuesNew.accessIndividualName = null
    }

    if (modalValues?.accessToName === null) {
      valuesNew.accessToName = null
    }

    setModalValues({ ...valuesNew, subjectType: value })
  }, [modalValues])

  return (
    <ModalBase
      title={translate('founders_of_the_vendor.modal.title')}
      handleClose={toggle}
      isOpen={isOpen}
      contentClasses={classes.formControl}
      paperStyle={classes.modalBox}
      maxWidth='md'
      handleSave={handleSave}
    >
      <FormControl component="fieldset" className={classes.radioGroup}>
        <RadioGroup
          value={modalValues.subjectType}
          onChange={handleRadio}
        >
          <FormControlLabel
            value={constants?.SUBJECT_TYPE_INDIVIDUAL}
            control={<Radio />}
            label={translate('fields.individual')}
            disabled={disabled}
          />
          <FormControlLabel
            value={constants?.SUBJECT_TYPE_LEGAL_PERSON}
            control={<Radio />}
            label={translate('fields.legal')}
            disabled={disabled}
          />
        </RadioGroup>
      </FormControl>
      {children}
    </ModalBase>
  )
}

export default FounderModalBase