import * as React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslate} from "react-admin";
import {Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    position: 'fixed', right: 0, bottom: 0, left: 0, zIndex: 100,
    padding: 5,
    backgroundColor: '#1565C0',
    textAlign: 'center',
  },
  text: {
    color: 'white',
    fontSize: '0.9rem'
  }
}))

const VpFooter = () => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <div className={classes.footer}>
      <Typography className={`${classes.text}`} variant="h6">
        {translate('footer.text')} <a className={classes.text}
                                      href={"documents/Letter MD_Oct 2023.pdf"}
                                      target="_blank"
                                      rel="noreferrer">
        {translate('footer.hrefName')}</a>
      </Typography>
    </div>
  )
};

export default VpFooter;