import * as React from 'react'
import { useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { TextInput } from 'react-admin'

const FullNameField = ({ label, inputProps }) => {
  const { values } = useFormState()

  useEffect(() => {
    inputProps.onChange({
      target: {
        value:
          `${values.lastName ? `${values.lastName} ` : ''}${values.firstName ? `${values.firstName[0]}. ` : ''}${values.middleName ? `${values.middleName[0]}.` : ''}`
      }
    })
  }, [values])

  return (
    <TextInput
      label={label}
      source="name"
      onChange={inputProps.onChange}
      helperText=''
    />
  )
}

export default FullNameField