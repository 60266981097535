import * as React from 'react'
import {CardContent
} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {useTranslate} from "react-admin";
import "../../../../../app/App.css";

const useStyles = makeStyles((theme) => ({
  fileName: {
    color: '#0000FF',
    textDecoration: 'underline'
  },
  cardContent: {
    paddingBottom: '1px'
  },
  center: {
    padding: '1px 40% 1px 20px'
  },
  padding: {
    padding: '20px'
  }
}))

const DocumentContent = ({title, label, options, className, ...props}) => {
  const translate = useTranslate()
  const classes = useStyles()
  return (
    <>
      <CardContent className={`${classes.cardContent} ${classes.padding}`}>
        <a
          className={classes.fileName}
          href={"documents/Инструкция для Поставщиков_04.22.pdf"}
          target="_blank"
        >
          {title}
        </a>
      </CardContent>

    </>
  )
}

export default DocumentContent